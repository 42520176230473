import React, { useState, useContext } from 'react';
// Libraries
import { message, Form } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
// Components
import Questions from './components/Questions';
import Button from '~/components/button';
import Drawer from '~/components/drawer';
// Contexts
import { LanguageContext } from '~/contexts/LanguageContext';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';

export default function ContactStoreButton({ GetContactsAgain }) {
  const { translate } = useContext(LanguageContext);
  const [loadingButton, setLoadingButton] = useState(false);
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState('');
  const [closeDrawer, setCloseDrawer] = useState(false);

  async function AddContact(data) {
    const params = {
      operation: ['Profile', 'Contact', 'Add'],
      data,
    };
    const response = await SocialMeAPI(params);
    if (response.success) {
      GetContactsAgain();
      message.success(translate.messages.contactSuccessfullyRegistered.text);
      setLoadingButton(false);
      return setCloseDrawer(true);
    }
    message.error(translate.messages.somethingHappened.text);
    setLoadingButton(false);
  }
  const onFinish = (data) => {
    AddContact(data);
    //console.log('Received data of form: ', data);
  };

  return (
    <Drawer
      title={translate.accountManagement.addContact.text}
      Trigger={
        <Button icon={<PlusOutlined />} type="primary" onClick={() => setCloseDrawer(false)}>
          {translate.accountManagement.addContact.text}
        </Button>
      }
      closeDrawer={closeDrawer}
      footer
      footerCancelButton
      footerMainButton={
        <Button
          loadingButtoning={loadingButton}
          type="primary"
          onClick={() => {
            form
              .validateFields()
              .then((values) => {
                form.resetFields();
                onFinish(values);
              })
              .catch((info) => {
                // console.log('Validate Failed:', info);
              });
          }}
        >
          {translate.basic.add.text}
        </Button>
      }
    >
      <Form
        labelCol={{
          xs: { span: 24 },
          sm: { span: 24 },
        }}
        wrapperCol={{
          xs: { span: 24 },
          sm: { span: 24 },
        }}
        form={form}
        layout="vertical"
        size="small"
        onValuesChange={() => setFormValues(form.getFieldsValue())}
        scrollToFirstError
      >
        <Questions formValues={formValues} />
      </Form>
    </Drawer>
  );
}
