import React from 'react';
//Libraries
import { Form, Alert } from 'antd';
//Components
import FormItem from '~/components/form/FormItem';
import Input from '~/components/input';
import Select from '~/components/select';
import Title from '~/components/typography/title';
//Utils
import {
    ValidateDate,
} from '~/utils/Validations';
//Environments
import { Sex } from '~/environments/Selects';

export default function PreRegistration({ form }) {
    return (
        <div>
            <Title textAlign="center" style={{ margin: '0px auto 10px' }} level={3}>
                Pré-registro
            </Title>
            <Alert
                message="Sobre o pré-registro"
                description="Ao criar um pré-registro no Social Me, você estará concordando que todas as informações aqui fornecidas são reais e correspondem ao seu familiar. Em caso de usuário menor de 18 (dezoito) anos, ou maior incapaz, você autoriza e concorda com os temos da Social Me para o uso da plataforma. Quando o membro da sua família acessar o Social Me pela primeira vez, ele irá terminar o seu cadastro e já estará pronto para usar o sistema."
                type="warning"
                showIcon
                style={{ margin: '10px 0px' }}
            />
            <Form
                labelCol={{
                    xs: { span: 24 },
                    sm: { span: 24 },
                }}
                wrapperCol={{
                    xs: { span: 24 },
                    sm: { span: 24 },
                }}
                layout="vertical"
                form={form}
                scrollToFirstError
            >
                <FormItem
                    label="Nome completo"
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: 'Um nome é obrigatório',
                        },
                    ]}
                    item={
                        <Input />
                    }
                />

                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'space-between',
                    }}
                >
                    <div>
                        <FormItem
                            label="CPF"
                            name={['cpf_number']}
                            style={{ width: '140px' }}
                            rules={[
                                {
                                    required: true,
                                    message: 'Um CPF é obrigatório',
                                },
                                () => ({
                                    validator(rule, value) {
                                        if (value.includes('_')) {
                                            return Promise.reject('Insira um CPF válido');
                                        }
                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                            item={<Input mask="111.111.111-11" />}
                        />
                    </div>
                    <div>
                        <FormItem
                            label="Nascimento"
                            style={{ width: '120px' }}
                            name={['birth_certificate_date']}
                            rules={[
                                {
                                    required: true,
                                    message: 'Nascimento obrigatório',
                                },
                                () => ({
                                    validator(rule, value) {
                                        if (ValidateDate(value, true) === false) {
                                            return Promise.reject('Data inválida');
                                        }
                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                            item={
                                <Input mask="11/11/1111" />
                            }
                        />
                    </div>
                    <div>
                        <FormItem
                            label="Sexo"
                            style={{ width: '150px' }}
                            name="sex"
                            rules={[
                                {
                                    required: true,
                                    message: 'Sexo é obrigatório',
                                },
                            ]}
                            item={
                                <Select options={Sex} placeholder="Selecione" noDefaultValue />
                            }
                        />
                    </div>
                </div>
            </Form>
        </div>
    );
}
