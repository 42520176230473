import React from 'react';
// Components
import UserDisconnectedDescription from '~/components/pageStructure/UserDisconnectedDescription';

export default function Documents() {
  return (
    <UserDisconnectedDescription
      description="Nesta página você pode editar os documentos informados a SocialMe, como CPF, CNS, CNH, RG, Passaporte e Certidão de Nascimento"
    />
  );
}
