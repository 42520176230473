import React, { useContext } from 'react';

import { ThemeContext } from '~/contexts/ThemeContext';

import Title from '~/components/typography/title';
import Card from '~/components/card';

import NotificationsItems from './components/NotificationsItems';

import { Header } from './style';

export default function Notifications() {
  const { theme } = useContext(ThemeContext);

  const cardStyle = {
    minHeight: '75vh',
  };

  return (
    <Card style={cardStyle}>
      <Header>
        <Title level={4}>Notificações</Title>
      </Header>

      <NotificationsItems theme={theme} />
    </Card>
  );
}
