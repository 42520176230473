import React from 'react';
//Components
import Text from '~/components/typography/text';
//Style
import {
  Container,
  FirstRow,
  SecondRow,
  ImageTitleContainer,
  ActionContainer,
} from './style';
//Utils
import { GetDocumentIcon } from '~/utils/SocialMeAssets';
//Own Components
import EditButton from '../../components/EditButton';

export default function ViewPassportMobile({ name, person, GetProfileAgain }) {
  return (
    <Container>
      <FirstRow>
        <ImageTitleContainer>
          <img src={GetDocumentIcon(name)} alt="..." style={{ height: 45 }} />
          <Text strong>Passaporte</Text>
        </ImageTitleContainer>
      </FirstRow>
      <SecondRow>
        <Text>Número: {person.passport_number}</Text>
      </SecondRow>
      <ActionContainer>
        <EditButton
          title="Editando Passaporte"
          person={person}
          GetProfileAgain={GetProfileAgain}
        />
      </ActionContainer>
    </Container>
  );
}
