import React, { useState } from 'react';
import { Switch, Row, Col } from 'antd';

import Text from '~/components/typography/text';
import Divider from '~/components/divider';

const institutions = ["Prefeitura de Anavilhanas", "Prefeitura de Pancas", "Prefeitura de Nova Petrópolis", "Prefeitura de Barcelos"]

export default function Body({ propertys }) {
    const [nobodyVisualize, setNobodyVisualize] = useState(Math.random() < 0.5)
    const [nobodyRequest, setNobodyRequest] = useState(Math.random() < 0.5)

    function ToogleNobodyRequest() {
        setNobodyRequest(!nobodyRequest)
    }
    function ToogleNobodyVisualize() {
        setNobodyVisualize(!nobodyVisualize)
    }
    function RenderInstitutions() {
        return (
            <div>
                {institutions.map(item => (
                    <Row key={item} style={{ marginTop: 40 }}>
                        <Col span={24}>
                            <Text strong>{item}</Text>
                        </Col>
                        <Col span={24}>
                            <div style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
                                <Switch defaultChecked={Math.random() < 0.5} />
                                <Text style={{ marginLeft: 5 }}>
                                    A instituição pode visualizar meu cadastro
                                </Text>
                            </div>
                        </Col>
                        <Col span={24}>
                            <div style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
                                <Switch defaultChecked={Math.random() < 0.5} />
                                <Text style={{ marginLeft: 5 }}>
                                    A instituição pode visualizar enviar solicitações
                                </Text>
                            </div>
                        </Col>
                    </Row>
                ))}
            </div>
        )
    }

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img
                    style={{ height: 50 }}
                    src={propertys.image}
                    alt="img"
                />
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: 30 }}>
                <Switch value={nobodyVisualize} onChange={ToogleNobodyVisualize} />
                <Text style={{ marginLeft: 5 }}>
                    {nobodyVisualize ?
                        "Qualquer Institução pode visualizar meu cadastro"
                        :
                        "Nenhuma Institução pode visualizar meu cadastro"
                    }

                </Text>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
                <Switch value={nobodyRequest} onChange={ToogleNobodyRequest} />
                <Text style={{ marginLeft: 5 }}>
                    {nobodyRequest ?
                        "Qualquer Institução pode enviar solicitações"
                        :
                        "Nenhuma Institução pode enviar solicitações"
                    }

                </Text>
            </div>
            {propertys.drawerType === 1 &&
                <>
                    <Divider />
                    <RenderInstitutions />
                </>
            }
        </div>
    )
}