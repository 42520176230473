import React, { useContext} from 'react';
// Libraries
import { Row, Col } from 'antd';
// Contexts
import { ResponsiveContext } from '~/contexts/ResponsiveContext';
// Standalone components
import { Container } from './style';
// Components
import Title from '~/components/typography/title';

export default function ContactHeader({ GetProfileAgain, title }) {
  const { isMobile } = useContext(ResponsiveContext);
  return (
    <Container>
      <Row>
        <Col
          style={{ display: 'flex', justifyContent: isMobile ? 'center' : 'flex-start' }}
          xs={24}
          sm={24}
          md={24}
          lg={12}
          xl={12}
        >
          <Title level={4}>{title}</Title>
        </Col>
        <Col
          style={{ display: 'flex', justifyContent: isMobile ? 'center' : 'flex-end' }}
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
        >
        </Col>
      </Row>
    </Container>
  );
}
