import React, { useContext, useState, useEffect } from 'react';
// Libraries
import { message, Drawer, Form } from 'antd';
// Components
import Button from '~/components/button';
//Own Components
import Addresses from './components/Addresses';
// Contexts
import { AxiosContext } from '~/contexts/AxiosContext';
import { ResponsiveContext } from '~/contexts/ResponsiveContext';

export default function LinkAddress({ itemData, GetDataAgain }) {
  const { CallToTheAPI } = useContext(AxiosContext);
  const { isMobile } = useContext(ResponsiveContext);

  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState('');
  const [loadingButton, setLoadingButton] = useState(false);

  useEffect(() => {
    async function PrepareInitialValues() {}
    if (itemData) {
      PrepareInitialValues();
    }
  }, [itemData]);

  const ShowDrawer = () => {
    setVisibleDrawer(true);
  };
  const CloseDrawer = () => {
    setVisibleDrawer(false);
  };
  async function Edit(data) {
    const params = {
      operation: ['Profile', 'Location', 'Edit'],
      urlParams: {
        placeId: itemData.id,
      },
      data,
    };
    const response = await CallToTheAPI(params);
    if (response.success) {
      message.success('Local Atualizado!');
      setLoadingButton(false);
      CloseDrawer();
      return GetDataAgain();
    }
    message.error('Alguma coisa aconteceu...');
    setLoadingButton(false);
  }

  const onFinish = (data) => {
    Edit(data);
  };
  return (
    <>
      <Button onClick={() => ShowDrawer()} style={{ marginRight: 5 }}>
        Gerenciar Endereços
      </Button>

      <Drawer
        title={`Gerenciando endereços`}
        width={isMobile ? '100%' : 600}
        onClose={CloseDrawer}
        visible={visibleDrawer}
        bodyStyle={{ padding: 20 }}
        footer={
          <div
            style={{
              textAlign: 'right',
            }}
          >
            <Button onClick={CloseDrawer} style={{ marginRight: 8 }}>
              Cancelar
            </Button>

            <Button
              loading={loadingButton}
              disabled={loadingButton}
              type="primary"
              onClick={() => {
                form
                  .validateFields()
                  .then((values) => {
                    //form.resetFields();
                    //form.setFieldsValue(values);
                    onFinish(values);
                  })
                  .catch((info) => {
                    //console.log('Validate Failed:', info);
                  });
              }}
            >
              Atualizar
            </Button>
          </div>
        }
      >
        <Form
          labelCol={{
            xs: { span: 24 },
            sm: { span: 24 },
          }}
          wrapperCol={{
            xs: { span: 24 },
            sm: { span: 24 },
          }}
          form={form}
          layout="vertical"
          size="small"
          onValuesChange={() => setFormValues(form.getFieldsValue())}
          scrollToFirstError
          initialValues={itemData}
        >
          <Addresses formValues={formValues} form={form} itemData={itemData} />
        </Form>
      </Drawer>
    </>
  );
}
