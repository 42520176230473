import React, { useContext, useState } from 'react';
// Contexts
import { AxiosContext } from '~/contexts/AxiosContext';
// Libraries
import { List, Avatar, Empty } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
// Components
import Button from '~/components/button';
import Text from '~/components/typography/text';
// Utils
import { GetDefaultAvatar } from '~/utils/SocialMeAssets';

export default function PendingList({
  pendingList,
  GetRelantionshipsAgain,
}) {
  const { CallToTheAPI } = useContext(AxiosContext);

  const [loadingButton, setLoadingButton] = useState(false);

  async function RejectRelationship(relationshipId) {
    setLoadingButton(true);
    const params = {
      operation: ['Profile', 'Relantionship', 'Reject'],
      urlParams: {
        relationshipId,
      },
    };
    const response = await CallToTheAPI(params);
    if (response.success) {
      GetRelantionshipsAgain();
    }
    return setLoadingButton(false);
  }

  async function ApproveRelationship(relationshipId) {
    setLoadingButton(true);
    const params = {
      operation: ['Profile', 'Relantionship', 'Approve'],
      urlParams: {
        relationshipId,
      },
    };
    const response = await CallToTheAPI(params);
    if (response.success) {
      GetRelantionshipsAgain();
    }
    return setLoadingButton(false);
  }
  function GetPronoun(item) {
    let sex;
    if(item.person_social_sex){
      sex = item.person_social_sex;
    }else if(item.person_sex){
      sex = item.person_sex;
    }else{
      sex = undefined;
    }
    switch (sex) {
      case 'M':
        return 'seu';
      case 'F':
        return 'sua';
      default:
        return 'seu/sua';
    }
  }
  function GetKinship(item) {
    let sex;
    if(item.person_social_sex){
      sex = item.person_social_sex;
    }else if(item.person_sex){
      sex = item.person_sex;
    }else{
      sex = undefined;
    }
    switch (sex) {
      case 'M':
        return item.kinship_male_opposite_name;
      case 'F':
        return item.kinship_female_opposite_name;
      default:
        return item.kinship_neutral_opposite_name;
    }
  }

  return pendingList.length === 0 ? (
    <div>
      <Empty description={false} />
      <Text style={{ textAlign: "center" }}>Sem Solicitações</Text>
    </div>
  ) : (
    <>
      <Text strong>Solicitações Recebidas</Text>
      <List
        itemLayout="horizontal"
        dataSource={pendingList}
        renderItem={(item) => (
          <List.Item
            actions={[
              <Button
                loading={loadingButton}
                onClick={() => RejectRelationship(item.id)}
                text="Não conheço"
                icon={<CloseOutlined />}
              />,
              <Button
                loading={loadingButton}
                onClick={() => ApproveRelationship(item.id)}
                text="Sim, é verdade"
                type="primary"
                icon={<CheckOutlined />}
              />,
            ]}
          >
            <List.Item.Meta
              avatar={
                <Avatar src={item.person_avatar || GetDefaultAvatar('other')} />
              }
              title={item.person_name}
              description={`diz ser ${GetPronoun(item)} ${GetKinship(
                item
              )}`}
            />
          </List.Item>
        )}
      />
    </>
  );
}
