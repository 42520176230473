import React from 'react';
// Components
import FormItem from '~/components/form/FormItem';
import Input from '~/components/input';

export default function CNPJForm({ formValues, form }) {
  return (
    <div>
      <Number />
    </div>
  );
}

const Number = () => {
  return (
    <FormItem
      label={'Número do CNPJ'}
      name={['cnpj_number']}
      rules={[
        {
          required: true,
          message: 'Um número é obrigatório',
        },
      ]}
      item={<Input mask="11.111.111/1111-11" />}
    />
  );
};
