import React from 'react';
import { Tag } from 'antd';
import {
    EyeOutlined,
    EyeInvisibleOutlined,
    CloseCircleOutlined,
    CheckCircleOutlined
} from '@ant-design/icons';
import Card from '~/components/card';
import ConfigButton from '../ConfigButton';

export default function CardItem({ image, heightImage, drawerType }) {
    return (
        <Card
            style={{ marginBottom: 5, height: 60 }}
        >
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: "center" }}>
                <div style={{ width: "30%", height: 40, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <img src={image} alt="brandImage" style={{ height: heightImage }} />
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {Math.random() < 0.5 ?
                        <Tag icon={<EyeOutlined />} color="success">
                            Pode visualizar meu cadastro
                        </Tag>
                        :
                        <Tag icon={<EyeInvisibleOutlined />} color="error">
                            Não pode visualizar meu cadastro
                        </Tag>
                    }
                    {Math.random() < 0.5 ?
                        <Tag icon={<CheckCircleOutlined />} color="success">
                            Pode me enviar solicitações
                        </Tag>
                        :
                        <Tag icon={<CloseCircleOutlined />} color="error">
                            Não pode me enviar solicitações
                        </Tag>
                    }

                </div>
                <div>
                    <ConfigButton drawerType={drawerType} image={image} />
                </div>
            </div>
        </Card>
    )
}