import React, { useState, useContext, useEffect } from 'react';
// Libraries
import { Form, message } from 'antd';
// Contexts
import { LanguageContext } from '~/contexts/LanguageContext';
// Components
import Button from '~/components/button';
import Card from '~/components/card';
import Loading from '~/components/loading';
import Divider from '~/components/divider';
// Questions
import BasicInformation from './components/BasicInformation';
import BirthInformation from './components/BirthInformation';
import SocialIdentity from './components/SocialIdentity';
// Services
import { DateInBrazilianFormat } from '~/utils/DateAndTime';
import { AmericanDateParser } from '~/utils/Parsers';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';

export default function StreetForm({ person, GetProfileAgain }) {
  const { translate } = useContext(LanguageContext);
  const [form] = Form.useForm();
  const [callingAPI, setCallingAPI] = useState(false);
  const [loadingForm, setLoadingForm] = useState(true);

  useEffect(() => {
    if (person) {
      person.birth_certificate_date = DateInBrazilianFormat(
        person.birth_certificate_date
      );
      setLoadingForm(false);
    }
  }, [person]);

  async function EditProfile(data) {
    setCallingAPI(true);
    const params = {
      operation: ['Profile', 'Edit'],
      data,
    };
    const response = await SocialMeAPI(params);
    if (response.success) {
      message.success(translate.messages.updatedProfile.text);
      GetProfileAgain();
    } else {
      message.error(translate.messages.somethingHappened.text);
    }
    return setCallingAPI(false);
  }

  async function onFinish(data) {
    data.birth_certificate_date = AmericanDateParser(
      data.birth_certificate_date
    );
    EditProfile(data);
  }
  if (loadingForm) {
    return (
      <Card>
        <Loading text={translate.messages.loadingData.text} />
      </Card>
    );
  }
  return (
    <Card bodyStyle={{ padding: '20px 20px 0px' }}>
      <Form
        labelCol={{ xs: { span: 24 }, sm: { span: 24 } }}
        wrapperCol={{ xs: { span: 24 }, sm: { span: 24 } }}
        form={form}
        size="small"
        layout="vertical"
        onFinish={onFinish}
        scrollToFirstError
        initialValues={person}
      >
        <BasicInformation />
        <BirthInformation form={form} />
        <SocialIdentity />

        <Divider style={{ margin: '10px 0px' }} />
        <Form.Item wrapperCol={{ xs: { span: 24 }, sm: { span: 24 } }}>
          <Button
            loading={callingAPI}
            style={{ float: 'right' }}
            size={'default'}
            type="primary"
            htmlType="submit"
          >
            {translate.basic.update.text}
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
}
