import React, { memo } from 'react';
// Libraries
import { AppstoreOutlined } from '@ant-design/icons';
// Components
import Button from '~/components/button';
import Drawer from '~/components/drawer';
// Own Components
import ItemsContainer from './components/ItemsContainer';
// Standalone Components
import { Container } from './style';

function ModulesMenu() {
  return (
    <Container>
      <Drawer
        width={'25vw'}
        Trigger={<Button shape="circle" icon={<AppstoreOutlined />} />}
        DrawerContentComponent={ItemsContainer}
      />
    </Container>
  );
}

export default memo(ModulesMenu);
