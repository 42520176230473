import React, { useContext, useState } from 'react';
// Libraries
import { List, Avatar, message, Empty } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
// Components
import Button from '~/components/button';
import Text from '~/components/typography/text';
// Contexts
import { AxiosContext } from '~/contexts/AxiosContext';
import { AuthContext } from '~/contexts/AuthContext';
// Utils
import { GetDefaultAvatar } from '~/utils/SocialMeAssets';

export default function RelationshipList({
  myRelationships,
  GetRelantionshipsAgain,
}) {
  const { CallToTheAPI } = useContext(AxiosContext);
  const { user } = useContext(AuthContext);

  const [loadingButton, setLoaddingButton] = useState(false);

  async function DeleteRelationship(relationshipId) {
    setLoaddingButton(true);
    const params = {
      operation: ['Profile', 'Relantionship', 'Delete'],
      urlParams: {
        relationshipId,
      },
    };
    const response = await CallToTheAPI(params);
    if (response.success) {
      message.success('Relacionamento deletado com sucesso');
    }
    GetRelantionshipsAgain();
    return setLoaddingButton(false);
  }

  function GetKinship(item) {
    let sex;
    if (item.linked_person_social_sex) {
      sex = item.linked_person_social_sex;
    } else if (item.linked_person_sex) {
      sex = item.linked_person_sex;
    } else {
      sex = undefined;
    }
    switch (sex) {
      case 'M':
        return item.kinship_male_name;
      case 'F':
        return item.kinship_female_name;
      default:
        return item.kinship_neutral_name;
    }
  }

  function GetKinshipOpposite(item) {
    let sex;
    if (item.person_social_name) {
      sex = item.person_social_name;
    } else if (item.person_sex) {
      sex = item.person_sex;
    } else {
      sex = undefined;
    }
    switch (sex) {
      case 'M':
        return item.kinship_male_opposite_name;
      case 'F':
        return item.kinship_female_opposite_name;
      default:
        return item.kinship_neutral_opposite_name;
    }
  }

  function RenderRelationship(item) {
    if (item.person_id === user.id) {
      return (
        <List.Item.Meta
          avatar={
            <Avatar src={item.linked_person_avatar || GetDefaultAvatar('other')} />
          }
          title={item.linked_person_name}
          description={GetKinship(item)}
        />
      )
    } else {
      return (
        <List.Item.Meta
          avatar={
            <Avatar src={item.person_avatar || GetDefaultAvatar('other')} />
          }
          title={item.person_name}
          description={GetKinshipOpposite(item)}
        />
      )
    }
  }

  return myRelationships.length === 0 ? (
    <div>
      <Empty description={false} />
      <Text style={{ textAlign: "center" }}>Sem Relacionamentos</Text>
    </div>
  ) : (
      <>
        <Text strong>Meus relacionamentos</Text>
        <List
          itemLayout="horizontal"
          dataSource={myRelationships}
          renderItem={(item) => (
            <List.Item
              actions={[
                <Button
                  loading={loadingButton}
                  icon={<DeleteOutlined />}
                  onClick={() => DeleteRelationship(item.id)}
                />,
              ]}
            >
              {RenderRelationship(item)}
            </List.Item>
          )}
        />
      </>
    );
}
