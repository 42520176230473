import React, { useContext } from 'react';
// Contexts
import { ResponsiveContext } from '~/contexts/ResponsiveContext';
//Own Components
import Avatar from './components/Avatar';
import Progress from './components/Progress';
//import SocialMeIdentity from './components/SocialMeIdentity';
import QRCode from './components/QRCode';
// Standalone Components
import { Container } from './style';
//Components
import Card from '~/components/card';
import Divider from '~/components/divider';
import Title from '~/components/typography/title';
// Utils
import { UserName } from '~/utils/UserInformation';

export default function UserCard({ person, GetProfileAgain }) {
  const { isMobile } = useContext(ResponsiveContext);
  return (
    <Container style={{ margin: isMobile ? '0px 0px 10px 0px' : '0px 10px 0px 0px' }}>
      <Card>
        <Avatar person={person} GetProfileAgain={GetProfileAgain} />
        <Title level={4} style={{ textAlign: 'center' }}>
          {UserName()}
        </Title>
        <Progress />
        <Divider />
        <QRCode person={person} GetProfileAgain={GetProfileAgain} />
      </Card>
    </Container>
  );
}
