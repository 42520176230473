import React from 'react';
//Components
import Text from '~/components/typography/text';
//Style
import {
  Container,
  FirstRow,
  SecondRow,
  ImageTitleContainer,
  ActionContainer,
} from './style';
//Utils
import { GetDocumentIcon } from '~/utils/SocialMeAssets';
//Own Components
import EditButton from '../../components/EditButton';

export default function ViewCPFDesktop({ name, person, GetProfileAgain }) {
  return (
    <Container>
      <FirstRow>
        <ImageTitleContainer>
          <img src={GetDocumentIcon(name)} alt="..." style={{ height: 45 }} />
          <Text strong>CPF (Cadastro de Pessoa Física)</Text>
        </ImageTitleContainer>
        <ActionContainer>
          <EditButton
            title="Editando CPF (Cadastro de Pessoa Física)"
            person={person}
            GetProfileAgain={GetProfileAgain}
          />
        </ActionContainer>
      </FirstRow>
      <SecondRow>
        <Text>Número do CPF: {person.cpf_number}</Text>
      </SecondRow>
    </Container>
  );
}
