import API from '~/environments/APIs';

export default {
  GetPersonQRCode: {
    baseURL: API.socialMe,
    url: '/qr-code/person',
    method: 'post',
    timeout: '50000',
  },
  Decode: {
    baseURL: API.socialMe,
    url: '/qr-code/decode/qrCodeId',
    method: 'get',
    timeout: '50000',
  },
  ReadDevice:{
    baseURL: API.socialMe,
    url: '/read-device/qrCodeId',
    method: 'get',
    timeout: '50000',
  },
  GetProductServiceQRCode: {
    baseURL: API.socialMe,
    url: '/qr-code/product-service',
    method: 'post',
    timeout: '50000',
  }
};
