import React from 'react';
import { Space } from 'antd';
//Own Components
import AddDocument from './components/AddDocument';

export default function AddDocumentContainer({ missingDocuments, GetProfileAgain }) {
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'flex-start',
            flexDirection: 'row',
            overflowX: 'auto',
            padding: '10px 10px'
        }}>
            <Space>
                {missingDocuments.map(item => (
                    <div key={item} >
                       <AddDocument documentToAdd={item} GetProfileAgain={GetProfileAgain} />
                    </div>
                ))}
            </Space>
        </div>
    )
}