import styled from 'styled-components';
import { InputNumber } from 'antd';

export const MainInputNumber = styled(InputNumber)`
  width: ${(props) => props.width || '100%'};
  margin: ${(props) => props.margin || '0px'};
  background-color: ${(props) => props.backgroundcolor};
  color: ${(props) => props.color};
  border-radius: 8px;
`;

export const Container = styled.div`
  position: relative;
`;

export const LabelContainer = styled.div`
  background-color: ${(props) => props.backgroundcolor};
  padding: 1px 5px;
  border-radius: 8px;
  position: absolute;
  top: ${(props) => props.top};
  left: 10;
  font-size: 8pt;
  line-height: 15px;
`;

export const RequiredSpan = styled.span`
  color: ${(props) => props.color};
  margin-left: 5px;
`;
