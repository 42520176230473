import React, { useState, useContext } from 'react';
// Libraries
import { message } from 'antd';
import { CameraOutlined } from '@ant-design/icons';
// Contexts
import { AuthContext } from '~/contexts/AuthContext';
import { LanguageContext } from '~/contexts/LanguageContext';
// Own Components
import DrawerContent from './components/DrawerContent';
// Standalone Components
import { Container, PhotoContainer, AvatarContent, IconContainer} from './style';
// Components
import Button from '~/components/button';
import Drawer from '~/components/drawer';
import Tooltip from '~/components/tooltip';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';
import { UserAvatar } from '~/utils/UserInformation';

export default function UserAvatarContainer({ person, GetProfileAgain }) {
  const { GetUserAgain } = useContext(AuthContext);
  const { translate } = useContext(LanguageContext);
  const [usedAvatarUrl, setUsedAvatarUrl] = useState();
  const [loadButton, setLoadButton] = useState(false);
  const [hover, setHover] = useState(false);
  const [closeDrawer, setCloseDrawer] = useState(false);

  async function EditProfile() {
    setLoadButton(true);
    const params = {
      operation: ['Profile', 'Edit'],
      data: {
        avatar: usedAvatarUrl,
      },
    };
    const response = await SocialMeAPI(params);
    if (response.success) {
      GetUserAgain();
      GetProfileAgain();
      setUsedAvatarUrl();
      setLoadButton(false);
      setCloseDrawer(true);
      return message.success(translate.messages.profilePhotoUpdatedSuccessfully.text);
    }
    message.error(translate.messages.somethingHappened.text);
    return setLoadButton(false);
  }

  return (
    <Drawer
      Trigger={
        <Tooltip
          placement="top"
          title={translate.accountManagement.clickHereToChangeYourAvatar.text}
          arrowPointAtCenter
          onClick={() => setCloseDrawer(false)}
        >
          <Container>
            <PhotoContainer>
              <AvatarContent
                onMouseEnter={() => setHover(true)}
                onMouseMove={() => setHover(true)}
                onMouseOut={() => setHover(false)}
                backgroundImage={UserAvatar()}
              />
            </PhotoContainer>
            <IconContainer
              onMouseEnter={() => setHover(true)}
              onMouseMove={() => setHover(true)}
              onMouseOut={() => setHover(false)}
              opacity={hover ? 1 : 0}
            >
              <CameraOutlined
                onMouseEnter={() => setHover(true)}
                onMouseMove={() => setHover(true)}
                style={{
                  color: 'white',
                  fontSize: '16pt',
                  display: 'block',
                  margin: '10px auto 0px',
                }}
              />
            </IconContainer>
          </Container>
        </Tooltip>
      }
      title={translate.accountManagement.updatingProfilePhoto.text}
      bodyStyle={{ padding: 0 }}
      footer
      footerCancelButton
      footerMainButton={
        <Button
          type="primary"
          onClick={EditProfile}
          loading={loadButton}
          disabled={!usedAvatarUrl}
        >
          {translate.accountManagement.changeProfilePhoto.text}
        </Button>
      }
      closeDrawer={closeDrawer}
    >
      <DrawerContent
        person={person}
        usedAvatarUrl={usedAvatarUrl}
        setUsedAvatarUrl={setUsedAvatarUrl}
        GetProfileAgain={GetProfileAgain}
      />
    </Drawer>
  );
}
