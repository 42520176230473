import React from 'react';
// Components
import Tabs from '~/components/tabs';
// Tabs
//import Notifications from './tabs/Notifications';
//import Permissions from './tabs/Permissions';
//import Privacy from './tabs/Privacy';
import PerformanceProfiles from './tabs/PerformanceProfiles';
//import LinkingDevices from './tabs/LinkingDevices';

export default function Config() {
  const tabsItems = [
    /*
    {
      tabPath: '/notificacoes',
      title: 'Notificações',
      badgeCount: 0,
      component: Notifications,
    },
    */
    {
      tabPath: '/perfis-de-atuacao',
      title: 'Perfis de atuação na comunidade Social Me',
      badgeCount: 0,
      component: PerformanceProfiles,
    },
    /*
    {
      tabPath: '/permissoes',
      title: 'Permissões',
      badgeCount: 0,
      component: Permissions,
    },
    {
      tabPath: '/privacidade',
      title: 'Privacidade',
      badgeCount: 0,
      component: Privacy,
    },
    {
      tabPath: '/vinculacoes-de-dispositivos',
      title: 'Vinculações de dispositivos',
      badgeCount: 0,
      component: LinkingDevices,
    },
        */
  ];
  const tabRedirect = {
    to: '/perfis-de-atuacao',
  };
  return (
    <div>
      <Tabs tabsItems={tabsItems} tabRedirect={tabRedirect} />
    </div>
  );
}
