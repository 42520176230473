import React from 'react';
//Libraries
import { Space } from 'antd';
//Components
import EditButton from './components/EditButton';
import DeleteButton from './components/DeleteButton';
import AddressManager from './components/AddressManager';

export default function Actions({ data, GetDataAgain }) {
  return (
    <span
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
      }}
    >
      <Space>
        <AddressManager itemData={data} GetDataAgain={GetDataAgain} />
        <EditButton itemData={data} GetDataAgain={GetDataAgain} />
        <DeleteButton itemData={data} GetDataAgain={GetDataAgain} />
      </Space>
    </span>
  );
}
