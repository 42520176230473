import React, { useContext, useState, useEffect } from 'react';
// Libraries
import { message, Drawer, Form } from 'antd';
// Components
import Questions from './components/Questions';
import Loading from '~/components/loading';
import Button from '~/components/button';
// Contexts
import { AxiosContext } from '~/contexts/AxiosContext';
import { ResponsiveContext } from '~/contexts/ResponsiveContext';

export default function HomelessQuestionnaire() {
  const { CallToTheAPI } = useContext(AxiosContext);
  const { isMobile } = useContext(ResponsiveContext);

  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [form] = Form.useForm();
  const [person, setPerson] = useState();
  const [load, setLoad] = useState(true);

  useEffect(() => {
    async function GetData() {
      const params = {
        operation: ['Profile', 'Get'],
      };
      const response = await CallToTheAPI(params);
      if (response.success) {
        setPerson(response.body.person);
        console.log('person -> ', response.body.person)
        return setLoad(false);
      }
      return setLoad(false);
    }
    GetData();
  }, [CallToTheAPI]);

  const ShowDrawer = () => {
    setVisibleDrawer(true);
  };
  const CloseDrawer = () => {
    setVisibleDrawer(false);
  };
  async function EditProfile(data) {
    const params = {
      operation: ['Profile', 'Edit'],
      data,
    };
    const response = await CallToTheAPI(params);
    if (response.success) {
      message.success('Perfil Atualizado!');
      setLoadingButton(false);
      CloseDrawer();
      return;
    }
    message.error('Alguma coisa aconteceu...');
    setLoadingButton(false);
  }

  const onFinish = (data) => {
    console.log('Received data of form: ', data);
    EditProfile(data); 
  };
  return (
    <>
      <Button
        onClick={() => ShowDrawer()}
        style={{ marginRight: 5 }}
      >
        Questionário de morador de rua
      </Button>

      <Drawer
        title={`Questionário de morador de rua`}
        width={isMobile ? '100%' : 600}
        onClose={CloseDrawer}
        visible={visibleDrawer}
        bodyStyle={{ padding: 20 }}
        footer={
          <div
            style={{
              textAlign: 'right',
            }}
          >
            <Button
              onClick={CloseDrawer}
              style={{ marginRight: 8 }}
            >
              Cancelar
              </Button>

            <Button
              loading={loadingButton}
              disabled={loadingButton}
              type="primary"
              onClick={() => {
                form
                  .validateFields()
                  .then((values) => {
                    //form.resetFields();
                    //form.setFieldsValue(values);
                    onFinish(values);
                  })
                  .catch((info) => {
                    //console.log('Validate Failed:', info);
                  });
              }}
            >
              Responder
              </Button>
          </div>
        }
      >
        {load ?
          <Loading text="Carregando formulário" />
          :
          <Form
            labelCol={{
              xs: { span: 24 },
              sm: { span: 24 },
            }}
            wrapperCol={{
              xs: { span: 24 },
              sm: { span: 24 },
            }}
            form={form}
            layout="vertical"
            size="small"
            //onValuesChange={() => setFormValues(form.getFieldsValue())}
            scrollToFirstError
            initialValues={person}
          >
            <Questions />
          </Form>
        }
      </Drawer>
    </>
  );
}
