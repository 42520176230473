import React, { useState, useEffect, useContext } from 'react';
// Libraries
// import { Row, Col } from 'antd';
// Contexts
import { AuthContext } from '~/contexts/AuthContext';
//Own Components
// import UserCard from './components/UserCard';
import Form from './components/Form';
// Components
import Card from '~/components/card';
import Loading from '~/components/loading';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';

export default function BasicInformation() {
  const { GetUserAgain } = useContext(AuthContext);
  const [loadingContent, setLoadingContent] = useState(true);
  const [person, setPerson] = useState();

  useEffect(() => {
    async function GetData() {
      const params = {
        operation: ['Profile', 'Get'],
      };
      const response = await SocialMeAPI(params);
      if (response.success) {
        setPerson(response.body.person);
        return setLoadingContent(false);
      }
      return setLoadingContent(false);
    }
    GetData();
  }, []);

  async function GetProfileAgain() {
    const params = {
      operation: ['Profile', 'Get'],
    };
    const response = await SocialMeAPI(params);
    if (response.success) {
      GetUserAgain();
      return setPerson(response.body.person);
    }
  }

  if (loadingContent) {
    return (
      <Card>
        <Loading />
      </Card>
    );
  }

  return <Form person={person} GetProfileAgain={GetProfileAgain} />;
}
