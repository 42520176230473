import React from 'react';
// Components
import UserDisconnectedDescription from '~/components/pageStructure/UserDisconnectedDescription';

export default function Config() {
  return (
    <UserDisconnectedDescription
      description="Nesta página você pode controlar sua privacidade e segurança na SocialMe. Além disso, ainda é possível gerenciar quem pode ver os seus dados e quais dados seus serão compartilhados. Além de pode ver seus perfis de atuação na SocialMe, assim como poderá solicitar se tornar um membro parceiro ou gestor (opções disponíveis apenas para pessoas jurídicas)."
    />
  );
}
