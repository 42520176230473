import styled from 'styled-components';

export const Container = styled.div`
    border: 1px solid rgba(0, 0, 0, 0.12);
    padding: 10px;
    border-radius: 10px;
`
export const FirstRow = styled.div`
    display: flex;
    justify-content: space-between;
`

export const SecondRow = styled.div`
`

export const ImageTitleContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`

export const ActionContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`