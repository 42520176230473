import React from 'react';
// Libraries
import { Form, Row, Col, Divider } from 'antd';
// Components
import FormItem from '~/components/form/FormItem';
import Input from '~/components/input';

export default function SearchPerson({ form }) {
  return (
    <div>
      <Divider>Busque uma instituição</Divider>
      <Form
        labelCol={{ xs: { span: 24 }, sm: { span: 24 } }}
        wrapperCol={{ xs: { span: 24 }, sm: { span: 24 } }}
        form={form}
        size="small"
        layout="vertical"
        scrollToFirstError
      >
        <Row>
          <Col xs={24} sm={24} md={18} lg={18} xl={18}>
            <div style={{ padding: 5 }}>
              <FormItem
                label="Nome"
                name={['filter', 'name']}
                item={
                  <Input />
                }
              />
            </div>
          </Col>
          <Col xs={24} sm={24} md={6} lg={6} xl={6}>
            <div style={{ padding: 5 }}>
              <FormItem
                label="Sistema"
                name={['filter', 'system']}
                item={
                  <Input />
                }
              />
            </div>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <div style={{ padding: 5 }}>
              <FormItem
                label="CNPJ"
                name={['filter', 'cnpj']}
                item={
                  <Input />
                }
              />
            </div>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <div style={{ padding: 5 }}>
              <FormItem
                label="Cidade"
                name={['filter', 'city']}
                item={
                  <Input />
                }
              />
            </div>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <div style={{ padding: 5 }}>
              <FormItem
                label="Estado"
                name={['filter', 'state']}
                item={
                  <Input />
                }
              />
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
}