import React from 'react';
import { Space } from 'antd';
//Components
import EditButton from './components/EditButton';
import DeleteButton from './components/DeleteButton';

export default function Actions({ address, GetAddressesAgain }) {
  return (
    <span style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
      <Space>
        <EditButton address={address} GetAddressesAgain={GetAddressesAgain} />
        <DeleteButton address={address} GetAddressesAgain={GetAddressesAgain} />
      </Space>
    </span>
  );
}
