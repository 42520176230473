import React, { useEffect, useState, useContext } from 'react';
// Contexts
import { LanguageContext } from '~/contexts/LanguageContext';
// Standalone components
import { Container } from './style';
// Components
import Card from '~/components/card';
import Loading from '~/components/loading';
import Divider from '~/components/divider';
import Title from '~/components/typography/title';
// Own Components
import Content from './components/Content';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';

export default function TimelineComponent() {
  const { translate } = useContext(LanguageContext);
  const [timeline, setTimeline] = useState();
  const [loadingTimeline, setLoadingTimeline] = useState(true);

  useEffect(() => {
    async function GetTimeline() {
      const params = {
        operation: ['Profile', 'GetTimeline'],
      };
      const response = await SocialMeAPI(params);
      if (response.success) {
        setTimeline(response.body.timeline.reverse());
        setLoadingTimeline(false);
      }
    }
    GetTimeline();
  }, []);

  return (
    <Container>
      <Card>
        <Title level={4}>{translate.accountManagement.timelineTitle.text}</Title>
        <Divider />
        {loadingTimeline ? (
          <Loading text={translate.messages.preparingYourTimeline.text} />
        ) : (
          <Content timeline={timeline} />
        )}
      </Card>
    </Container>
  );
}
