import React from 'react';
// Libraries
import { List, Avatar } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
// Components
import Button from '~/components/button';

const dataFake = [
  {
    name: 'Prefeitura de Barcelos',
    avatar: 'https://static.wikia.nocookie.net/batman/images/a/a8/Gotham_City.jpg',
    description: 'Vinculado há 4 meses'
  }
]

export default function RelationshipList() {
  return (
    <>
      <List
        itemLayout="horizontal"
        dataSource={dataFake}
        renderItem={(item) => (
          <List.Item
            actions={[
              <Button
                icon={<DeleteOutlined />}
              />,
            ]}
          >
            <List.Item.Meta
              avatar={
                <Avatar src={item.avatar} />
              }
              title={item.name}
              description={item.description}
            />
          </List.Item>
        )}
      />
    </>
  )
}
