import React, { memo } from 'react';

import Avatar from '~/components/avatar';
import Text from '~/components/typography/text';

import { Container, TextGroup, Box, GroupTextBox, Circle } from './style';

function Contacts({ theme }) {
  const fakeMessages = [
    {
      avatar: 'https://uifaces.co/our-content/donated/xZ4wg2Xj.jpg',
      name: 'Matheus Ribeiro',
      lastMessage:
        'Olá, meu nome é Matheus Ribeiro e estou avisando você sobre um assunto importante.',
      messageHourSended: '19:57',
      messageReceivedWithoutView: 2,
    },
    {
      avatar: 'https://randomuser.me/api/portraits/women/95.jpg',
      name: 'Emily watson',
      lastMessage:
        'Olá, meu nome é Emily watson e estou avisando você sobre um assunto importante.',

      messageHourSended: '18:51',
    },
    {
      avatar:
        'https://images-na.ssl-images-amazon.com/images/M/MV5BMjAwMzc5OTEzOF5BMl5BanBnXkFtZTgwMDc5ODU3MTE@._V1_UX172_CR0,0,172,256_AL_.jpg',
      name: 'Sabrina ramalho',
      lastMessage:
        'Olá, meu nome é Sabrina ramalho e estou avisando você sobre um assunto importante.',

      messageHourSended: '18:13',
      messageReceivedWithoutView: 1,
    },
    {
      avatar: 'https://uifaces.co/our-content/donated/ukegoVAy.jpg',
      name: 'Diego da Silva',
      lastMessage:
        'Olá, meu nome é Diego da Silva e estou avisando você sobre um assunto importante.',
      messageHourSended: '17:43',
    },

    {
      avatar: 'https://uifaces.co/our-content/donated/AW-rdWlG.jpg',
      name: 'Fabrício Ribeiro',
      lastMessage:
        'Olá, meu nome é Fabrício Ribeiro e estou avisando você sobre um assunto importante.',
      messageHourSended: '17:21',
    },
    {
      avatar: 'https://uifaces.co/our-content/donated/FJkauyEa.jpg',
      name: 'Ricardo Menezes',
      lastMessage:
        'Olá, meu nome é Ricardo Menezes e estou avisando você sobre um assunto importante.',
      messageHourSended: '15:51',
    },
    {
      avatar: 'https://uifaces.co/our-content/donated/AVQ0V28X.jpg',
      name: 'Sarah Conor',
      lastMessage:
        'Olá, meu nome é Sarah Conor e estou avisando você sobre um assunto importante.',
      messageHourSended: '15:19',
    },
    {
      avatar:
        'https://images-na.ssl-images-amazon.com/images/M/MV5BYTcwYjA3NGQtNDU4NS00OWVkLTlmM2MtM2Q4NzA0ZjkxYTA3XkEyXkFqcGdeQXVyMjc4NTQyNTQ@._V1_UX172_CR0,0,172,256_AL_.jpg',
      name: 'Caroline Dias',
      lastMessage:
        'Olá, meu nome é Caroline Dias e estou avisando você sobre um assunto importante.',
      messageHourSended: '15:04',
    },
    {
      avatar: 'https://uifaces.co/our-content/donated/N5PLzyan.jpg',
      name: 'Raul Herreira',
      lastMessage:
        'Olá, meu nome é Raul Herreira e estou avisando você sobre um assunto importante.',
      messageHourSended: '14:34',
    },
  ];
  return (
    <Container
      scrollBarColor={theme.color.white}
      scrollBarThumbColor={theme.color.scrollBar}
    >
      {fakeMessages.map((message) => (
        <Box key={message.lastMessage}>
          <Avatar src={message.avatar} size={54} />
          <GroupTextBox>
            <TextGroup>
              <Text strong>{message.name}</Text>
              <Text style={{ fontSize: 12 }} kind="secondary">
                {message.messageHourSended}
              </Text>
            </TextGroup>
            <TextGroup>
              <Text>
                {`${message.lastMessage} - ${message.timeThatMessageWasSend}`.substr(
                  0,
                  30
                ) + '...'}
              </Text>
              <Circle
                backgroundColor={
                  message.messageReceivedWithoutView && theme.color.primary
                }
              >
                {message.messageReceivedWithoutView}
              </Circle>
            </TextGroup>
          </GroupTextBox>
        </Box>
      ))}
    </Container>
  );
}

export default memo(Contacts);
