import React from 'react';
//Own Components
import Actions from './components/Actions';
//Components
import Table from '~/components/table';
import Pagination from '~/components/pagination';
import Text from '~/components/typography/text';

export default function Content({ data, GetDataAgain, pageParameters }) {
  const tableColumns = [
    {
      title: 'Local',
      dataIndex: 'name',
      key: 'name',
      render: (name) => <Text strong>{name}</Text>,
    },
    {
      title: 'Ações',
      key: 'action',
      render: (data) => <Actions GetDataAgain={GetDataAgain} data={data} />,
    },
  ];

  return (
    <div>
      <Table tableColumns={tableColumns} dataReceived={data} />
      <Pagination
        onChange={(currentPage, limit) => GetDataAgain({ currentPage, limit })}
        onShowSizeChange={(currentPage, limit) =>
          GetDataAgain({ currentPage, limit })
        }
        total={pageParameters.totalElements}
        showSizeChanger
        showQuickJumper
        showTotal={(total) => `Total ${total} items`}
      />
    </div>
  );
}
