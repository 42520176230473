import React, { useEffect, useState, useContext } from 'react';
// Contexts
import { LanguageContext } from '~/contexts/LanguageContext';
// Libraries
import { Row, Col } from 'antd';
// Components
import FormItem from '~/components/form/FormItem';
import Select from '~/components/select';
import Input from '~/components/input';
import Loading from '~/components/loading';
import Divider from '~/components/divider';
//utils
import { ValidateDate } from '~/utils/Validations';
import SocialMeAPI from '~/utils/SocialMeAPI';

export default function ContactForm({ form }) {
  const { translate } = useContext(LanguageContext);
  const [countries, setCountries] = useState([]);
  const [loadingCountries, setLoadingCountries] = useState(true);
  const [disabledStateSelect, setDisabledStateSelect] = useState(true);
  const [states, setStates] = useState([]);
  const [loadingStateSelect, setLoadingStateSelect] = useState(false);
  const [disabledCitiesSelect, setDisabledCitiesSelect] = useState(true);
  const [cities, setCities] = useState([]);
  const [loadingCitySelect, setLoadingCitySelect] = useState(false);
  const [getCountries, setGetCountries] = useState(false);
  const [getStates, setGetStates] = useState(false);
  const [getCities, setGetCities] = useState(false);

  useEffect(() => {
    async function GetCountries() {
      const params = {
        operation: ['System', 'Location', 'Get'],
        data: {
          filter: {
            type: 'COU',
          },
          pagination: {
            sort: {
              by: 'name',
              order: 'asc',
            },
          },
        },
      };
      const response = await SocialMeAPI(params);
      if (response.success) {
        let data = [];
        /*
        response.body.countryStateCityCommunityList.elements.map((item) => {
          let newItem = {};
          newItem.value = item.id;
          newItem.label = item.name;
          data.push(newItem);
          return null;
        });
        */
        let newItem = {};
        newItem.value =
          response.body.countryStateCityCommunityList.elements[29].id;
        newItem.label =
          response.body.countryStateCityCommunityList.elements[29].name;
        data.push(newItem);
        setCountries(data);
        setLoadingCountries(false);
        setGetCountries(true);
      }
    }
    GetCountries();
  }, []);

  useEffect(() => {
    async function GetStates(countryId) {
      const params = {
        operation: ['System', 'Location', 'Get'],
        data: {
          filter: {
            type: 'ST',
            country: countryId,
          },
          pagination: {
            sort: {
              by: 'name',
              order: 'asc',
            },
            limit: 0,
          },
        },
      };
      const response = await SocialMeAPI(params);
      if (response.success) {
        let data = [];
        response.body.countryStateCityCommunityList.elements.map((item) => {
          let newItem = {};
          newItem.value = item.id;
          newItem.label = item.name;
          data.push(newItem);
          return null;
        });
        setStates(data);
        setLoadingStateSelect(false);
        setDisabledStateSelect(false);
        setGetStates(true);
      }
    }
    if (form.getFieldsValue().birth_certificate_state) {
      GetStates(form.getFieldsValue().birth_certificate_country);
    } else {
      setGetStates(true);
    }
  }, [form]);

  useEffect(() => {
    async function GetCities(stateId) {
      const params = {
        operation: ['System', 'Location', 'Get'],
        data: {
          filter: {
            type: 'CI',
            state: stateId,
          },
          pagination: {
            sort: {
              by: 'name',
              order: 'asc',
            },
            limit: 0,
          },
        },
      };
      const response = await SocialMeAPI(params);
      if (response.success) {
        let data = [];
        response.body.countryStateCityCommunityList.elements.map((item) => {
          let newItem = {};
          newItem.value = item.id;
          newItem.label = item.name;
          data.push(newItem);
          return null;
        });
        setCities(data);
        setLoadingCitySelect(false);
        setDisabledCitiesSelect(false);
        setGetCities(true);
      }
    }
    if (form.getFieldsValue().birth_certificate_city) {
      GetCities(form.getFieldsValue().birth_certificate_state);
    } else {
      setGetCities(true);
    }
  }, [form]);

  async function GetStates(countryId) {
    const params = {
      operation: ['System', 'Location', 'Get'],
      data: {
        filter: {
          type: 'ST',
          country: countryId,
        },
        pagination: {
          sort: {
            by: 'name',
            order: 'asc',
          },
          limit: 0,
        },
      },
    };
    //console.log('params -> ', params)
    const response = await SocialMeAPI(params);
    //console.log('response -> ', response)
    if (response.success) {
      let data = [];
      response.body.countryStateCityCommunityList.elements.map((item) => {
        let newItem = {};
        newItem.value = item.id;
        newItem.label = item.name;
        data.push(newItem);
        return null;
      });
      setStates(data);
      setLoadingStateSelect(false);
    }
  }

  async function GetCities(stateId) {
    const params = {
      operation: ['System', 'Location', 'Get'],
      data: {
        filter: {
          type: 'CI',
          state: stateId,
        },
        pagination: {
          sort: {
            by: 'name',
            order: 'asc',
          },
          limit: 0,
        },
      },
    };
    const response = await SocialMeAPI(params);
    if (response.success) {
      let data = [];
      response.body.countryStateCityCommunityList.elements.map((item) => {
        let newItem = {};
        newItem.value = item.id;
        newItem.label = item.name;
        data.push(newItem);
        return null;
      });
      setCities(data);
      setLoadingCitySelect(false);
    }
  }

  async function SelectCountry(data) {
    form.setFieldsValue({
      birth_certificate_state: '',
    });
    form.setFieldsValue({
      birth_certificate_city: '',
    });
    setStates([]);
    setDisabledStateSelect(false);
    setLoadingStateSelect(true);
    setDisabledCitiesSelect(true);
    GetStates(data.value);
  }

  function SelectState(data) {
    form.setFieldsValue({
      birth_certificate_city: '',
    });
    setCities([]);
    setDisabledCitiesSelect(false);
    setLoadingCitySelect(true);
    GetCities(data.value);
  }

  return (
    <Row>
      <Divider style={{ margin: '10px 0px' }}>
        {translate.accountManagement.birth.text}
      </Divider>
      <Col xs={24} sm={24} md={4} lg={4} xl={4}>
        <FormItem
          label={translate.fields.birthLabel.text}
          name={['birth_certificate_date']}
          rules={[
            {
              required: true,
              message: translate.fields.birthIsRequired.text,
            },
            () => ({
              validator(rule, value) {
                if (ValidateDate(value) === false) {
                  return Promise.reject(translate.fields.invalidDate.text);
                }
                return Promise.resolve();
              },
            }),
          ]}
          item={
            <Input
              placeholder={translate.fields.enterYourBirth.text}
              mask="11/11/1111"
            />
          }
          style={{ marginRight: 10 }}
        />
      </Col>
      <Col xs={24} sm={24} md={6} lg={6} xl={6}>
        <FormItem
          label={translate.fields.birthCountryLabel.text}
          name={['birth_certificate_country']}
          style={{ marginRight: 10 }}
          item={
            getCountries ? (
              <Select
                options={countries}
                loading={loadingCountries}
                onSelect={(value, data) => SelectCountry(data)}
                placeholder={translate.fields.select.text}
              />
            ) : (
              <Loading height="30px" text=" " />
            )
          }
        />
      </Col>
      <Col xs={24} sm={24} md={6} lg={6} xl={6}>
        <FormItem
          label={translate.fields.birthStateLabel.text}
          name={['birth_certificate_state']}
          style={{ marginRight: 10 }}
          item={
            getStates ? (
              <Select
                options={states}
                loading={loadingStateSelect}
                disabled={disabledStateSelect}
                onSelect={(value, data) => SelectState(data)}
                placeholder={translate.fields.select.text}
              />
            ) : (
              <Loading height="30px" text=" " />
            )
          }
        />
      </Col>
      <Col xs={24} sm={24} md={8} lg={8} xl={8}>
        <FormItem
          label={translate.fields.birthCityLabel.text}
          name={['birth_certificate_city']}
          item={
            getCities ? (
              <Select
                options={cities}
                loading={loadingCitySelect}
                disabled={disabledCitiesSelect}
                //onSelect={(value, data) => SelectCity(data)}
                placeholder={translate.fields.select.text}
              />
            ) : (
              <Loading height="30px" text=" " />
            )
          }
        />
      </Col>
    </Row>
  );
}
