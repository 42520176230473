export default {
  yes: {
    original: 'Yes',
    text: 'Sim',
  },
  no: {
    original: 'No',
    text: 'Não',
  },
  back: {
    original: 'Back',
    text: 'Voltar',
  },
  login: {
    original: 'Login',
    text: 'Entrar',
  },
  next: {
    original: 'Next',
    text: 'Próxima',
  },
  cancel: {
    original: 'Cancel',
    text: 'Cancelar',
  },
  privacy: {
    original: 'Privacy',
    text: 'Privacidade',
  },
  terms: {
    original: 'Terms',
    text: 'Termos',
  },
  logout: {
    original: 'Logout',
    text: 'Sair',
  },
  settings: {
    original: 'Settings',
    text: 'Configurações',
  },
  language: {
    original: 'Language',
    text: 'Idioma',
  },
  select: {
    original: 'Select',
    text: 'Selecione',
  },
  update: {
    original: 'Update',
    text: 'Atualizar',
  },
  add: {
    original: 'Add',
    text: 'Adicionar',
  },
};
