import React from 'react';
// Components
import UserDisconnectedDescription from '~/components/pageStructure/UserDisconnectedDescription';

export default function Relationships() {
  return (
    <UserDisconnectedDescription
      description="Nesta página você poderá gerenciar seus relacionamentos familiares, adicionando integrantes da sua família, assim como visualizando sua árvore familiar e recebendo solicitações de outras pessoas."
    />
  );
}
