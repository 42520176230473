import React, { createContext, useContext, useEffect, useState } from 'react';
// Libraries
import styled from 'styled-components';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
// Contexts
import { AuthContext } from './AuthContext';
import { ThemeContext } from './ThemeContext';
// Components
import Button from '~/components/button';
import Card from '~/components/card';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';
import { GetModuleNameByHostname } from '~/utils/Hosts';
// Terms
import BeneficiaryTerms from '~/environments/Terms/beneficiaryTerms';
import DonorTerms from '~/environments/Terms/donorTerms';
import PartnerTerms from '~/environments/Terms/partnerTerms';
import ManagerTerms from '~/environments/Terms/managerTerms';

const Container = styled.div`
  background-color: ${(style) => style.backgroundColor || '#fff'};
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: ${(style) => style.primary || '#fff'};
`;

export const ContractsContext = createContext();

let hostname = GetModuleNameByHostname();

const ContractsContextProvider = ({ children }) => {
  const { user, GetUserAgain } = useContext(AuthContext);
  const { theme } = useContext(ThemeContext);

  const [loadInterface, setLoadInterface] = useState(true);
  const [permission, setPermission] = useState(false);

  useEffect(() => {
    if (user) {
      if (hostname === 'beneficiary') {
        if (user.role) {
          if (user.role.BENEFICIARY) {
            setPermission(true);
          }
          return setLoadInterface(true);
        }
      }
      if (hostname === 'donor') {
        if (user.role) {
          if (user.role.GIVER) {
            setPermission(true);
          }
          return setLoadInterface(true);
        }
      }
      if (hostname === 'partner') {
        if (user.role) {
          if (user.role.PARTNER) {
            setPermission(true);
          }
          return setLoadInterface(true);
        }
      }
      if (hostname === 'manager') {
        if (user.role) {
          if (user.role.MANAGER) {
            setPermission(true);
          }
          return setLoadInterface(true);
        }
      }
      setPermission(true);
      setLoadInterface(true);
    }
    setPermission(true);
    setLoadInterface(true);
  }, [user])

  async function AddRole(operationName) {
    const params = {
      operation: ['Profile', 'Roles', 'Add', operationName],
    };
    const response = await SocialMeAPI(params);
    if (response.success) {
      GetUserAgain();
    }
  }

  const BeneficiaryContract = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10 }}>
        <Card style={{ width: '95%' }}>
          <BeneficiaryTerms />
          <Button type="primary" size="large" onClick={() => AddRole('Beneficiary')}>Li e concordo</Button>
        </Card>
      </div>
    )
  }
  const DonorContract = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10 }}>
        <Card style={{ width: '95%' }}>
          <DonorTerms />
          <Button type="primary" size="large" onClick={() => AddRole('Giver')}>Li e concordo</Button>
        </Card>
      </div>
    )
  }
  const PartnerContract = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10 }}>
        <Card style={{ width: '95%' }}>
          <PartnerTerms />
          <Button type="primary" size="large" onClick={() => AddRole('Partner')}>Li e concordo</Button>
        </Card>
      </div>
    )
  }
  const ManagerContract = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10 }}>
        <Card style={{ width: '95%' }}>
          <ManagerTerms />
          <Button type="primary" size="large" onClick={() => AddRole('Manager')}>Li e concordo</Button>
        </Card>
      </div>
    )
  }

  const Contract = () => {
    
    if (hostname === 'beneficiary') {
      return <BeneficiaryContract />
    }
    if (hostname === 'donor') {
      return <DonorContract />
    }
    if (hostname === 'partner') {
      return <PartnerContract />
    }
    if (hostname === 'manager') {
      return <ManagerContract />
    }
    return <div/>
  }

  return (
    <ContractsContext.Provider value={{}}>
      {!loadInterface ?
        <Container
          style={{
            backgroundColor:
              (theme && theme.color && theme.color.backgroundSecondary) ||
              '#fff',
            color: (theme && theme.color && theme.color.primary) || '#fff',
          }}
        >
          <Spin
            style={{
              color: (theme && theme.color && theme.color.primary) || '#fff',
            }}
            indicator={
              <LoadingOutlined
                style={{
                  fontSize: 24,
                  color:
                    (theme && theme.color && theme.color.primary) || '#fff',
                }}
                spin
              />
            }
            tip={`Verificando contratos...`}
          />
        </Container>
        :
        permission ?
          children
          :
          <Contract />
      }
    </ContractsContext.Provider>
  );
};

export default ContractsContextProvider;
