import React from 'react';
// Components
import Card from '~/components/card';
import Divider from '~/components/divider';
import Title from '~/components/typography/title';
import Text from '~/components/typography/text';

import CardItem from './components/CardItem';

import MePagamentos from '~/assets/images/brand/mePagamentos2.png';
import DoacoesMe from '~/assets/images/brand/doacoesMe2.png';
import MeLiga from '~/assets/images/brand/meLiga.png';
import MeCidadao from '~/assets/images/brand/meCidadao.png';
import MeBeneficios from '~/assets/images/brand/meBeneficios.png';
import HubMe from '~/assets/images/brand/hubMe.png';
import TrocadosMe from '~/assets/images/brand/trocadosMe.png';

const SocialMe = 'https://socialme-assets.s3.us-east-1.amazonaws.com/images/brands/socialMe.png'
const SIGAM = 'https://media.milanote.com/p/images/1L5DiY1bzJOVfs/WzD/SIGAM-02.png'


export default function DataProtection() {
  return (
    <div>
      <Card>
        <Title level={4}>Proteção de dados</Title>
        <Text>Seus dados pertecem somente a você. Escolha quem pode visualizar seu cadastro Social Me.</Text>
        <Divider />
        <div>
          <CardItem image={SocialMe} heightImage="40px" drawerType={2} />
          <CardItem image={MePagamentos} heightImage="40px" drawerType={2} />
          <CardItem image={TrocadosMe} heightImage="40px" drawerType={2} />
          <CardItem image={DoacoesMe} heightImage="20px" drawerType={2} />
          <CardItem image={MeLiga} heightImage="40px" drawerType={2} />
          <CardItem image={MeCidadao} heightImage="40px" drawerType={2} />
          <CardItem image={MeBeneficios} heightImage="40px" drawerType={2} />
          <CardItem image={HubMe} heightImage="40px" drawerType={2} />
          <CardItem image={SIGAM} heightImage="40px" drawerType={1} />
        </div>
      </Card>
    </div>
  );
}
