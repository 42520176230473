import React from 'react';
// Libraries
import { Radio } from 'antd';
// Components
import FormItem from '~/components/form/FormItem';
import InputNumber from '~/components/inputNumber';
import CheckboxGroup from '~/components/checkboxGroup';
import RadioGroup from '~/components/radioGroup';


export default function HomelessQuestionnaire() {
  return (
    <div>
      <Question1 />
      <Question2 />
      <Question3 />
      <Question4 />
      <Question5 />
      <Question6 />
      <Question7 />
      <Question8 />
      <Question9 />
      <Question10 />
    </div>
  )
}

const Question1 = () => {
  return (
    <FormItem
      label="1) Você é Morador de Rua?"
      name={['sc_homeless']}
      item={
        <Radio.Group>
          <Radio value={true}>Sim</Radio>
          <Radio value={false}>Não</Radio>
        </Radio.Group>
      }
    />
  )
}

const Question2 = () => {
  return (
    <FormItem
      label="2) Onde você costuma dormir? (Este quesito admite múltipla marcação)"
      item={
        <div>
          <FormItem
            label="1 - Rua. Quantas vezes por semana?"
            name={['sc_homeless_weekly_sleep_street']}
            value={0}
            item={
              <InputNumber min={0} />
            }
          />
          <FormItem
            label="2 - Albergue. Quantas vezes por semana?"
            name={['sc_homeless_weekly_sleep_hoste']}
            item={
              <InputNumber min={0} />
            }
          />
          <FormItem
            label="3 - Domicílio particular. Quantas vezes por semana?"
            name={['sc_homeless_weekly_sleep_private_home']}
            item={
              <InputNumber min={0} />
            }
          />
          <FormItem
            label="4 - Outro. Quantas vezes por semana?"
            name={['sc_homeless_weekly_sleep_other']}
            item={
              <InputNumber min={0} />
            }
          />
        </div>
      }
    />
  )
}

const Question3 = () => {
  const options = [
    { label: '1 - Perda de moradia', value: 'lostHouse' },
    { label: '2 - Ameaça/violência', value: 'violence' },
    { label: '3 - Problemas com familiares/companheiro(a)', value: 'familyProblems' },
    { label: '4 - Alcoolismo/drogas', value: 'drugs' },
    { label: '5 - Desemprego', value: 'unemployment' },
    { label: '6 - Trabalho', value: 'work' },
    { label: '7 - Tratamento de saúde', value: 'health' },
    { label: '8 - Preferência/opção própria', value: 'ownOption' },
    { label: '9 - Outro', value: 'other' },
    { label: '10 - Não sabe/não lembra', value: 'doesntRemember' },
  ];
  return (
    <FormItem
      label="3) Quais os principais motivos pelos quais você passou a morar na rua/albergue/outro? (Este quesito admite múltipla marcação.)"
      name={['sc_homeless_reason']}
      item={
        <CheckboxGroup options={options} />
      }
    />
  )
}

const Question4 = () => {
  const options = [
    { label: '1 - Menos de seis meses', value: 'Menos de seis meses' },
    { label: '2 - Entre seis meses e um ano', value: 'Entre seis meses e um ano' },
    { label: '3 - Entre um e dois anos', value: 'Entre um e dois anos' },
    { label: '4 - Entre dois e cinco ano', value: 'Entre dois e cinco ano' },
    { label: '5 - Entre cinco e dez anos', value: 'Entre cinco e dez anos' },
    { label: '6 - Mais de dez anos', value: 'Mais de dez anos' },
  ];
  return (
    <FormItem
      label="4) Há quanto tempo você vive na rua?"
      name={['sc_homeless_how_long']}
      item={
        <RadioGroup options={options} />
      }
    />
  )
}

const Question5 = () => {
  return (
    <FormItem
      label="5) Vive com sua família na rua?"
      name={['sc_homeless_family']}
      item={
        <Radio.Group>
          <Radio value={true}>Sim</Radio>
          <Radio value={false}>Não</Radio>
        </Radio.Group>
      }
    />
  )
}

const Question6 = () => {
  const options = [
    { label: '1 - Todo dia', value: 'Todo dia' },
    { label: '2 - Toda semana', value: 'Toda semana' },
    { label: '3 - Todo mês', value: 'Todo mês' },
    { label: '4 - Todo ano', value: 'Todo ano' },
    { label: '5 - Quase nunca', value: 'Quase nunca' },
    { label: '6 - Nunca', value: 'Nunca' },
  ];
  return (
    <FormItem
      label="6) Você tem contato com parentes que vivam fora da rua?"
      name={['sc_homeless_contact_with_non_homeless_relatives']}
      item={
        <RadioGroup options={options} />
      }
    />
  )
}

const Question7 = () => {
  return (
    <FormItem
      label="7) Nos últimos seis meses você frequentou ou participou de alguma atividade comunitária?"
      item={
        <div>
          <FormItem
            label="1 - Escola"
            name={['sc_last_semester_community_activity_participated_school']}
            item={
              <Radio.Group>
                <Radio value={true}>Sim</Radio>
                <Radio value={false}>Não</Radio>
              </Radio.Group>
            }
          />
          <FormItem
            label="2 - Associação"
            name={['sc_last_semester_community_activity_participated_association']}
            item={
              <Radio.Group>
                <Radio value={true}>Sim</Radio>
                <Radio value={false}>Não</Radio>
              </Radio.Group>
            }
          />
          <FormItem
            label="3 - Cooperativa"
            name={['sc_last_semester_community_activity_participated_cooperative']}
            item={
              <Radio.Group>
                <Radio value={true}>Sim</Radio>
                <Radio value={false}>Não</Radio>
              </Radio.Group>
            }
          />
          <FormItem
            label="4 - Movimento social"
            name={['sc_last_semester_community_activity_participated_social_movement']}
            item={
              <Radio.Group>
                <Radio value={true}>Sim</Radio>
                <Radio value={false}>Não</Radio>
              </Radio.Group>
            }
          />
          <FormItem
            label="5 - Não sabe/não lembra"
            name={['sc_last_semester_community_activity_participated_doesnt_remember']}
            item={
              <Radio.Group>
                <Radio value={true}>Sim</Radio>
                <Radio value={false}>Não</Radio>
              </Radio.Group>
            }
          />
          <FormItem
            label="6 - Prefiro não responder"
            name={['sc_last_semester_community_activity_participated_no_answer']}
            item={
              <Radio.Group>
                <Radio value={true}>Sim</Radio>
                <Radio value={false}>Não</Radio>
              </Radio.Group>
            }
          />
        </div>
      }
    />
  )
}

const Question8 = () => {
  return (
    <FormItem
      label="8) Nos últimos seis meses você foi atendido em algum dos lugares listados abaixo?"
      item={
        <div>
          <FormItem
            label="1 - Centro de Referência da Assistência Social - CRAS"
            name={['sc_last_semester_attended_social_assistance']}
            item={
              <Radio.Group>
                <Radio value={true}>Sim</Radio>
                <Radio value={false}>Não</Radio>
              </Radio.Group>
            }
          />
          <FormItem
            label="2 - Centro de Referência Especializado da Assistência Social - CREAS"
            name={['sc_last_semester_attended_specialized_social_assistance']}
            item={
              <Radio.Group>
                <Radio value={true}>Sim</Radio>
                <Radio value={false}>Não</Radio>
              </Radio.Group>
            }
          />
          <FormItem
            label="3 - Centro de referência para população em situação de rua"
            name={['sc_last_semester_attended_center_for_homeless']}
            item={
              <Radio.Group>
                <Radio value={true}>Sim</Radio>
                <Radio value={false}>Não</Radio>
              </Radio.Group>
            }
          />
          <FormItem
            label="4 - Instituição de acolhimento governamental (albergue/abrigo/outro)"
            name={['sc_last_semester_attended_government_hostel_institution']}
            item={
              <Radio.Group>
                <Radio value={true}>Sim</Radio>
                <Radio value={false}>Não</Radio>
              </Radio.Group>
            }
          />
          <FormItem
            label="5 - Instituição de acolhimento não-governamental (instituição beneficente privada/outro)"
            name={['sc_last_semester_attended_non_government_hostel_institution']}
            item={
              <Radio.Group>
                <Radio value={true}>Sim</Radio>
                <Radio value={false}>Não</Radio>
              </Radio.Group>
            }
          />
          <FormItem
            label="6 - Hospital/clínica geral"
            name={['sc_last_semester_attended_hospital']}
            item={
              <Radio.Group>
                <Radio value={true}>Sim</Radio>
                <Radio value={false}>Não</Radio>
              </Radio.Group>
            }
          />
        </div>
      }
    />
  )
}

const Question9 = () => {
  return (
    <FormItem
      label="9) Alguma vez você teve emprego com carteira de trabalho assinada?"
      name={['fw_formal']}
      item={
        <Radio.Group>
          <Radio value={true}>Sim</Radio>
          <Radio value={false}>Não</Radio>
        </Radio.Group>
      }
    />
  )
}

const Question10 = () => {
  const options = [
    { label: '1 - Construção civil', value: 'Construção civil' },
    { label: '2 - Guardador de carro/flanelinha', value: 'Guardador de carro/flanelinha' },
    { label: '3 - Carregador/estivador', value: 'Carregador/estivador' },
    { label: '4 - Catador de material reciclável', value: 'Catador de material reciclável' },
    { label: '5 - Serviços gerais/limpeza /outro', value: 'Serviços gerais/limpeza /outro' },
    { label: '6 - Pede dinheiro', value: 'Pede dinheiro' },
    { label: '7 - Vendas', value: 'Vendas' },
    { label: '8 - Outro', value: 'Outro' },
  ];
  return (
    <FormItem
      label="10) O que você faz para ganhar dinheiro? (Este quesito admite múltipla marcação)"
      name={['work_activities']}
      item={
        <CheckboxGroup options={options} />
      }
    />
  )
}