import React from 'react';
// Components
import UserDisconnectedDescription from '~/components/pageStructure/UserDisconnectedDescription';

export default function BasicInformation() {
  return (
    <UserDisconnectedDescription
      description="Nesta página você pode editar informações pessoais utilizadas nos serviços da SocialMe, como nome civil, nome social, data de nascimento, foto de perfil e a carteira de identidade da SocialMe, chamada “Me Identifica”."
    />
  );
}
