import React, { useState } from 'react';

import Content from './components/Content';
import Footer from './components/Footer';
import Header from './components/Header';

import Card from '~/components/card';

export default function MessageArea({ theme }) {
  const [text, setText] = useState();

  const cardStyle = {
    minHeight: '85vh',
    marginRight: 10,
  };

  return (
    <Card style={cardStyle}>
      <Header theme={theme} />
      <Content />
      <Footer text={text} setText={setText} theme={theme} />
    </Card>
  );
}
