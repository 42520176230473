import styled from 'styled-components';
//Environments
import System from '~/environments/System';

const responsiveBreakpoint = `${System.responsiveBreakpoint}px`;

export const Container = styled.div`
  width: 100%;
  background-color: ${(style) => style.backgroundColor || '#fff'};
  height: 100%;
  overflow-x: hidden;
  position: relative;
  @media (max-width: ${responsiveBreakpoint}) {
    /* */
  }
`;

export const Link = styled.a``;
