import React, { memo } from 'react';

import Header from './components/Header';
import Contacts from './components/Contacts';

import Card from '~/components/card';
import Divider from '~/components/divider';

function SideBar({ theme }) {
  const cardStyle = {
    minHeight: '85vh',
    marginRight: 10,
  };

  return (
    <Card style={cardStyle}>
      <Header />
      <Divider />
      <Contacts theme={theme} />
    </Card>
  );
}

export default memo(SideBar);
