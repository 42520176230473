import React, { useContext, useState } from 'react';
// Libraries
import { message, Drawer, Form } from 'antd';
// Components
import Questions from './components/Questions';
import Button from '~/components/button';
// Contexts
import { AxiosContext } from '~/contexts/AxiosContext';
import { ResponsiveContext } from '~/contexts/ResponsiveContext';

export default function Actions({ title, person, GetProfileAgain }) {
  const { CallToTheAPI } = useContext(AxiosContext);
  const { isMobile } = useContext(ResponsiveContext);

  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState('');

  const ShowDrawer = () => {
    setVisibleDrawer(true);
  };
  const CloseDrawer = () => {
    setVisibleDrawer(false);
  };
  async function EditProfile(data) {
    const params = {
      operation: ['Profile', 'Edit'],
      data,
    };
    const response = await CallToTheAPI(params);
    if (response.success) {
      message.success('Documento Atualizado!');
      setLoadingButton(false);
      CloseDrawer();
      return GetProfileAgain();
    }
    message.error('Alguma coisa aconteceu...');
    setLoadingButton(false);
  }

  const onFinish = (data) => {
    //console.log('Received data of form: ', data);
    if (data.cns_number) data.cns_number = data.cns_number.replace(/[^\d]+/g, '');
    //console.log('tratamento ', data);
    EditProfile(data);
  };
  return (
    <>
      <Button
        text="Editar"
        onClick={() => ShowDrawer()}
        style={{ marginRight: 5 }}
      />

      <Drawer
        title={title}
        width={isMobile ? '100%' : 600}
        onClose={CloseDrawer}
        visible={visibleDrawer}
        bodyStyle={{ padding: 20 }}
        footer={
          <div
            style={{
              textAlign: 'right',
            }}
          >
            <Button
              onClick={CloseDrawer}
              style={{ marginRight: 8 }}
            >
              Cancelar
              </Button>

            <Button
              loading={loadingButton}
              disabled={loadingButton}
              type="primary"
              onClick={() => {
                form
                  .validateFields()
                  .then((values) => {
                    //form.resetFields();
                    //form.setFieldsValue(values);
                    onFinish(values);
                  })
                  .catch((info) => {
                    //console.log('Validate Failed:', info);
                  });
              }}
            >
              Atualizar
              </Button>
          </div>
        }
      >
        <Form
          labelCol={{
            xs: { span: 24 },
            sm: { span: 24 },
          }}
          wrapperCol={{
            xs: { span: 24 },
            sm: { span: 24 },
          }}
          form={form}
          layout="vertical"
          size="small"
          onValuesChange={() => setFormValues(form.getFieldsValue())}
          scrollToFirstError
          initialValues={person}
        >
          <Questions formValues={formValues} form={form} />
        </Form>
      </Drawer>
    </>
  );
}
