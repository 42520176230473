export default {
    SM0ACEA3C: {
      message: 'The address was successfully deleted',
      //Profile.Address.Delete
    },
    SM5753FD0: {
      message: 'The contact was successfully deleted',
      //Profile.Contact.Delete
    },
    SM783805B: {
      message: "The 'file' was successfully deleted",
      //Profile.Avatar.Delete
    },
    SM7E62414: {
      message: "The 'device' was successfully deleted",
    },
    SMFB224F5: {
      message: "The 'Custom question' was successfully deleted",
    }
  };
  