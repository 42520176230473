import styled from 'styled-components';

export const Container = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 10px;
  border-radius: 10px;
`;
export const FirstRow = styled.div`
  display: flex;
  justify-content: center;
`;

export const SecondRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 10px 0px;
`;

export const ImageTitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const ActionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
