import React, { useContext } from 'react';
// Libraries
import { BsGear } from 'react-icons/bs';
import {
  AiOutlineMessage,
  AiOutlineInfoCircle,
  AiOutlineNotification,
} from 'react-icons/ai';
import {
  FiCalendar,
  FiMapPin,
  FiFolder,
  FiPhone,
  FiUser,
  FiLock
} from 'react-icons/fi';
import { GiFamilyTree } from 'react-icons/gi';
import {HiLink} from 'react-icons/hi'
import { Route, Switch, Redirect } from 'react-router-dom';
import { Layout } from 'antd';
// Contexts
import { ThemeContext } from '~/contexts/ThemeContext';
import { LanguageContext } from '~/contexts/LanguageContext';
// Components
import Sidebar from '~/components/sidebar';
import MainNavbar from '~/components/navbar/Main';
// Pages
import Begin from './pages/Begin';
import BasicInformation from './pages/BasicInformation';
import Documents from './pages/Documents';
import Config from './pages/Config';
import Contact from './pages/Contact';
import Addresses from './pages/Addresses';
import Timeline from './pages/Timeline';
import Relationships from './pages/Relationships';
import Messager from './pages/Messager';
import DataProtection from './pages/DataProtection';
import Bindings from './pages/Bindings';
import Notifications from './pages/Notifications';
// import Family from './pages/Family';
// import WeaknessesAndDisabilities from './pages/WeaknessesAndDisabilities';
// import WorkAndRemuneration from './pages/WorkAndRemuneration';
// import LinkToProgramsAndServices from './pages/LinkToProgramsAndServices';
// Utils
import { slugMask } from '~/utils/Masks';

const { Content } = Layout;

export default function Profile() {
  const { theme } = useContext(ThemeContext);
  const { translate } = useContext(LanguageContext);

  const sidebarItems = [
    {
      index: 0,
      title: 'First',
    },
    {
      index: 1,
      title: translate.accountManagement.begin.text,
      url: `/${slugMask(translate.accountManagement.begin.text)}`,
      icon: FiUser,
    },
    {
      index: 2,
      title: translate.accountManagement.basicInformation.text,
      url: `/${slugMask(translate.accountManagement.basicInformation.text)}`,
      icon: AiOutlineInfoCircle,
    },
    {
      index: 3,
      title: translate.accountManagement.documents.text,
      url: `/${slugMask(translate.accountManagement.documents.text)}`,
      icon: FiFolder,
    },
    {
      index: 4,
      title: translate.accountManagement.contacts.text,
      url: `/${slugMask(translate.accountManagement.contacts.text)}`,
      icon: FiPhone,
    },
    {
      index: 5,
      title: translate.accountManagement.adresses.text,
      url: `/${slugMask(translate.accountManagement.adresses.text)}`,
      icon: FiMapPin,
    },
    {
      index: 6,
      title: translate.accountManagement.timeline.text,
      url: `/${slugMask(translate.accountManagement.timeline.text)}`,
      icon: FiCalendar,
    },
    {
      index: 7,
      title: translate.accountManagement.relationships.text,
      url: `/${slugMask(translate.accountManagement.relationships.text)}`,
      icon: GiFamilyTree,
    },
    {
      index: 8,
      title: translate.accountManagement.messager.text,
      url: `/${slugMask(translate.accountManagement.messager.text)}`,
      icon: AiOutlineMessage,
    },
    {
      index: 9,
      title: translate.accountManagement.notifications.text,
      url: `/${slugMask(translate.accountManagement.notifications.text)}`,
      icon: AiOutlineNotification,
    },
    /*
    {
      index: 7,
      title: translate.accountManagement.family.text,
      url: `/${slugMask(translate.accountManagement.family.text)}`,
    },
    {
      index: 8,
      title: translate.accountManagement.weaknessesAndDeficiencies.text,
      url: `/${slugMask(translate.accountManagement.weaknessesAndDeficiencies.text)}`,
    },
    {
      index: 9,
      title: translate.accountManagement.workAndRemuneration.text,
      url: `/${slugMask(translate.accountManagement.workAndRemuneration.text)}`,
    },
    {
      index: 10,
      title: translate.accountManagement.bindings.text,
      url: `/${slugMask(translate.accountManagement.bindings.text)}`,
    },
    */
    {
      index: 10,
      title: 'Last',
    },
    {
      index: 11,
      title: 'Vinculações',
      url: `/vinculacoes`,
      icon: HiLink,
    },
    {
      index: 12,
      title: 'Proteção de dados',
      url: `/protecao-de-dados`,
      icon: FiLock,
    },
    {
      index: 13,
      title: translate.accountManagement.settings.text,
      url: `/${slugMask(translate.accountManagement.settings.text)}`,
      icon: BsGear,
    },
    {
      index: 14,
      title: 'Last',
    },
  ];

  return (
    <div>
      <Layout
        style={{
          backgroundColor: theme.color.backgroundPrimary,
          height: '100vh',
        }}
      >
        <Sidebar items={sidebarItems} />
        <Layout
          style={{
            padding: 10,
            backgroundColor: theme.color.backgroundSecondary,
          }}
        >
          <MainNavbar />
          <Content
            style={{
              padding: '10px 5px',
              overflow: 'auto',
            }}
          >
            <Switch>
              <Route exact path="/">
                <Redirect
                  to={`/${slugMask(translate.accountManagement.begin.text)}`}
                />
              </Route>
              <Route
                path={`/${slugMask(translate.accountManagement.begin.text)}`}
                component={Begin}
              />
              <Route
                path={`/${slugMask(
                  translate.accountManagement.basicInformation.text
                )}`}
                component={BasicInformation}
              />
              <Route
                path={`/${slugMask(
                  translate.accountManagement.documents.text
                )}`}
                component={Documents}
              />
              <Route
                path={`/${slugMask(translate.accountManagement.contacts.text)}`}
                component={Contact}
              />
              <Route
                path={`/${slugMask(translate.accountManagement.adresses.text)}`}
                component={Addresses}
              />
              <Route
                path={`/${slugMask(translate.accountManagement.timeline.text)}`}
                component={Timeline}
              />
              <Route
                path={`/${slugMask(translate.accountManagement.settings.text)}`}
                component={Config}
              />
              <Route
                path={`/${slugMask(translate.accountManagement.messager.text)}`}
                component={Messager}
              />
              <Route
                path={`/${slugMask(
                  translate.accountManagement.notifications.text
                )}`}
                component={Notifications}
              />
              <Route path="/relacionamentos" component={Relationships} />
              <Route path="/protecao-de-dados" component={DataProtection} />
              <Route path="/vinculacoes" component={Bindings} />
              <Route path="*">
                <Redirect
                  to={`/${slugMask(
                    translate.accountManagement.basicInformation.text
                  )}`}
                />
              </Route>
              {/* 
               
            
              <Route path={`/contatos`} component={Contact} />
              <Route path={`/enderecos`} component={Addresses} />
              
              <Route path={`/relacionamentos`} component={Relationships} />
              <Route path={`/familia`} component={Family} />
              <Route
                path={`/debilitacoes-e-deficiencias`}
                component={WeaknessesAndDisabilities}
              />
              <Route
                path={`/trabalho-e-remuneracao`}
                component={WorkAndRemuneration}
              />
              <Route
                path={`/vinculacao-a-programas-e-servicos`}
                component={LinkToProgramsAndServices}
              />
             
              <Route exact path={`*`}>
                <Redirect to="/informacoes-basicas" />
              </Route>
              */}
            </Switch>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
}
