import React, { useContext } from 'react';
// Contexts
import { LanguageContext } from '~/contexts/LanguageContext';
// Components
import Loading from '~/components/loading';
// Kinds
import PF from './kind/PF';
import PJ from './kind/PJ';
// Utils
import { UserKind } from '~/utils/UserInformation';

export default function Profile() {
  const { translate } = useContext(LanguageContext);

  function RenderProfile() {
    const kind = UserKind();
    if (kind) {
      if (kind === 'PF') return <PF />
      if (kind === 'PJ') return <PJ />
    }
    return <Loading height="100vh" text={translate.messages.checkingUser.text} />
  }

  return RenderProfile()
}
