import React, { useContext } from 'react';
// Libraries
import { Row, Col, Popconfirm, message } from 'antd';
// Contexts
import { ThemeContext } from '~/contexts/ThemeContext';
// Own Components
import ProfileCard from './components/ProfileCard';
// Components
import Button from '~/components/button';
import Divider from '~/components/divider';
// Utils
import { UserSex, UserKind } from '~/utils/UserInformation';
// Terms
import BeneficiaryTerms from './Terms/beneficiaryTerms';
import DonorTerms from './Terms/donorTerms';
import PartnerTerms from './Terms/partnerTerms';
import ManagerTerms from './Terms/managerTerms';

export default function PerformanceProfiles() {
  const { theme } = useContext(ThemeContext);
  function confirm(e) {
    message.success('Pedido de exclusão enviado com sucesso!');
  }
  return (
    <div>
      <Row>
        <Col xs={24} sm={24} md={6} lg={6} xl={6}>
          <ProfileCard
            imageName="beneficiary"
            operationName="Beneficiary"
            name={UserSex() === 'M' ? 'Beneficiário' : 'Beneficiária'}
            buttonText={
              UserSex() === 'M' ? 'um beneficiário' : 'uma beneficiária'
            }
            terms={<BeneficiaryTerms />}
          />
        </Col>
        <Col xs={24} sm={24} md={6} lg={6} xl={6}>
          <ProfileCard
            imageName="donor"
            operationName="Giver"
            name={UserSex() === 'M' ? 'Doador' : 'Doadora'}
            buttonText={UserSex() === 'M' ? 'um doador' : 'uma doadora'}
            terms={<DonorTerms />}
          />
        </Col>
        {UserKind() === 'PJ' ? (
          <>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <ProfileCard
                imageName="partner"
                operationName="Partner"
                name={UserSex() === 'M' ? 'Parceiro' : 'Parceira'}
                buttonText={UserSex() === 'M' ? 'um parceiro' : 'uma parceira'}
                terms={<PartnerTerms />}
              />
            </Col>
         
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <ProfileCard
                imageName="manager"
                operationName="Manager"
                name={UserSex() === 'M' ? 'Gestor' : 'Gestora'}
                buttonText={UserSex() === 'M' ? 'um gestor' : 'uma gestora'}
                terms={<ManagerTerms />}
              />
            </Col>
            
          </>
        ) : (
            <></>
          )}
      </Row>
      <Divider />
      <Row>
        <Col span={24}>
          <Popconfirm
            title="Tem certeza que deseja entrar com pedido para excluir seu cadastro?"
            onConfirm={confirm}
            okText="Sim, tenho certeza"
            cancelText="Não"
          >
            <Button type="dashed" style={{ backgroundColor: theme.color.danger, color: 'white' }}>Apagar cadastro Social Me</Button>
          </Popconfirm>
        </Col>
      </Row>
    </div>
  );
}
