import React from 'react';

import {
  NotificationOutlined,
  AlertOutlined,
  WarningOutlined,
} from '@ant-design/icons';

import Text from '~/components/typography/text';

import { Container, Box } from './style';

export default function Notifications({ theme }) {
  const fakeNotifications = [
    {
      Icon: NotificationOutlined,
      color: theme.color.white,
      backgroundColor: theme.color.primaryLight,
      text:
        'Olá Matheus, o seu teste do Covid-19 deu negativo, pode ficar tranquilo.',
    },
    {
      Icon: AlertOutlined,
      color: theme.color.white,
      backgroundColor: theme.color.danger,
      text: 'Olá Matheus, faz 2 anos que não realiza a consulta ao dentista',
    },

    {
      Icon: WarningOutlined,
      color: theme.color.white,
      backgroundColor: theme.color.warning,
      text:
        'Matheus, você poderia realizar atividades físicas para tratar de sua saúde',
    },
    {
      Icon: AlertOutlined,
      color: theme.color.white,
      backgroundColor: theme.color.danger,
      text:
        'Olá Matheus, o seu teste do Covid-19 deu positivo, por favor ficar em casa em quarentena',
    },
    {
      Icon: NotificationOutlined,
      color: theme.color.white,
      backgroundColor: theme.color.primaryLight,
      text: 'Olá Matheus, foi realizado uma doação para você, meus parabêns!',
    },
    {
      Icon: AlertOutlined,
      color: theme.color.white,
      backgroundColor: theme.color.danger,
      text: 'Olá Matheus, faz 1 ano que você não faz exames médicos.',
    },
  ];

  return (
    <Container
      scrollBarColor={theme.color.white}
      scrollBarThumbColor={theme.color.scrollBar}
    >
      {fakeNotifications.map((notification) => {
        const { backgroundColor, color, Icon, text } = notification;

        const iconStyle = {
          backgroundColor,
          color,
          borderRadius: '50%',
          marginRight: 10,
          padding: 10,
          fontSize: 14,
        };

        return (
          <Box key={notification.text}>
            <Icon style={iconStyle} />

            <Text>{`${text.substr(0, 40)}...`}</Text>
          </Box>
        );
      })}
    </Container>
  );
}
