import React, { useEffect, useState, useContext } from 'react';
// Contexts
import { LanguageContext } from '~/contexts/LanguageContext';
// Components
import Progress from '~/components/progress';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';

export default function ProgressProfile() {
    const { translate } = useContext(LanguageContext);
    const [percentage, setPercentage] = useState(0)

    useEffect(() => {
        async function GetProfilePercentage() {
            const params = {
                operation: ['Profile', 'GetProfilePercentage']
            };
            const response = await SocialMeAPI(params);
            if (response.success) {
                setPercentage(response.body.percentage.percentage)
            }
        }
        GetProfilePercentage();
    }, [])

    return (
        <Progress percent={percentage} size="small" status="active" tooltipText={translate.accountManagement.profilePercentage.text} />
    );
}
