import React, { memo } from 'react';

import Text from '~/components/typography/text';
import Avatar from '~/components/avatar';

import { Container, PersonInfoBox, SearchIcon } from './style';

function Header({ theme }) {
  return (
    <Container>
      <PersonInfoBox>
        <Avatar
          style={{ marginRight: 15 }}
          src="https://uifaces.co/our-content/donated/xZ4wg2Xj.jpg"
          size={48}
        />
        <Text>Matheus Ribeiro</Text>
      </PersonInfoBox>
      <SearchIcon />
    </Container>
  );
}

export default memo(Header);
