import React from 'react';
// Components
import FormItem from '~/components/form/FormItem';
import Input from '~/components/input';

export default function CPFForm({ formValues, form }) {
  return (
    <div>
      <Number />
    </div>
  );
}

const Number = () => {
  return (
    <FormItem
      label={"Número do CPF"}
      name={['cpf_number']}
      rules={[
        {
          required: true,
          message: 'Um número é obrigatório',
        },
      ]}
      item={
        <Input mask="111.111.111-11" />
      }
    />
  )
}