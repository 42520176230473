import React from 'react';
//Libraries
import { Space } from 'antd';
//Views
import ViewCNPJ from './components/ViewCNPJ';

export default function OwnedDocumentsContainer({
  ownedDocuments,
  person,
  GetProfileAgain,
}) {
  function RenderView(name) {
    switch (name) {
      case 'CNPJ':
        return (
          <ViewCNPJ
            name={name}
            person={person}
            GetProfileAgain={GetProfileAgain}
          />
        );

      default:
        return 'null';
    }
  }

  return (
    <div>
      <Space style={{ width: '100%' }} direction={'vertical'}>
        {ownedDocuments.map((item) => (
          <div key={item}>{RenderView(item)}</div>
        ))}
      </Space>
    </div>
  );
}
