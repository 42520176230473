export default {
  ResponsiveBreakpoint: 680,
  TabletBreakpoint: 1023,
  hostname: {
    administration: 'admin.socialme.com.br',
    authentication: 'conta.socialme.com.br',
    accountManagement: 'perfil.socialme.com.br',
    beneficiary: 'beneficiario.socialme.com.br',
    citizen: '',
    digitalWallet: 'carteira.socialme.com.br',
    donationsPortal: 'doacoes.me',
    donor: 'doador.socialme.com.br',
    ecoop: 'ropetec.com.br',
    manager: 'gestor.socialme.com.br',
    marketplace: 'feiralivre.me',
    medicalRecord: '',
    operationsFinder: 'achei.me',
    partner: 'parceiro.socialme.com.br',
    paymentGateway: 'pagamentos.socialme.com.br',
    telephoneOperator: '',
  },
  cookie: {
    expire: 'Thu, 30 Dec 2060 23:59:59 UTC',
    name: {
      browser: 'brwsrtkn',
      user: 'usrtkn',
      theme: 'usrthm',
      language: 'usrlngg',
      wallet: 'wllttkn',
    },
    domain: {
      administration: '.socialme.com.br',
      authentication: '.socialme.com.br',
      accountManagement: '.socialme.com.br',
      beneficiary: '.socialme.com.br',
      citizen: '',
      digitalWallet: '.socialme.com.br',
      donationsPortal: '.doacoes.me',
      donor: '.socialme.com.br',
      ecoop: '',
      manager: '.socialme.com.br',
      marketplace: '.feiralivre.me',
      medicalRecord: '',
      operationsFinder: '.achei.me',
      partner: '.socialme.com.br',
      paymentGateway: '.socialme.com.br',
      telephoneOperator: '',
    },
  },
};
