import React, { memo, useContext } from 'react';

import { ThemeContext } from '~/contexts/ThemeContext';

import Button from '~/components/button';
import Popover from '~/components/popover';

import Notifications from './components/Notification';

import { NotificationIcon, Div } from './style';

function NotificationButton() {
  const { theme } = useContext(ThemeContext);

  const content = <Notifications theme={theme} />;

  return (
    <Popover placement="bottom" content={content} trigger="click">
      <Div>
        <Button shape="circle" icon={<NotificationIcon />} />
      </Div>
    </Popover>
  );
}

export default memo(NotificationButton);
