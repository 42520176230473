export default {
    SM6463D81: {
        message: 'The person profile was successfully Updated',
        //Profile.Edit
    },
    SMF4DF986: {
        message: 'The contact was successfully Updated',
        //Profile.Contact.Edit
    },
    SME396849: {
        message: 'The relationship was successfully Updated',
        //Profile.Relantionship.Delete
    },
    SM3034963: {
        message: 'The role was successfully Updated',
    },
    SMD571DD1: {
        message: 'The place was successfully Updated',
    },
    SM4513863: {
        message: "The 'device' was successfully Updated",
    },
    SMDBCDB5B: {
        message: "The 'activity' was successfully Updated",
    },
    SMD8B9437: {
        message: "The 'Custom questions' was successfully Updated",
    }
};
