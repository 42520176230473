export default {
  Context: {
    Auth: false,
    Browser: false,
    Interface: false,
    Language: false,
    Responsive: false,
    Sidebar: false,
    Theme: false,
    Axios: false,
    Wallet: false,
  },
  Modules: {
    Authentication: false
  },
  Utils: {
    SocialMeAPI: false,
    SocialMeAssets: false
  },
  Wallet: {
    Auth: false,
    Home: false,
    QRCodes: false,
    CheckingAccount: false,
  },
  Provider: {
    Home: false,
  },
};

// console.log('😀🍪❌✔❗❓⛔🚫🧭🛸⚠🔗🔧🔑🔒🔓📍📌📊📋📉📈💼📦💳📒📙📘📗📕💡🔍💻🔋📱🔔💎🏆🔥💧⚡🚀⌛🛑🚧🌎')
