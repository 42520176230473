import React, { memo } from 'react';

import Title from '~/components/typography/title';
import Card from '~/components/card';

import Messages from './components/Messages';
import GoToMessager from './components/GoToMessager';

import { Header } from './style';

function MessagerShortcut({ theme, isMobile }) {
  const cardStyle = {
    margin: isMobile ? '10px 0' : '0 10px',
    height: '70vh',
    display: 'flex',
    flexDirection: 'column',

    justifyContent: 'space-between',
  };

  return (
    <Card style={cardStyle}>
      <Header>
        <Title level={4}>Mensagens</Title>
      </Header>

      <Messages theme={theme} />
      <GoToMessager />
    </Card>
  );
}

export default memo(MessagerShortcut);
