import React from 'react';
// Components
import FormItem from '~/components/form/FormItem';
import Select from '~/components/select';
import Input from '~/components/input';

export default function AddressForm({ formValues, form }) {
  return (
    <div>
      <QuestionKind />

      {formValues.kind ?
        formValues.kind === 'PHONE' ?
          <>
            <QuestionValuePhone />
            <QuestionDescriptionPhone />
            <QuestionComplementPhone />
          </>
          :
          <>
            <QuestionValueEmail />
            <QuestionDescriptionEmail />
            <QuestionComplementEmail />
          </>
        :
        <></>
      }
    </div>
  );
}

const QuestionKind = () => {
  const options = [
    { label: 'Telefone', value: 'PHONE' },
    { label: 'Email', value: 'EMAIL' },
  ];
  return (
    <FormItem
      label="1) Qual o tipo de contato?"
      name={['kind']}
      item={
        <Select placeholder="Selecione" options={options} />
      }
    />
  )
}

const QuestionValuePhone = () => {
  return (
    <FormItem
      label={"2) Qual o número do telefone?"}
      name={['value']}
      item={
        <Input placeholder="+00 00 00000-0000" mask="+11 11 11111-1111" />
      }
    />
  )
}
const QuestionDescriptionPhone = () => {
  return (
    <FormItem
      label={"3) Uma descrição para o seu telefone"}
      name={['description']}
      rules={[
        {
          required: true,
          message: 'Uma descrição é obrigatória',
        },
      ]}
      item={
        <Input placeholder="Ex.: Meu telefone principal"  />
      }
    />
  )
}
const QuestionComplementPhone = () => {
  return (
    <FormItem
      label="4) Complemento"
      name={['complement']}
      item={
        <Input placeholder="Operadora, se utiliza algum mensageiro no número e etc" />
      }
    />
  )
}

const QuestionValueEmail = () => {
  return (
    <FormItem
      label={"2) Qual o endereço de email?"}
      name={['value']}
      rules={[
        {
          type: 'email',
          message: 'Esse não é um email válido',
        }
      ]}
      item={
        <Input placeholder="Ex.: meunome@email.com" />
      }
    />
  )
}
const QuestionDescriptionEmail = () => {
  return (
    <FormItem
      label={"3) Uma descrição para o seu email"}
      name={['description']}
      rules={[
        {
          required: true,
          message: 'Uma descrição é obrigatória',
        },
      ]}
      item={
        <Input placeholder="Ex.: Meu email principal"  />
      }
    />
  )
}
const QuestionComplementEmail = () => {
  return (
    <FormItem
      label="4) Complemento"
      name={['complement']}
      item={
        <Input placeholder="Ex.: o email pertence a outra pessoa, mas tenho acesso" />
      }
    />
  )
}