import React from 'react';
//Components
import Text from '~/components/typography/text';
//Style
import {
  Container,
  FirstRow,
  SecondRow,
  ImageTitleContainer,
  ActionContainer,
} from './style';
//Utils
import { GetDocumentIcon } from '~/utils/SocialMeAssets';
//Own Components
import EditButton from '../../components/EditButton';

export default function ViewVoterCertificateMobile({
  name,
  person,
  GetProfileAgain,
}) {
  return (
    <Container>
      <FirstRow>
        <ImageTitleContainer>
          <img src={GetDocumentIcon(name)} alt="..." style={{ height: 45 }} />
          <Text strong>Título de Eleitor</Text>
        </ImageTitleContainer>
      </FirstRow>
      <SecondRow>
        <Text>Número: {person.voter_certificate_number}</Text>
        {person.voter_certificate_zone ? (
          <Text>Zona: {person.voter_certificate_zone}</Text>
        ) : (
          <></>
        )}
        {person.voter_certificate_section ? (
          <Text>Seção: {person.voter_certificate_section}</Text>
        ) : (
          <></>
        )}
      </SecondRow>
      <ActionContainer>
        <EditButton
          title="Editando Título de Eleitor"
          person={person}
          GetProfileAgain={GetProfileAgain}
        />
      </ActionContainer>
    </Container>
  );
}
