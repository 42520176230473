import React, { useState, useEffect, useContext } from 'react';
// Contexts
import { LanguageContext } from '~/contexts/LanguageContext';
// Components
import Header from './components/Header';
import Content from './components/Content';
import Card from '~/components/card';
import Loading from '~/components/loading';
import Divider from '~/components/divider';
// Standalone components
import { Container } from './style';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';

export default function Contact() {
  const { translate } = useContext(LanguageContext);
  const [loadingContent, setLoadingContent] = useState(true);
  const [contacts, setContacts] = useState([]);

  useEffect(() => {
    async function Getcontacts() {
      const params = {
        operation: ['Profile', 'Contact', 'Get'],
      };
      const response = await SocialMeAPI(params);
      if (response.success) {
        setContacts(response.body.contacts.elements);
        return setLoadingContent(false);
      }
      return setLoadingContent(false);
    }
    Getcontacts();
  }, []);

  async function GetContactsAgain() {
    const params = {
      operation: ['Profile', 'Contact', 'Get'],
    };
    const response = await SocialMeAPI(params);
    if (response.success) {
      setContacts(response.body.contacts.elements);
    }
  }

  return (
    <Container>
      <Card>
        {loadingContent ? (
          <Loading />
        ) : (
          <>
            <Header
              title={translate.accountManagement.contactsTitle.text}
              GetContactsAgain={GetContactsAgain}
            />
            <Divider />
            <Content GetContactsAgain={GetContactsAgain} contacts={contacts} />
          </>
        )}
      </Card>
    </Container>
  );
}
