import React, { memo } from 'react';
// Standalone Components
import { MainProgress } from './style';
// Components
import Tooltip from '~/components/tooltip';

function AntProgress({
  format,
  percent,
  showInfo,
  status,
  strokeColor,
  strokeLinecap,
  success,
  trailColor,
  type,
  steps,
  strokeWidth,
  width,
  gapDegree,
  gapPosition,
  tooltipText,
}) {
  return (
    <Tooltip title={tooltipText}>
      <MainProgress
        format={format}
        percent={percent}
        showInfo={showInfo}
        status={status}
        strokeColor={strokeColor}
        strokeLinecap={strokeLinecap}
        success={success}
        trailColor={trailColor}
        type={type}
        steps={steps}
        strokeWidth={strokeWidth}
        width={width}
        gapDegree={gapDegree}
        gapPosition={gapPosition}
      />
    </Tooltip>
  );
}

export default memo(AntProgress);
