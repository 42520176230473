import React from 'react';

import { Button } from 'antd';
// Components
import Actions from './components/Actions';
//
import Table from '~/components/table';

export default function ContactsTable({ place, addresses, GetAddressesAgain }) {
  const tableColumns = [
    {
      title: 'Descrição',
      dataIndex: 'description',
      key: 'description',
      render: (description, record) =>
        record.is_street ? (
          <Button type="link">MORADOR DE RUA</Button>
        ) : (
          <Button type="link">{description}</Button>
        ),
    },
    {
      title: 'Endereço',
      dataIndex: 'address',
      key: 'address',
      render: (address, record) => (
        <div>
          <span>
            {address ? `${record.designation}: ${address}` : ''},{' '}
            {record.number ? record.number : ''} ,
            {record.neighborhood ? record.neighborhood : ''}
          </span>
        </div>
      ),
    },
    {
      title: 'Local',
      dataIndex: 'city_name',
      key: 'city_name',
      render: (city_name, record) => (
        <span>
          {`${city_name || 'Cidade não informada'} - ` || ''}
          {`${record.state_name || 'Estado não informada'} - ` || ''}
          {`${record.country_name || 'País não informada'}` || ''}
        </span>
      ),
    },
    {
      title: 'Ações',
      key: 'action',
      render: (data) => (
        <Actions
          GetAddressesAgain={GetAddressesAgain}
          address={data}
          place={place}
        />
      ),
    },
  ];

  return <Table tableColumns={tableColumns} dataReceived={addresses} />;
}
