import React, { useState, useContext } from 'react';
// Libraries
import { Drawer, message, Form } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
// Contexts
import { AxiosContext } from '~/contexts/AxiosContext';
import { ResponsiveContext } from '~/contexts/ResponsiveContext';
//Own Components
import AddForm from './components/AddForm';
//Components
import Button from '~/components/button';

export default function StoreButton({ GetDataAgain }) {
  const { CallToTheAPI } = useContext(AxiosContext);
  const { isMobile } = useContext(ResponsiveContext);

  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState('');
  const [loadingButton, setLoadingButton] = useState(false);

  const ShowDrawer = () => {
    setVisibleDrawer(true);
  };
  const CloseDrawer = () => {
    setVisibleDrawer(false);
  };
  async function Add(data) {
    const params = {
      operation: ['Profile', 'Location', 'Add'],
      data,
    };
    const response = await CallToTheAPI(params);
    console.log('response -> ', response);
    if (response.success) {
      GetDataAgain();
      message.success('Local Cadastrado!');
      setLoadingButton(false);
      return setVisibleDrawer(false);
    }
    message.error('Alguma coisa aconteceu...');
    setLoadingButton(false);
  }
  const onFinish = (data) => {
    Add(data);
  };

  return (
    <>
      <Button
        text="Novo local"
        onClick={() => ShowDrawer()}
        type="primary"
        icon={<PlusOutlined />}
      />

      <Drawer
        title="Novo local"
        width={isMobile ? '100%' : 600}
        onClose={CloseDrawer}
        visible={visibleDrawer}
        bodyStyle={{ padding: 20 }}
        footer={
          <div
            style={{
              textAlign: 'right',
            }}
          >
            <Button
              text="Cancelar"
              onClick={CloseDrawer}
              style={{ marginRight: 8 }}
            />

            <Button
              text="Adicionar"
              loading={loadingButton}
              disabled={loadingButton}
              type="primary"
              onClick={() => {
                form
                  .validateFields()
                  .then((values) => {
                    form.resetFields();
                    onFinish(values);
                  })
                  .catch((info) => {
                    // console.log('Validate Failed:', info);
                  });
              }}
            />
          </div>
        }
      >
        <Form
          labelCol={{
            xs: { span: 24 },
            sm: { span: 24 },
          }}
          wrapperCol={{
            xs: { span: 24 },
            sm: { span: 24 },
          }}
          form={form}
          layout="vertical"
          size="small"
          onValuesChange={() => setFormValues(form.getFieldsValue())}
          scrollToFirstError
        >
          <AddForm formValues={formValues} form={form} />
        </Form>
      </Drawer>
    </>
  );
}
