import React from 'react';
// Components
import UserDisconnectedDescription from '~/components/pageStructure/UserDisconnectedDescription';

export default function Timeline() {
  return (
    <UserDisconnectedDescription
      description="Nesta página você pode visualizar o seu histórico de serviços na SocialMe, como as atividades que participou, os benefícios resgatados e os programas que entrou."
    />
  );
}
