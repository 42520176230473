import React, { useState, useContext } from 'react';
// Contexts
import { Drawer, message, Form } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { ResponsiveContext } from '~/contexts/ResponsiveContext';
// Libraries
// Components
import Button from '~/components/button';
import Loading from '~/components/loading';
// Steps
import SearchPerson from './steps/SearchPerson';
import Select from './steps/Select';
import Fill from './steps/Fill';
import PreRegistration from './steps/PreRegistration';

export default function ContactStoreButton({ GetRelantionshipsAgain }) {
  const { isMobile } = useContext(ResponsiveContext);

  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [load] = useState(false);
  const [step, setStep] = useState('search');
  const [searchForm] = Form.useForm();
  const [linkForm] = Form.useForm();
  const [preRegisterForm] = Form.useForm();
  const [peoples] = useState([
    {
      name: 'Prefeitura de Anavilhanas',
      avatar: 'http://www.consciencia.org/wp-content/uploads/2008/02/La-Ruta-Hacia-El-Dorado.jpg',
      link: true
    },
    {
      name: 'Prefeitura de Pancas',
      avatar: 'https://lh3.googleusercontent.com/-mpl8t-wH8Bs/VoA3ZEh59lI/AAAAAAAASrU/dp1H7H1N7Vg/DSCF0880_thumb.jpg?imgmax=800',
      link: false
    },
    {
      name: 'Prefeitura de Barcelos',
      avatar: 'https://static.wikia.nocookie.net/batman/images/a/a8/Gotham_City.jpg',
      link: true
    },
    {
      name: 'Prefeitura de Nova Petrópolis',
      avatar: 'https://www.viajali.com.br/wp-content/uploads/2020/10/nova-petropolis-1.jpg',
      link: true
    }
  ]);
  const [people, setPeople] = useState();

  const ShowDrawer = () => {
    setVisibleDrawer(true);
  };
  const CloseDrawer = () => {
    setVisibleDrawer(false);
  };

  async function onFinishFill() {
    message.success('Solicitação enviada!');
    setPeople()
    setStep('search')
  }

  const RenderButton = () => {
    switch (step) {
      case 'search':
        return (
          <Button
            type="primary"
            onClick={() => setStep('select')}
          >
            Buscar
          </Button>
        );
      case 'select':
        return (
          <Button onClick={() => setStep('search')}>Pesquisar novamente</Button>
        );
      case 'fill':
        return (
          <>
            <Button
              style={{ marginRight: 8 }}
              onClick={() => setStep('select')}
            >
              Voltar
            </Button>
            <Button
              type="primary"
              onClick={() => onFinishFill()}
            >
              Solicitar
            </Button>
          </>
        );
      default:
        return null;
    }
  };
  const RenderStep = () => {
    switch (step) {
      case 'search':
        return <SearchPerson form={searchForm} />;
      case 'select':
        return (
          <Select setStep={setStep} peoples={peoples} setPeople={setPeople} />
        );
      case 'fill':
        return <Fill people={people} form={linkForm} />;
      case 'preRegister':
        return <PreRegistration form={preRegisterForm} />;
      default:
        return null;
    }
  };
  return (
    <>
      <Button
        text="Novo Vinculo"
        onClick={() => ShowDrawer()}
        type="primary"
        icon={<PlusOutlined />}
      />

      <Drawer
        title="Novo Vinculo"
        width={isMobile ? '100%' : 600}
        onClose={CloseDrawer}
        visible={visibleDrawer}
        bodyStyle={{ padding: 20 }}
        footer={
          <div
            style={{
              textAlign: 'right',
            }}
          >
            <Button
              text="Cancelar"
              onClick={CloseDrawer}
              style={{ marginRight: 8 }}
            />

            {RenderButton()}
          </div>
        }
      >
        {load ? <Loading /> : RenderStep()}
      </Drawer>
    </>
  );
}
