import React, { useContext, useState, useEffect } from 'react';
// Libraries
import { CheckCircleOutlined } from '@ant-design/icons';
//Contexts
import { ThemeContext } from '~/contexts/ThemeContext';
import { AuthContext } from '~/contexts/AuthContext';
// Components
import Card from '~/components/card';
import Button from '~/components/button';
import Title from '~/components/typography/title';
import Text from '~/components/typography/text';
import Loading from '~/components/loading';
import Space from '~/components/space';
import Modal from '~/components/modal';
// Environments
import System from '~/environments/System';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';
import { GetBrandIcon } from '~/utils/SocialMeAssets';

export default function ProfileCard({ imageName, name, operationName, terms }) {
  const { user, GetUserAgain } = useContext(AuthContext);
  const { theme } = useContext(ThemeContext);

  const [haveTheProfile, setHaveTheProfile] = useState(false);
  const [loadingResponse, setLoadingResponse] = useState(true);

  useEffect(() => {
    if (user) {
      if (user.role[operationName.toUpperCase()]) {
        setHaveTheProfile(true);
      } else {
        setHaveTheProfile(false);
      }
      return setLoadingResponse(false);
    }
  }, [user, operationName]);

  async function AddRole() {
    const params = {
      operation: ['Profile', 'Roles', 'Add', operationName],
    };
    const response = await SocialMeAPI(params);
    console.log('response -> ', response);
    if (response.success) {
      GetUserAgain();
    }
  }

  if (loadingResponse) {
    return (
      <div>
        <Card>
          <Loading text="Verificando" />
        </Card>
      </div>
    );
  }

  return (
    <div style={{ padding: 5 }}>
      <Card>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              <img
                src={GetBrandIcon(imageName)}
                alt="..."
                style={{ width: 64 }}
              />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <Title level={4}>{name}</Title>
            </div>
          </div>
          <div style={{ marginTop: 20 }}>
            {haveTheProfile ? (
              <Space direction={'vertical'}>
                <Text>
                  <CheckCircleOutlined
                    style={{ color: theme.color.success, marginRight: 10 }}
                  />
                Você já é um {name}
                </Text>
                <a href={`https://${System.hostname[imageName]}`}>
                  <Button type="primary">Acessar painel do {name}</Button>
                </a>
              </Space>
            ) : (
                <Modal
                  Trigger={
                    <Button
                      type="primary"
                    >
                      Quero me tornar um {name}
                    </Button>
                  }
                  title={`Contrato: ${name}`}
                  content={terms}
                  cancelText={'Cancelar'}
                  okText={'Li e concordo'}
                  onOk={() => AddRole()}
                  width={'80vw'}
                  style={{ top: 10 }}
                />
              )}
          </div>
        </div>
      </Card>
    </div>
  );
}

/*

 <Button
                  type="primary"
                  onClick={() => AddRole()}
                  loading={loadingButton}
                >
                  Quero me tornar um {name}
                </Button>
*/