import React, { memo } from 'react';

import { Link } from '~/components/link';
import Button from '~/components/button';

import { Container } from './style';

function GoToMessager() {
  return (
    <Container>
      <Link to="/notificacoes">
        <Button>Ver mais notificações</Button>
      </Link>
    </Container>
  );
}

export default memo(GoToMessager);
