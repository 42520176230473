import React from 'react';
// Own Components
import Header from './components/Header';
import Content from './components/Content';
// Components
import Card from '~/components/card';

export default function Relationships() {

  return (
    <div>
      <Card>
        <Header
          title="Vinculações"
        />
      <Content />
      </Card>
    </div>
  );
}
