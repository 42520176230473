import React from 'react'
// Com
import Button from '~/components/button'
import Drawer from '~/components/drawer'

import Type1 from './Type1';

export default function ConfigButton({ drawerType, image }) {
    return (
        <Drawer
            Trigger={
                <Button>Configurações</Button>
            }
            DrawerContentComponent={Type1}
            DrawerContentComponentParams={{ drawerType, image }}
            title="Configurações de proteção de dados"
        />
    )
}