import React, { useState, useContext } from 'react';
// Libraries
import { Drawer, message, Form } from 'antd';
// Components
import Button from '~/components/button';
import Text from '~/components/typography/text';
// Contexts
import { AxiosContext } from '~/contexts/AxiosContext';
import { ResponsiveContext } from '~/contexts/ResponsiveContext';
import { ThemeContext } from '~/contexts/ThemeContext';
//Utils
import { AmericanDateParser } from '~/utils/Parsers';
import { GetDocumentIcon } from '~/utils/SocialMeAssets';
//Questions
import QuestionsCPF from './components/QuestionsCPF';
import QuestionsBirthCertificate from './components/QuestionsBirthCertificate';
import QuestionsCNH from './components/QuestionsCNH';
import QuestionsCNS from './components/QuestionsCNS';
import QuestionsCTPS from './components/QuestionsCTPS';
import QuestionsPassport from './components/QuestionsPassport';
import QuestionsRG from './components/QuestionsRG';
import QuestionsVoterCertificate from './components/QuestionsVoterCertificate';

export default function AddDocument({ documentToAdd, GetProfileAgain }) {
  const { CallToTheAPI } = useContext(AxiosContext);
  const { isMobile } = useContext(ResponsiveContext);
  const { theme } = useContext(ThemeContext);

  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [load, setLoad] = useState(false);
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState('');

  const ShowDrawer = () => {
    setVisibleDrawer(true);
  };
  const CloseDrawer = () => {
    setVisibleDrawer(false);
  };

  function GetDocumentName(name) {
    switch (name) {
      case 'BirthCertificate':
        return 'Certidão de Nascimento';
      case 'CTPS':
        return 'Carteira de trabalho';
      case 'Passport':
        return 'Passaporte';
      case 'VoterCertificate':
        return 'Título de Eleitor';
      default:
        return name
    }
  }

  function RenderDocumentQuestions(name) {
    switch (name) {
      case 'CPF':
        return <QuestionsCPF />
      case 'BirthCertificate':
        return <QuestionsBirthCertificate formValues={formValues} form={form} />
      case 'CNH':
        return <QuestionsCNH />
      case 'CNS':
        return <QuestionsCNS />
      case 'CTPS':
        return <QuestionsCTPS />
      case 'Passport':
        return <QuestionsPassport />
      case 'RG':
        return <QuestionsRG />
      case 'VoterCertificate':
        return <QuestionsVoterCertificate />
      default:
        return 'null'
    }
  }

  async function AddDocument(data) {
    setLoad(true);
    const params = {
      operation: ['Profile', 'Edit'],
      data,
    };
    const response = await CallToTheAPI(params);
    if (response.success) {
      message.success(`${documentToAdd} Cadastrado!`);
      setLoad(false);
      setVisibleDrawer(false) 
      return GetProfileAgain();
    }
    message.error('Alguma coisa aconteceu...');
    setLoad(false);
  }

  const onFinish = (data) => {
    //console.log('Received data of form: ', data);
    if (data.cpf_number) data.cpf_number = data.cpf_number.replace(/[^\d]+/g, '');
    if (data.cns_number) data.cns_number = data.cns_number.replace(/[^\d]+/g, '');
    if (data.rg_number) data.rg_number = data.rg_number.replace(/[^\d]+/g, '');
    if (data.voter_certificate_number) data.voter_certificate_number = data.voter_certificate_number.replace(/[^\d]+/g, '');
    if (data.ctps_pis_pasep) data.ctps_pis_pasep = data.ctps_pis_pasep.replace(/[^\d]+/g, '');
    if (data.birth_certificate_date) data.birth_certificate_date = AmericanDateParser(data.birth_certificate_date);
    if (data.cnh_valid_through) data.cnh_valid_through = AmericanDateParser(data.cnh_valid_through);
    if (data.rg_dispatched_at) data.rg_dispatched_at = AmericanDateParser(data.rg_dispatched_at);
    //console.log('Tratamento: ', data);
    AddDocument(data);
  };

  return (
    <>
      <div
        onClick={() => ShowDrawer()}
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          backgroundColor: theme.color.backgroundPrimary,
          borderRadius: 10,
          cursor: 'pointer',
          border: `1px solid ${theme.color.primary}`
        }}>
        <div style={{
          height: 40,
          padding: 5,
          backgroundColor: theme.color.backgroundSecondary,
          display: 'flex',
          alignItems: 'center',
          borderTopLeftRadius: 10,
          borderBottomLeftRadius: 10
        }}>
          <img src={GetDocumentIcon(documentToAdd)} alt="..." style={{ height: 32 }} />
        </div>
        <div style={{
          height: 40,
          width: 'max-content',
          padding: 5,
          backgroundColor: theme.color.backgroundPrimary,
          display: 'flex',
          alignItems: 'center',
          borderTopRightRadius: 10,
          borderBottomRightRadius: 10
        }}>
          <Text color={theme.color.textOnPrimaryBackground}>{`Cadastrar ${GetDocumentName(documentToAdd)}`}</Text>
        </div>
      </div>

      <Drawer
        title={`Cadastrar ${GetDocumentName(documentToAdd)}`}
        width={isMobile ? '100%' : 600}
        onClose={CloseDrawer}
        visible={visibleDrawer}
        bodyStyle={{ padding: 20 }}
        footer={
          <div
            style={{
              textAlign: 'right',
            }}
          >
            <Button
              onClick={CloseDrawer}
              style={{ marginRight: 8 }}
            >
              Cancelar
            </Button>

            <Button
              loading={load}
              disabled={load}
              type="primary"
              onClick={() => {
                form
                  .validateFields()
                  .then((values) => {
                    //form.resetFields();
                    onFinish(values);
                  })
                  .catch((info) => {
                    // console.log('Validate Failed:', info);
                  });
              }}
            >
              Adicionar
            </Button>
          </div>
        }
      >
        <Form
          labelCol={{
            xs: { span: 24 },
            sm: { span: 24 },
          }}
          wrapperCol={{
            xs: { span: 24 },
            sm: { span: 24 },
          }}
          form={form}
          layout="vertical"
          size="small"
          onValuesChange={() => setFormValues(form.getFieldsValue())}
          scrollToFirstError
        >
          {RenderDocumentQuestions(documentToAdd)}
        </Form>
      </Drawer>
    </>
  );
}
