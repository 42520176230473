import React, { useContext } from 'react';
// Libraries
import { Divider, Row, Col } from 'antd';
// Contexts
import { LanguageContext } from '~/contexts/LanguageContext';
// Components
import FormItem from '~/components/form/FormItem';
import Input from '~/components/input';
//import Select from '~/components/select';

/*
const Enquadramentos = [
  {
    value: 'Enquadramento 1',
    Label: 'Enquadramento 1'
  },
  {
    value: 'Enquadramento 2',
    Label: 'Enquadramento 2'
  }
]
*/

export default function BasicInformation() {
  const { translate } = useContext(LanguageContext);
  return (
    <Row>
      <Divider style={{ margin: '0px 0px 10px' }}>
        {translate.accountManagement.basic.text}
      </Divider>
      <Col xs={24} sm={24} md={16} lg={16} xl={16}>
        <FormItem
          label={'Razão Social'}
          name={['name']}
          rules={[
            {
              required: true,
              message: translate.fields.nameIsRequired.text,
            },
          ]}
          item={<Input placeholder={translate.fields.enterYourFullName.text} />}
          style={{ marginRight: 10 }}
        />
      </Col>
      <Col xs={24} sm={24} md={8} lg={8} xl={8}>
        <FormItem
          label={translate.fields.mainContactLabel.text}
          name={['main_contact']}
          helpText={translate.fields.mainContactHelp.text}
          item={
            <Input placeholder={translate.fields.enterAnEmailOrPhone.text} />
          }
          style={{ marginRight: 10 }}
        />
      </Col>
      <Col xs={24} sm={24} md={16} lg={16} xl={16}>
        <FormItem
          label={'Nome Fantasia'}
          name={['social_name']}
          item={<Input placeholder={'Insira seu nome fantasia'} />}
          style={{ marginRight: 10 }}
        />
      </Col>
      {/* 
      <Col xs={24} sm={24} md={8} lg={8} xl={8}>
        <FormItem
          label={'Enquadramento'}
          name={['schooling']}
          item={
            <Select
              placeholder={translate.fields.select.text}
              options={Enquadramentos}
            />
          }
          style={{ marginRight: 10 }}
        />
      </Col>
      */}
    </Row>
  );
}
