import React, { useContext } from 'react';
// Libraries
import Animate, { FadeIn } from 'animate-css-styled-components';
// Contexts
import { LanguageContext } from '~/contexts/LanguageContext';
// Standalone Components
import { Container, Content, Image } from './style';
// Components
import Tooltip from '~/components/tooltip';
// Utils
import { GetBrandIcon } from '~/utils/SocialMeAssets';
import { GetModuleNameByHostname } from '~/utils/Hosts';

export default function SidebarHeader({ collapsed }) {
  const { translate } = useContext(LanguageContext);
  function GetModuleBrand() {
    const host = GetModuleNameByHostname();
    if (host === 'localhost') {
      return GetBrandIcon('administration');
    }
    return GetBrandIcon(host);
  }

  return (
    <Container>
      <Animate Animation={[FadeIn]} duration={['0.8s']} delay={['0.2s']}>
        <Content>
          {collapsed ? (
            <div style={{ display: 'flex' }}>
              <Tooltip
                title={translate.brands[GetModuleNameByHostname()].text}
                placement="right"
              >
                <div
                  style={{
                    backgroundColor: 'white',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: 5,
                    borderRadius: 8,
                    opacity: 1,
                  }}
                >
                  <Image src={GetModuleBrand()} alt="..." height="24px" />
                </div>
              </Tooltip>
              <Image
                src={GetBrandIcon('socialMeVariation6')}
                alt="..."
                height="0px"
              />
            </div>
          ) : (
            <div style={{ display: 'flex' }}>
              <div
                style={{
                  backgroundColor: 'white',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: 0,
                  borderRadius: 0,
                  opacity: 0,
                }}
              >
                <Image src={GetModuleBrand()} alt="..." height="0px" />
              </div>
              <Image
                src={GetBrandIcon('socialMeVariation6')}
                alt="..."
                height="64px"
              />
            </div>
          )}
        </Content>
      </Animate>
    </Container>
  );
}
