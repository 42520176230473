import React, { createContext, useEffect, useState } from 'react';
//Libraries
import { useLocation } from 'react-router-dom';
//Environments
import System from '~/environments/System';
import Debug from '~/environments/Debug';
//Utils
import Cookie from '~/utils/Cookie';
import SocialMeAPI from '~/utils/SocialMeAPI';

const isDebug = Debug.Context.Auth;

export const AuthContext = createContext();

const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState();
  const [endedUserLogic, setEndedUserLogic] = useState(false);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();

  //CheckUserToken
  useEffect(() => {
    async function CheckUserToken() {
      const params = {
        operation: ['Auth', 'UserToken', 'Check'],
      };
      if (isDebug) console.log('🚀 API: Check user token');
      const response = await SocialMeAPI(params);
      if (isDebug) console.log('  ⚡ API Response: ', response);
      if (response.success) {
        if (isDebug) console.log('    ✔ Valid user token');
        let user = response.body.usr;
        if (response.body.permission) {
          user.permission = response.body.permission;
        }
        if (response.body.role) {
          user.role = response.body.role;
        }
        setUser(user);
      } else {
        if (isDebug) console.log('    ❌ Invalid user token');
        const deletingTheUserCookie = await Cookie.Delete(
          System.cookie.name.user
        );
        if (deletingTheUserCookie) {
          if (isDebug) console.log('  ✔ User cookie successfully deleted');
        }
      }
      return setEndedUserLogic(true);
    }
    async function VerifyUserToken() {
      if (isDebug) console.log('🔍 Searching for user token');
      const UserTokenCookies = await Cookie.Get(System.cookie.name.user);
      if (UserTokenCookies) {
        if (isDebug) console.log('  ✔ User token found');
        CheckUserToken();
      } else {
        if (isDebug) console.log('  ❌ User token not found');
        setEndedUserLogic(true);
      }
    }
    VerifyUserToken();
  }, []);

  async function GetUserAgain() {
    const params = {
      operation: ['Auth', 'UserToken', 'Check'],
    };
    if (isDebug) console.log('🚀 API: Check user token');
    const response = await SocialMeAPI(params);
    if (isDebug) console.log('  ⚡ API Response: ', response);
    if (response.success) {
      let user = response.body.usr;
      if (response.body.permission) {
        user.permission = response.body.permission;
      }
      if (response.body.role) {
        user.role = response.body.role;
      }
      setUser(user);
    }
  }

  async function Redirect() {
    const serviceName = query.get('service');
    if (serviceName) {
      return window.location.replace(`https://${System.hostname[serviceName]}`);
    }
  }

  async function PopulateUser(person, token) {
    if (isDebug) console.log('Populate User');

    if (isDebug) console.log('  🍪 Persist cookie in browser');
    const creatingTheCookie = await Cookie.Create({
      name: System.cookie.name.user,
      value: token,
    });
    if (creatingTheCookie) {
      if (isDebug) console.log('  ✔ User cookie successfully created');
    } else {
      if (isDebug) console.log('  ❗ Error creating User Cookie');
    }

    const serviceName = query.get('service');
    if (!serviceName) {
      if (isDebug) console.log('  ❌ Service does not exist');
      setUser(person);
    } else {
      if (isDebug) console.log('  ✔ Service exist');
    }

    Redirect();
  }

  async function Logout(url) {
    async function Refresh() {
      if(url !== 'noURL'){
        return window.location.replace(url);
      }
    }
    async function DeleteCookies() {
      const deletingTheBrowserCookie = await Cookie.Delete(
        System.cookie.name.browser
      );
      if (deletingTheBrowserCookie) {
        if (isDebug) console.log('  ✔ Browser cookie successfully deleted');
      }
      const deletingTheUserCookie = await Cookie.Delete(
        System.cookie.name.user
      );
      if (deletingTheUserCookie) {
        if (isDebug) console.log('  ✔ User cookie successfully deleted');
      }
      const deletingTheThemeCookie = await Cookie.Delete(
        System.cookie.name.theme
      );
      if (deletingTheThemeCookie) {
        if (isDebug) console.log('  ✔ Theme cookie successfully deleted');
      }
      const deletingTheLanguageCookie = await Cookie.Delete(
        System.cookie.name.language
      );
      if (deletingTheLanguageCookie) {
        if (isDebug) console.log('  ✔ Language cookie successfully deleted');
      }
      Refresh();
    }
    const params = {
      operation: ['Auth', 'Logout'],
    };
    if (isDebug) console.log('🚀 API: Logout');
    const response = await SocialMeAPI(params);
    if (isDebug) console.log('  ⚡ API Response: ', response);
    await DeleteCookies();
  }

  return (
    <AuthContext.Provider
      value={{ GetUserAgain, PopulateUser, Logout, user, endedUserLogic }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
