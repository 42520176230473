import React from 'react';
import ReactToPrint from 'react-to-print';
import Text from '~/components/typography/text';
import Button from '~/components/button';
import QRCode from 'qrcode.react';

/* 
Paper Size
width: 792,
height: 1120,
*/

class ComponentToPrint extends React.Component {
  render() {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', padding: '2px 2px 2px 2px' }}>
        {/* FRONT */}
        <div
          style={{
            width: 321,
            height: 226,
            backgroundImage:
              "url('https://socialme-assets.s3.amazonaws.com/images/qrCode/SocialMeIdentity_Purple_01.jpg')",
            backgroundPosition: 'center top',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundColor: 'white',
            border: '2px solid #303030'
          }}
        >
          <div style={{
            width: 317,
            height: 222,
            display: 'flex'
            //backgroundColor: 'purple'
          }}>
            {/* LEFT */}
            <div style={{
              //backgroundColor: 'black',
              width: 100,
              height: 222,
              padding: '24px 0px 0px 18px'
            }}>
              {/* AVATAR */}
              <div style={{
                backgroundColor: 'blue',
                width: '80px',
                height: '98px',
                borderRadius: '8px',
                borderTopRightRadius: '0px',
                backgroundImage: `url(${this.props.avatar})`,
                backgroundPosition: 'center center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
              }}>
              </div>
            </div>
            {/* RIGHT */}
            <div
              style={{
                //backgroundColor: 'orange',
                width: 217,
                height: 222,
                padding: '65px 0px 0px 9px'
              }}
            >
              {/* INPUT'S CONTAINER */}
              <div
                style={{
                  //backgroundColor: 'orange',
                  width: 197,
                  height: 133
                }}
              >
                {/* NAME INPUT */}
                <div
                  style={{
                    //backgroundColor: 'orange',
                    width: 190,
                    height: 21,
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <Text color="#fff" style={{ fontSize: this.props.fontSizeName }}>{this.props.name}</Text>
                </div>
                {/* CPF INPUT */}
                <div
                  style={{
                    //backgroundColor: 'green',
                    width: 190,
                    height: 21,
                    marginTop: 13,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <Text color="#fff">{this.props.rg}</Text>
                </div>
                {/* CPF INPUT / BIRTH INPUT */}
                <div
                  style={{
                    //backgroundColor: 'yellow',
                    width: 192,
                    height: 21,
                    display: 'flex',
                    marginTop: 14
                  }}
                >
                  <div
                    style={{
                      //backgroundColor: 'cyan',
                      width: 103,
                      height: 21,
                    }}
                  >
                    <Text color="#fff">{this.props.cpf}</Text>
                  </div>
                  <div
                    style={{
                      //backgroundColor: 'blue',
                      width: 82,
                      height: 21,
                      marginLeft: 6
                    }}
                  >
                    <Text color="#fff">{this.props.birth}</Text>
                  </div>
                </div>
                {/* CNS INPUT / PIS INPUT */}
                <div
                  style={{
                    //backgroundColor: 'yellow',
                    width: 192,
                    height: 21,
                    display: 'flex',
                    marginTop: 14
                  }}
                >
                  <div
                    style={{
                      //backgroundColor: 'cyan',
                      width: 89,
                      height: 21,
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <Text color="#fff" style={{ fontSize: '7pt' }}>{this.props.cns}</Text>
                  </div>
                  <div
                    style={{
                      //backgroundColor: 'blue',
                      width: 92,
                      height: 21,
                      marginLeft: 9,
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <Text color="#fff" style={{ fontSize: '9.5pt' }}>{this.props.pis}</Text>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* BACK */}
        <div
          style={{
            width: 321,
            height: 226,
            padding: '17px 0px 0px 73px',
            backgroundColor: 'white',
            border: '2px solid #303030'
          }}
        >
          <div
            style={{
              backgroundColor: '#fff',
              width: 170,
              height: 170,
              borderRadius: '8px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              border: '2px solid black'
            }}
          >
            <QRCode
              value={this.props.qrCode}
              size={160}
              bgColor="#ffffff"
              fgColor="#303030"
              level="L"
              includeMargin={false}
              renderAs="svg"
            />
          </div>
          <div style={{ width: 170, marginTop: 10, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Text type="secondary" style={{ fontSize: '8pt' }}>Seu QR Code</Text>
          </div>

        </div>
      </div>
    );
  }
}

class Example extends React.Component {
  render() {
    return (
      <div>
        <ReactToPrint
          trigger={() => (
            <Button type="primary" style={{ marginBottom: 10 }}>
              Imprimir Identidade Me
            </Button>
          )}
          content={() => this.componentRef}
        />
        <ComponentToPrint
          ref={(el) => (this.componentRef = el)}
          avatar={this.props.avatar}
          qrCode={this.props.qrCode}
          name={this.props.name}
          fontSizeName={this.props.fontSizeName}
          rg={this.props.rg}
          cpf={this.props.cpf}
          birth={this.props.birth}
          cns={this.props.cns}
          pis={this.props.pis}
        />
      </div>
    );
  }
}

export default Example;