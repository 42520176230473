import React, { useState, useEffect, useContext } from 'react';
// Contexts
import { LanguageContext } from '~/contexts/LanguageContext';
//Own Components
import AddDocumentContainer from './components/AddDocumentContainer';
import OwnedDocumentsContainer from './components/OwnedDocumentsContainer';
// Standalone Components
import { Container } from './style';
//Components
import Loading from '~/components/loading';

export default function Content({ person, GetProfileAgain }) {
    const { translate } = useContext(LanguageContext);
    const [ownedDocuments, setOwnedDocuments] = useState([])
    const [missingDocuments, setMissingDocuments] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (person) {
            const ownedArray = [];
            const missingArray = [];
            async function HasOrNot(fieldName, documentName) {
                if (person[fieldName]) {
                    ownedArray.push(documentName);
                } else {
                    missingArray.push(documentName);
                }
            }
            async function SeparationOfDocuments() {
                await HasOrNot('cpf_number', 'CPF');
                await HasOrNot('birth_certificate_date', 'BirthCertificate');
                await HasOrNot('rg_number', 'RG');
                await HasOrNot('cns_number', 'CNS');
                await HasOrNot('cnh_number', 'CNH');
                //await HasOrNot('cnpj_number', 'CNPJ');
                await HasOrNot('ctps_number', 'CTPS');
                //await HasOrNot('dni_number', 'DNI');
                await HasOrNot('passport_number', 'Passport');
                await HasOrNot('voter_certificate_number', 'VoterCertificate');
                //await HasOrNot('marriage_certificate_number', 'MarriageCertificate');
                //await HasOrNot('military_certificate_number', 'MilitaryCertificate');
                setOwnedDocuments(ownedArray);
                setMissingDocuments(missingArray);
                setLoading(false);
            }
            SeparationOfDocuments();
        }
    }, [person])

    return (
        <Container>
            {loading ?
                <Loading text={translate.messages.preparingYourDocuments.text} />
                :
                <>
                     <AddDocumentContainer missingDocuments={missingDocuments} GetProfileAgain={GetProfileAgain} />
<OwnedDocumentsContainer ownedDocuments={ownedDocuments} person={person} GetProfileAgain={GetProfileAgain} />
                </>
            }
        </Container>
    )
}
/*

*/