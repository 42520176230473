import React from 'react';
// Components
import Title from '~/components/typography/title';
import Button from '~/components/button';
// Environments
import System from '~/environments/System';
// Utils
import { GetModuleNameByHostname } from '~/utils/Hosts';

export default function UserDisconnectedDescription({ description }) {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: 20,
                minHeight: 'calc(100vh - 64px)'
            }}
        >
            <div
                style={{
                    width: '90%',
                }}
            >
                <div style={{ marginBottom: 20 }}>

                </div>
                <div>
                    <Title level={4} style={{ textAlign: "center" }}>
                        {description}
                    </Title>
                </div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignContent: 'center',
                        margin: '20px auto',
                        flexDirection: 'column',
                        width: 200,
                    }}
                >
                    <a
                        href={`https://${System.hostname.authentication}/?service=${GetModuleNameByHostname()}`}
                        style={{ width: 220, display: 'flex', justifyContent: 'center', justifyItems: 'center' }}
                    >
                        <Button
                            type="primary"
                            size="large"
                        >
                            Entrar no Social Me
                        </Button>
                    </a>
                    <a
                        href={`https://${System.hostname.authentication}/?service=${GetModuleNameByHostname()}`}
                        style={{ width: 220, display: 'flex', justifyContent: 'center', justifyItems: 'center' }}
                    >
                        <Button type="link" style={{ marginTop: 10 }}>
                            Criar uma conta Social Me
                        </Button>
                    </a>
                </div>
            </div>
        </div>
    )
}