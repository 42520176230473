import React, { useState, useContext, useEffect } from 'react';
// Libraries
import { Divider, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
// Components
import Header from './components/Header';
import Content from './components/Content';
import Card from '~/components/card';
// Standalone components
import { LoadContainer } from './style';
// Contexts
import { AxiosContext } from '~/contexts/AxiosContext';
import { ThemeContext } from '~/contexts/ThemeContext';

export default function Contact() {
  const { CallToTheAPI } = useContext(AxiosContext);
  const { theme } = useContext(ThemeContext);

  const [load, setLoad] = useState(true);
  const [contacts, setContacts] = useState([]);

  useEffect(() => {
    async function Getcontacts() {
      const params = {
        operation: ['Profile', 'Contact', 'Get'],
      };
      const response = await CallToTheAPI(params);
      if (response.success) {
        setContacts(response.body.contacts.elements);
        return setLoad(false);
      }
      return setLoad(false);
    }
    Getcontacts();
  }, [CallToTheAPI]);

  async function GetContactsAgain() {
    const params = {
      operation: ['Profile', 'Contact', 'Get'],
    };
    const response = await CallToTheAPI(params);
    if (response.success) {
      setContacts(response.body.contacts.elements);
    }
  }

  return (
    <div>
      <Card>
        {load ? (
          <LoadContainer>
            <Spin
              style={{ color: theme.color.primary }}
              indicator={
                <LoadingOutlined
                  style={{ fontSize: 24, color: theme.color.primary }}
                  spin
                />
              }
              tip="Carregando..."
            />
          </LoadContainer>
        ) : (
          <>
            <Header title="Contatos" GetContactsAgain={GetContactsAgain} />
            <Divider />
            <Content GetContactsAgain={GetContactsAgain} contacts={contacts} />
          </>
        )}
      </Card>
    </div>
  );
}
