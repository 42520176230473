import React from 'react';
// Libraries
import { Form, Row, Col, Divider } from 'antd';
// Components
import FormItem from '~/components/form/FormItem';
import Input from '~/components/input';

export default function SearchPerson({ form }) {
  return (
    <div>
      <Divider>Busque o seu familiar</Divider>
      <Form
        labelCol={{ xs: { span: 24 }, sm: { span: 24 } }}
        wrapperCol={{ xs: { span: 24 }, sm: { span: 24 } }}
        form={form}
        size="small"
        layout="vertical"
        scrollToFirstError
      >
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <div style={{ padding: 5 }}>
              <FormItem
                label="Nome Completo"
                name={['filter', 'name']}
                item={
                  <Input />
                }
              />
            </div>
          </Col>
          <Col xs={24} sm={24} md={18} lg={18} xl={18}>
            <div style={{ padding: 5 }}>
              <FormItem
                label="Nome da Mãe"
                name={['filter', 'mother_name']}
                item={
                  <Input />
                }
              />
            </div>
          </Col>
          <Col xs={24} sm={24} md={6} lg={6} xl={6}>
            <div style={{ padding: 5 }}>
              <FormItem
                label="Nascimento"
                name={['filter', 'birth_certificate_date']}
                item={
                  <Input mask="11/11/1111" />
                }
              />
            </div>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <div style={{ padding: 5 }}>
              <FormItem
                label="CPF"
                name={['filter', 'cpf_number']}
                item={
                  <Input mask="111.111.111-11" />
                }
              />
            </div>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <div style={{ padding: 5 }}>
              <FormItem
                label="CNS"
                name={['filter', 'cns_number']}
                item={
                  <Input mask="111 1111 1111 1111" />
                }
              />
            </div>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <div style={{ padding: 5 }}>
              <FormItem
                label="PIS"
                name={['filter', 'ctps_pis_pasep']}
                item={
                  <Input mask="111.11111.11-1" />
                }
              />
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
}