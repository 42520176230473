import React, { useContext } from 'react';
// Libraries
import { Divider, Row, Col } from 'antd';
// Contexts
import { LanguageContext } from '~/contexts/LanguageContext';
// Components
import FormItem from '~/components/form/FormItem';
import Input from '~/components/input';
import Select from '~/components/select';
// Environments
import { Sex, Gender } from '~/environments/Selects';

export default function SocialIdentity() {
  const { translate } = useContext(LanguageContext);
  return (
    <Row>
      <Divider style={{ margin: '10px 0px' }}>
        {translate.accountManagement.socialIdentity.text}
      </Divider>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        <FormItem
          label={translate.fields.socialNameLabel.text}
          name={['social_name']}
          helpText={translate.fields.socialNameHelp.text}
          item={
            <Input placeholder={translate.fields.socialNamePlaceholder.text} />
          }
          style={{ marginRight: 10 }}
        />
      </Col>
      <Col xs={24} sm={24} md={5} lg={5} xl={5}>
        <FormItem
          label={translate.fields.socialSexLabel.text}
          name={['social_sex']}
          helpText={translate.fields.socialSexHelp.text}
          item={
            <Select placeholder={translate.fields.select.text} options={Sex} />
          }
          style={{ marginRight: 10 }}
        />
      </Col>
      <Col xs={24} sm={24} md={7} lg={7} xl={7}>
        <FormItem
          label={translate.fields.genderLabel.text}
          name={['gender']}
          helpText={translate.fields.genderHelp.text}
          item={
            <Select
              placeholder={translate.fields.select.text}
              options={Gender}
            />
          }
        />
      </Col>
    </Row>
  );
}
