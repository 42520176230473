import React from 'react';
// Libraries
import { Route, Switch, useRouteMatch } from 'react-router-dom';
// Components
import Tabs from '~/components/tabs';
import PendingList from './components/PendingList';
import RequestsMade from './components/RequestsMade';
import RelationshipList from './components/RelationshipList';

export default function Content() {
  const { path } = useRouteMatch();
  const tabsItems = [
    {
      title: 'Minhas vinculações',
      tabPath: '/',
      badgeCount: 0,
    },
    {
      title: 'Solicitações recebidas',
      tabPath: '/solicitacoes-recebidas',
    },
    {
      title: 'Solicitações pendentes',
      tabPath: '/solicitacoes-realizadas',
    },
  ];
  return (
    <div>
      <Tabs tabsItems={tabsItems} />
      <Switch>
        <Route
          exact
          path={path}
          component={() => (
            <RelationshipList />
          )}
        />
        <Route
          path={`${path}/solicitacoes-recebidas`}
          component={() => (
            <PendingList />
          )}
        />
        <Route
          path={`${path}/solicitacoes-realizadas`}
          component={() => (
            <RequestsMade />
          )}
        />
      </Switch>
    </div>
  );
}
