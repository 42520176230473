import React, { useContext } from 'react';
// Contexts
import { ResponsiveContext } from '~/contexts/ResponsiveContext';
//Own Components
import Avatar from './components/Avatar';
import SocialMeIdentity from './components/SocialMeIdentity';
import QRCode from './components/QRCode';
// Standalone Components
import { Div } from './style';
//Components
import Card from '~/components/card';
import Divider from '~/components/divider';
import Title from '~/components/typography/title';
// Utils
import { UserName } from '~/utils/UserInformation';

export default function UserCard({ person, GetProfileAgain }) {
  const { isMobile } = useContext(ResponsiveContext);

  const cardStyle = {
    height: '70vh',
  };

  return (
    <Div>
      <Card style={cardStyle}>
        <Avatar person={person} GetProfileAgain={GetProfileAgain} />
        <Title level={4} style={{ textAlign: 'center' }}>
          {UserName()}
        </Title>

        <Divider />
        <QRCode person={person} GetProfileAgain={GetProfileAgain} />
        {!isMobile && (
          <>
            <Divider />
            <SocialMeIdentity name="123" qrCode="123" />
          </>
        )}
      </Card>
    </Div>
  );
}
