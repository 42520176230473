import styled from 'styled-components';

import System from '~/environments/System';

import { IoIosNotificationsOutline } from 'react-icons/io';

export const Div = styled.div``;

export const NotificationIcon = styled(IoIosNotificationsOutline)`
  font-size: 22px !important;

  @media (max-width: ${`${System.ResponsiveBreakpoint}px`}) {
    font-size: 26px !important;
  }
`;
