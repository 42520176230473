import React from 'react';
// Components
import FormItem from '~/components/form/FormItem';
import Input from '~/components/input';

export default function CNSForm({ formValues, form }) {
  return (
    <div>
      <Number />
    </div>
  );
}

const Number = () => {
  return (
    <FormItem
      label={"Número do CNS"}
      name={['cns_number']}
      rules={[
        {
          required: true,
          message: 'Um número é obrigatório',
        },
        () => ({
          validator(rule, value) {
            if (value.includes('_')) {
              return Promise.reject('Insira um PIS válido');
            }
            return Promise.resolve();
          },
        }),
      ]}
      item={
        <Input mask="111 1111 1111 1111" />
      }
    />
  )
}