import React from 'react';
// Libraries
import { Row, Col } from 'antd';
//Own Components
import StoreButton from './components/StoreButton';
//Components
import Title from '~/components/typography/title';

export default function ContactHeader({ title, GetRelantionshipsAgain }) {
  return (
    <div>
      <Row>
        <Col
          style={{ display: 'flex', justifyContent: 'flex-start' }}
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
        >
          <Title level={4}>{title}</Title>
        </Col>
        <Col
          style={{ display: 'flex', justifyContent: 'flex-end' }}
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
        >
          <StoreButton
            GetRelantionshipsAgain={GetRelantionshipsAgain}
          />
        </Col>
      </Row>
    </div>
  );
}
