import React from 'react';
// Contexts
// Libraries
import { List, Avatar } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
// Components
import Button from '~/components/button';

const dataFake = [
  {
    name: 'Prefeitura de Anavilhanas',
    avatar: 'http://www.consciencia.org/wp-content/uploads/2008/02/La-Ruta-Hacia-El-Dorado.jpg',
    description: 'Enviou o pedido há 2 semanas'
  }
]

export default function PendingList() {
  return(
    <List
        itemLayout="horizontal"
        dataSource={dataFake}
        renderItem={(item) => (
          <List.Item
            actions={[
              <Button
                text="Não aceitar vinculação"
                icon={<CloseOutlined />}
              />,
              <Button
                text="Aceitar vinculação"
                type="primary"
                icon={<CheckOutlined />}
              />,
            ]}
          >
            <List.Item.Meta
              avatar={
                <Avatar src={item.avatar} />
              }
              title={item.name}
              description={item.description}
            />
          </List.Item>
        )}
      />
  )
}
