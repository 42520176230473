import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0;
`;

export const Box = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 10px 10px 0;
`;
