import React, { useState, useEffect } from 'react';
// Components
import Header from './components/Header';
import Content from './components/Content';
import Card from '~/components/card';
import Loading from '~/components/loading';
import Divider from '~/components/divider';
// Standalone components
import { Container } from './style';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';

export default function Contact() {
  const [load, setLoad] = useState(true);
  const [addresses, setAddresses] = useState([]);

  useEffect(() => {
    async function GetAddresses() {
      const params = {
        operation: ['Profile', 'Address', 'Get'],
      };
      const response = await SocialMeAPI(params);
      if (response.success) {
        setAddresses(response.body.addresses.elements);
        return setLoad(false);
      }
      return setLoad(false);
    }
    GetAddresses();
  }, []);

  async function GetAddressesAgain() {
    const params = {
      operation: ['Profile', 'Address', 'Get'],
    };
    const response = await SocialMeAPI(params);
    if (response.success) {
      setAddresses(response.body.addresses.elements);
    }
  }

  return (
    <Container>
      <Card>
        {load ? (
          <Loading />
        ) : (
          <>
            <Header title="Endereços" GetAddressesAgain={GetAddressesAgain} />
            <Divider />
            <Content
              GetAddressesAgain={GetAddressesAgain}
              addresses={addresses}
            />
          </>
        )}
      </Card>
    </Container>
  );
}
