import styled from 'styled-components';

export const Container = styled.div`
  padding: 5px;
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 5px;
`;

export const LeftItems = styled.div``;

export const RightItems = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
