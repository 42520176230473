import React from 'react';
// Components
import FormItem from '~/components/form/FormItem';
import Input from '~/components/input';

export default function VoterCertificateForm({ formValues, form }) {
  return (
    <div>
      <Number />
      <Zone />
      <Section />
    </div>
  );
}

const Number = () => {
  return (
    <FormItem
      label={"Número do Título de eleitor"}
      name={['voter_certificate_number']}
      rules={[
        {
          required: true,
          message: 'Um número é obrigatório',
        },
        () => ({
          validator(rule, value) {
            if (value.includes('_')) {
              return Promise.reject('Insira um número válido');
            }
            return Promise.resolve();
          },
        }),
      ]}
      item={
        <Input mask="1111 1111 1111 11" />
      }
    />
  )
}

const Zone = () => {
  return (
    <FormItem
      label={"Zona"}
      name={['voter_certificate_zone']}
      item={
        <Input placeholder="Digite sua zona eleitoral" mask="111" />
      }
    />
  )
}

const Section = () => {
  return (
    <FormItem
      label={"Seção"}
      name={['voter_certificate_section']}
      item={
        <Input placeholder="Digite sua seção" mask="1111" />
      }
    />
  )
}