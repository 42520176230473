import React from 'react';
// Libraries
import { Avatar, Divider, Form, Typography, Alert } from 'antd';
// Services
import { GetDefaultAvatar } from '~/utils/SocialMeAssets';

const { Title } = Typography;

export default function Fill({ people, form }) {
  return (
    <div>
      <Form
        labelCol={{ xs: { span: 24 }, sm: { span: 24 } }}
        wrapperCol={{ xs: { span: 24 }, sm: { span: 24 } }}
        form={form}
        size="small"
        layout="vertical"
        scrollToFirstError
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <Avatar
            size={124}
            src={people.avatar ? people.avatar : GetDefaultAvatar('other')}
            style={{ marginBottom: 10 }}
          />
          <Title level={4}>{people.name}</Title>
        </div>
        <Divider />
        <Alert
          message="Atenção"
          description="A prefeitura poderá visualizar seus dados e lhe enviar solicitações"
          type="warning"
          showIcon
        />
      </Form>
    </div>
  );
}
