import React, { useState, useEffect, useContext } from 'react';
// Contexts
import { LanguageContext } from '~/contexts/LanguageContext';
//Own Components
import Header from './components/Header';
import Content from './components/Content';
// Standalone Components
import { Container } from './style';
//Components
import Card from '~/components/card';
import Divider from '~/components/divider';
import Loading from '~/components/loading';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';

export default function Documents() {
  const { translate } = useContext(LanguageContext);
  const [loadingContent, setLoadingContent] = useState(true);
  const [person, setPerson] = useState();

  useEffect(() => {
    async function GetProfile() {
      const params = {
        operation: ['Profile', 'Get'],
      };
      const response = await SocialMeAPI(params);
      if (response.success) {
        setPerson(response.body.person);
        return setLoadingContent(false);
      }
      return setLoadingContent(false);
    }
    GetProfile();
  }, []);

  async function GetProfileAgain() {
    const params = {
      operation: ['Profile', 'Get'],
    };
    const response = await SocialMeAPI(params);
    if (response.success) {
      setPerson(response.body.person);
    }
  }

  return (
    <Container>
      <Card>
        <Header title={translate.accountManagement.documentsTitle.text} />
        <Divider />
        {loadingContent ?
          <Loading text={translate.messages.fetchingYourDocuments.text} />
          :
          <Content person={person} GetProfileAgain={GetProfileAgain} />
        }
      </Card>
    </Container>
  );
}