import React from 'react';
// Contexts
// Libraries
import { List, Avatar } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
// Components
import Button from '~/components/button';

const dataFake = [
  {
    name: 'Prefeitura de Nova Petrópolis',
    avatar: 'https://www.viajali.com.br/wp-content/uploads/2020/10/nova-petropolis-1.jpg',
    description: 'Solicitação realizada há 3 semanas'
  }
]

export default function RequestsMade() {
  return (
    <List
      itemLayout="horizontal"
      dataSource={dataFake}
      renderItem={(item) => (
        <List.Item
          actions={[
            <Button
              text="Cancelar solicitação"
              icon={<CloseOutlined />}
            />,
          ]}
        >
          <List.Item.Meta
            avatar={
              <Avatar src={item.avatar} />
            }
            title={item.name}
            description={item.description}
          />
        </List.Item>
      )}
    />
  )
}
