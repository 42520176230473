import React from 'react';
// Components
import FormItem from '~/components/form/FormItem';
import Input from '~/components/input';

export default function CPFForm({ formValues, form }) {
  return (
    <div>
      <PIS />
      <Number />
    </div>
  );
}

const PIS = () => {
  return (
    <FormItem
      label={"PIS"}
      name={['ctps_pis_pasep']}
      rules={[
        {
          required: true,
          message: 'Um número é obrigatório',
        },
        () => ({
          validator(rule, value) {
            if (value.includes('_')) {
              return Promise.reject('Insira um PIS válido');
            }
            return Promise.resolve();
          },
        }),
      ]}
      item={
        <Input mask="111.11111.11-1" />
      }
    />
  )
}

const Number = () => {
  return (
    <FormItem
      label={"Número"}
      name={['ctps_number']}
      rules={[
        () => ({
          validator(rule, value) {
            if (value.includes('_')) {
              return Promise.reject('Insira um número válido');
            }
            return Promise.resolve();
          },
        }),
      ]}
      item={
        <Input mask="1111111" />
      }
    />
  )
}