import React, { useContext, useState, useEffect } from 'react';
// Libraries
import { message, Drawer, Form } from 'antd';
// Components
import Questions from './components/Questions';
import Button from '~/components/button';
// Contexts
import { AxiosContext } from '~/contexts/AxiosContext';
import { ResponsiveContext } from '~/contexts/ResponsiveContext';

export default function Actions({ address, GetAddressesAgain }) {
  const { CallToTheAPI } = useContext(AxiosContext);
  const { isMobile } = useContext(ResponsiveContext);

  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState('');

  useEffect(() => {
    if (address) {
      address.country = address.country_id;
      address.state = address.state_id;
      address.city = address.city_id;
      address.community = address.community_id;
    }
  }, [address]);
  const ShowDrawer = () => {
    setVisibleDrawer(true);
  };
  const CloseDrawer = () => {
    setVisibleDrawer(false);
  };
  async function EditAddress(data) {
    const params = {
      operation: ['Profile', 'Address', 'Edit'],
      urlParams: {
        addressId: address.id,
      },
      data,
    };
    const response = await CallToTheAPI(params);
    if (response.success) {
      message.success('Endereço Atualizado!');
      setLoadingButton(false);
      CloseDrawer();
      return GetAddressesAgain();
    }
    message.error('Alguma coisa aconteceu...');
    setLoadingButton(false);
  }

  const onFinish = (data) => {
    //console.log('Received data of form: ', data);
    EditAddress(data);
  };
  return (
    <>
      <Button
        text="Editar"
        onClick={() => ShowDrawer()}
        style={{ marginRight: 5 }}
      />

      <Drawer
        title={`EDITANDO ${address.description}`}
        width={isMobile ? '100%' : 600}
        onClose={CloseDrawer}
        visible={visibleDrawer}
        bodyStyle={{ padding: 20 }}
        footer={
          <div
            style={{
              textAlign: 'right',
            }}
          >
            <Button onClick={CloseDrawer} style={{ marginRight: 8 }}>
              Cancelar
            </Button>

            <Button
              loading={loadingButton}
              disabled={loadingButton}
              type="primary"
              onClick={() => {
                form
                  .validateFields()
                  .then((values) => {
                    //form.resetFields();
                    //form.setFieldsValue(values);
                    onFinish(values);
                  })
                  .catch((info) => {
                    //console.log('Validate Failed:', info);
                  });
              }}
            >
              Atualizar
            </Button>
          </div>
        }
      >
        <Form
          labelCol={{
            xs: { span: 24 },
            sm: { span: 24 },
          }}
          wrapperCol={{
            xs: { span: 24 },
            sm: { span: 24 },
          }}
          form={form}
          layout="vertical"
          size="small"
          onValuesChange={() => setFormValues(form.getFieldsValue())}
          scrollToFirstError
          initialValues={address}
        >
          <Questions formValues={formValues} form={form} address={address} />
        </Form>
      </Drawer>
    </>
  );
}
