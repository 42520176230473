import React, { useContext, memo } from 'react';
// Contexts
import { ResponsiveContext } from '~/contexts/ResponsiveContext';
// Components
import Space from '~/components/space';
//Libraries
import { MainButton, Div } from './style';

function AntButton({
  children,
  disabled,
  htmlType,
  icon,
  loading,
  onClick,
  onKeyDown,
  onKeyPress,
  size,
  style,
  text,
  type,
  shape,
  anotherLibraryIcon,
}) {
  const { isMobile } = useContext(ResponsiveContext);
  return (
    <MainButton
      shape={shape}
      style={style}
      loading={loading}
      disabled={disabled}
      onClick={onClick}
      onKeyDown={onKeyDown}
      onKeyPress={onKeyPress}
      icon={anotherLibraryIcon ? false : icon}
      htmlType={htmlType}
      type={type || 'default'}
      size={isMobile ? 'large' : size ? size : 'default'}
    >
      {anotherLibraryIcon ? (
        <Space>
          <Div>{icon}</Div>
          <Div>{children || text}</Div>
        </Space>
      ) : (
        children || text
      )}
    </MainButton>
  );
}

export default memo(AntButton);
