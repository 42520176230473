import React, { useContext } from 'react';
// Libraries
import { message, Popconfirm } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
// Contexts
import { AxiosContext } from '~/contexts/AxiosContext';
import { ThemeContext } from '~/contexts/ThemeContext';

export default function Actions({ contact, GetContactsAgain }) {
  const { CallToTheAPI } = useContext(AxiosContext);
  const { theme } = useContext(ThemeContext);

  async function DeleteContact() {
    const params = {
      operation: ['Profile', 'Contact', 'Delete'],
      urlParams: {
        contactId: contact.id,
      },
    };
    const response = await CallToTheAPI(params);
    if(response.success){
      GetContactsAgain();
      return message.success('Contato Deletado!');
    }
    return message.error('Alguma coisa aconteceu...');
  }

  return (
    <Popconfirm
      placement="top"
      title="Deseja deletar esse contato?"
      onConfirm={() => DeleteContact()}
      okText="Sim, delete agora"
      cancelText="Não"
    >
      <DeleteOutlined style={{ color: theme.color.danger }} />
      {/* 
      <Button
        type="secondary"
        shape="square"
        icon={<DeleteOutlined style={{ color: theme.color.danger }} />}
      />
      */}
    </Popconfirm>
  );
}
