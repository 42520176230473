import React, { memo } from 'react';

import Avatar from '~/components/avatar';
import Text from '~/components/typography/text';

import { Container, TextBox, Box } from './style';

function MessageItem({ theme }) {
  const fakeMessages = [
    {
      avatar: 'https://uifaces.co/our-content/donated/xZ4wg2Xj.jpg',
      name: 'Matheus Ribeiro',
      lastMessage:
        'Olá, meu nome é Matheus Ribeiro e estou avisando você sobre um assunto importante.',
      timeThatMessageWasSend: '2 sem',
    },
    {
      avatar: 'https://randomuser.me/api/portraits/women/95.jpg',
      name: 'Emily watson',
      lastMessage:
        'Olá, meu nome é Emily watson e estou avisando você sobre um assunto importante.',
      timeThatMessageWasSend: '2 sem',
    },
    {
      avatar:
        'https://images-na.ssl-images-amazon.com/images/M/MV5BMjAwMzc5OTEzOF5BMl5BanBnXkFtZTgwMDc5ODU3MTE@._V1_UX172_CR0,0,172,256_AL_.jpg',
      name: 'Sabrina ramalho',
      lastMessage:
        'Olá, meu nome é Sabrina ramalho e estou avisando você sobre um assunto importante.',
      timeThatMessageWasSend: '2 sem',
    },
    {
      avatar: 'https://uifaces.co/our-content/donated/ukegoVAy.jpg',
      name: 'Diego da Silva',
      lastMessage:
        'Olá, meu nome é Diego da Silva e estou avisando você sobre um assunto importante.',
      timeThatMessageWasSend: '2 sem',
    },
  ];
  return (
    <Container
      scrollBarColor={theme.color.white}
      scrollBarThumbColor={theme.color.scrollBar}
    >
      {fakeMessages.map((message) => (
        <Box key={message.lastMessage}>
          <Avatar src={message.avatar} size={54} />
          <TextBox>
            <Text strong>{message.name}</Text>
            <Text>
              {`${message.lastMessage} - ${message.timeThatMessageWasSend}`.substr(
                0,
                45
              ) + '...'}
            </Text>
          </TextBox>
        </Box>
      ))}
    </Container>
  );
}

export default memo(MessageItem);
