import React, { memo, useContext } from "react";
// Libraries
import Pagination from "./style";

import { ResponsiveContext } from "~/contexts/ResponsiveContext";
import { ThemeContext } from "~/contexts/ThemeContext";

function AntPagination({
  onChange,
  currentPage,
  limit,
  onShowSizeChange,
  total,
  showTotal,
  showSizeChanger,
  showQuickJumper,
}) {
  const { theme } = useContext(ThemeContext);
  const { isMobile } = useContext(ResponsiveContext);

  function HandleOnSizeChange(currentPage, limit) {
    const lastPage = Math.round(total / limit);

    if (currentPage * limit > total) {
      onChange(lastPage);
      onShowSizeChange(limit);
    } else {
      onShowSizeChange(limit);
    }
  }

  return (
    <Pagination
      textColor={theme.color.textOnPrimaryBackground}
      primary={theme.color.primary}
      borderColor={theme.color.primary}
      defaultCurrent={1}
      pageSize={limit || 12}
      current={currentPage}
      onChange={onChange}
      onShowSizeChange={HandleOnSizeChange}
      total={total}
      showLessItems={isMobile && true}
      showSizeChanger={isMobile ? false : showSizeChanger}
      showQuickJumper={isMobile ? false : showQuickJumper}
      showTotal={showTotal}
    />
  );
}

export default memo(AntPagination);
