import React from 'react';
// Utils
import { ExistingUser } from '~/utils/UserInformation';
//Pages
import ProfileDescription from './ProfileDescription';
import Profile from './Profile';

export default function AuthenticationCheck() {
  return ExistingUser() ? <Profile /> : <ProfileDescription />;
}
