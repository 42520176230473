const defaultAvatars = {
  feminine:
    'https://socialme-assets.s3.amazonaws.com/images/avatars/defaultProfileFeminine.jpg',
  male:
    'https://socialme-assets.s3.amazonaws.com/images/avatars/defaultProfileMale.jpg',
  partner: 'https://socialme-assets.s3.amazonaws.com/images/avatars/defaultProfilePartner.jpg',
  other: 'https://uifaces.co/our-content/donated/t33XAm04.jpg'
};

const brandsIcons = {
  accountManagement:
    'https://socialme-assets.s3.us-east-1.amazonaws.com/images/brands/account_64x64.png',
  operationsFinder:
    'https://socialme-assets.s3.us-east-1.amazonaws.com/images/brands/achei_64x64.png',
  digitalWallet:
    'https://socialme-assets.s3.us-east-1.amazonaws.com/images/brands/wallet_64x64.png',
  beneficiary:
    'https://socialme-assets.s3.us-east-1.amazonaws.com/images/brands/beneficiary_64x64.png',
  manager:
    'https://socialme-assets.s3.us-east-1.amazonaws.com/images/brands/manager_64x64.png',
  partner:
    'https://socialme-assets.s3.us-east-1.amazonaws.com/images/brands/provider_64x64.png',
  donor:
    'https://socialme-assets.s3.us-east-1.amazonaws.com/images/brands/giver_64x64.png',
  donationsPortal:
    'https://socialme-assets.s3.us-east-1.amazonaws.com/images/brands/doacoesMe_64x64.png',
  marketplace:
    'https://socialme-assets.s3.us-east-1.amazonaws.com/images/brands/feiraLivre_64x64.png',
  ecoop:
    'https://socialme-assets.s3.us-east-1.amazonaws.com/images/brands/ecoop_64x64.png',
  administration:
    'https://socialme-assets.s3.us-east-1.amazonaws.com/images/icons/modules/admin.svg',
  citizen:
    'https://socialme-assets.s3.us-east-1.amazonaws.com/images/brands/cidadao_64x64.png',
  medicalRecord:
    'https://socialme-assets.s3.us-east-1.amazonaws.com/images/brands/prontuario_64x64.png',
  socialMe:
    'https://socialme-assets.s3.us-east-1.amazonaws.com/images/brands/socialMe.png',
  socialMeVariation1:
    'https://socialme-assets.s3.amazonaws.com/images/brands/socialMe_variation1.svg',
  socialMeVariation2:
    'https://socialme-assets.s3.amazonaws.com/images/brands/socialMe_variation2.svg',
  socialMeVariation3:
    'https://socialme-assets.s3.amazonaws.com/images/brands/socialMe_variation3.svg',
  socialMeVariation4:
    'https://socialme-assets.s3.amazonaws.com/images/brands/socialMe_variation4.svg',
  socialMeVariation5:
    'https://socialme-assets.s3.amazonaws.com/images/brands/socialMe_variation5.svg',
  socialMeVariation6:
    'https://socialme-assets.s3.amazonaws.com/images/brands/socialMe_variation6.svg',
  socialMe_black64x64:
    'https://socialme-assets.s3.amazonaws.com/images/brands/socialMe_black64x64.jpg'
};

const documentsIcons = {
  CTPS: 'https://socialme-assets.s3.us-east-1.amazonaws.com/images/icons/documents/carteiraDeTrabalho.png',
  BirthCertificate: 'https://socialme-assets.s3.us-east-1.amazonaws.com/images/icons/documents/certidaoDeNascimento.png',
  CNH: 'https://socialme-assets.s3.us-east-1.amazonaws.com/images/icons/documents/cnh.png',
  CNS: 'https://socialme-assets.s3.us-east-1.amazonaws.com/images/icons/documents/cns.png',
  CPF: 'https://socialme-assets.s3.us-east-1.amazonaws.com/images/icons/documents/cpf.png',
  CNPJ: 'https://socialme-assets.s3.us-east-1.amazonaws.com/images/icons/documents/cpf.png',
  Passport: 'https://socialme-assets.s3.us-east-1.amazonaws.com/images/icons/documents/passaporte.png',
  RG: 'https://socialme-assets.s3.us-east-1.amazonaws.com/images/icons/documents/rg.png',
  VoterCertificate: 'https://socialme-assets.s3.us-east-1.amazonaws.com/images/icons/documents/tituloDeEleitor.png'
}

export function PickMeCoinIcon() {
  return "https://socialme-assets.s3.us-east-1.amazonaws.com/images/icons/me_coin2.png";
}

export function GetBrandIcon(name) {
  return brandsIcons[name];
}

export function GetDefaultAvatar(name) {
  return defaultAvatars[name];
}

export function GetDocumentIcon(name) {
  return documentsIcons[name];
}
