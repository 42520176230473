import React from 'react';
// Components
import UserDisconnectedDescription from '~/components/pageStructure/UserDisconnectedDescription';

export default function Contact() {
  return (
    <UserDisconnectedDescription
      description="Nesta página você pode gerenciar os seus contatos principais utilizados na SocialMe, como e-mails, telefone fixos e celulares."
    />
  );
}
