import React, { useEffect, useState, useContext } from 'react';
// Contexts
import { AxiosContext } from '~/contexts/AxiosContext';
// Libraries
import axios from 'axios';
import { Radio } from 'antd';
// Components
import FormItem from '~/components/form/FormItem';
import Select from '~/components/select';
import Input from '~/components/input';

export default function AddressForm({ formValues, form, address }) {
  const { CallToTheAPI } = useContext(AxiosContext);

  const [cepConsulted, setCepConsulted] = useState('');
  const [countries, setCountries] = useState([]);
  const [loadingCountries, setLoadingCountries] = useState(true);
  const [disabledStateSelect, setDisabledStateSelect] = useState(true);
  const [states, setStates] = useState([]);
  const [loadingStateSelect, setLoadingStateSelect] = useState(false);
  const [disabledCitiesSelect, setDisabledCitiesSelect] = useState(true);
  const [cities, setCities] = useState([]);
  const [loadingCitySelect, setLoadingCitySelect] = useState(false);
  const [disabledCommunitiesSelect, setDisabledCommunitiesSelect] = useState(true);
  const [communities, setCommunities] = useState([]);
  const [loadingCommunitySelect, setLoadingCommunitySelect] = useState(false);

  useEffect(() => {
    async function GetCountries() {
      const params = {
        operation: ['System', 'Location', 'Get'],
        data: {
          filter: {
            type: 'COU',
          },
          pagination: {
            sort: {
              by: 'name',
              order: 'asc',
            },
          }
        },
      };
      const response = await CallToTheAPI(params);
      if (response.success) {
        let data = [];
        response.body.countryStateCityCommunityList.elements.map((item) => {
          let newItem = {};
          if (item.name === 'BRASIL') {
            newItem.value = item.id;
            newItem.label = item.name;
            data.push(newItem);
          }
          return null;
        });
        /*
        response.body.countryStateCityCommunityList.elements.map((item) => {
          let newItem = {};
          newItem.value = item.id;
          newItem.label = item.name;
          data.push(newItem);
          return null;
        });
        */
        setCountries(data);
        setLoadingCountries(false);
      }
    }
    GetCountries();
  }, [CallToTheAPI]);

  useEffect(() => {
    async function GetStates(countryId) {
      const params = {
        operation: ['System', 'Location', 'Get'],
        data: {
          filter: {
            type: 'ST',
            country: countryId,
          },
          pagination: {
            sort: {
              by: 'name',
              order: 'asc',
            },
            limit: 0,
          }
        }
      };
      const response = await CallToTheAPI(params);
      if (response.success) {
        let data = [];
        response.body.countryStateCityCommunityList.elements.map((item) => {
          let newItem = {};
          newItem.value = item.id;
          newItem.label = item.name;
          data.push(newItem);
          return null;
        });
        setStates(data);
        setLoadingStateSelect(false);
        setDisabledStateSelect(false);
      }
    }
    if (form.getFieldsValue().state) {
      GetStates(form.getFieldsValue().country);
    }
  }, [CallToTheAPI, form])

  useEffect(() => {
    async function GetCities(stateId) {
      const params = {
        operation: ['System', 'Location', 'Get'],
        data: {
          filter: {
            type: 'CI',
            state: stateId,
          },
          pagination: {
            sort: {
              by: 'name',
              order: 'asc',
            },
            limit: 0,
          }
        }
      };
      const response = await CallToTheAPI(params);
      if (response.success) {
        let data = [];
        response.body.countryStateCityCommunityList.elements.map((item) => {
          let newItem = {};
          newItem.value = item.id;
          newItem.label = item.name;
          data.push(newItem);
          return null;
        });
        setCities(data);
        setLoadingCitySelect(false);
        setDisabledCitiesSelect(false);
      }
    }
    if (form.getFieldsValue().city) {
      GetCities(form.getFieldsValue().state);
    }
  }, [CallToTheAPI, form])

  useEffect(() => {
    async function GetCommunities(cityId) {
      const params = {
        operation: ['System', 'Location', 'Get'],
        data: {
          filter: {
            type: 'COM',
            city: cityId,
          },
          pagination: {
            sort: {
              by: 'name',
              order: 'asc',
            },
            limit: 0,
          }
        }
      };
      const response = await CallToTheAPI(params);
      if (response.success) {
        let data = [];
        response.body.countryStateCityCommunityList.elements.map((item) => {
          let newItem = {};
          newItem.value = item.id;
          newItem.label = item.name;
          data.push(newItem);
          return null;
        });
        setCommunities(data);
        setLoadingCommunitySelect(false);
      }
    }
    if (form.getFieldsValue().community) {
      GetCommunities(form.getFieldsValue().city);
    }
  }, [CallToTheAPI, form])

  async function GetStates(countryId) {
    const params = {
      operation: ['System', 'Location', 'Get'],
      data: {
        filter: {
          type: 'ST',
          country: countryId,
        },
        pagination: {
          sort: {
            by: 'name',
            order: 'asc',
          },
          limit: 0,
        }
      }
    };
    const response = await CallToTheAPI(params);
    if (response.success) {
      let data = [];
      response.body.countryStateCityCommunityList.elements.map((item) => {
        let newItem = {};
        newItem.value = item.id;
        newItem.label = item.name;
        data.push(newItem);
        return null;
      });
      setStates(data);
      setLoadingStateSelect(false);
    }
  }

  async function GetCities(stateId) {
    const params = {
      operation: ['System', 'Location', 'Get'],
      data: {
        filter: {
          type: 'CI',
          state: stateId,
        },
        pagination: {
          sort: {
            by: 'name',
            order: 'asc',
          },
          limit: 0,
        }
      }
    };
    const response = await CallToTheAPI(params);
    if (response.success) {
      let data = [];
      response.body.countryStateCityCommunityList.elements.map((item) => {
        let newItem = {};
        newItem.value = item.id;
        newItem.label = item.name;
        data.push(newItem);
        return null;
      });
      setCities(data);
      setLoadingCitySelect(false);
    }
  }

  async function GetCommunities(cityId) {
    const params = {
      operation: ['System', 'Location', 'Get'],
      data: {
        filter: {
          type: 'COM',
          city: cityId,
        },
        pagination: {
          sort: {
            by: 'name',
            order: 'asc',
          },
          limit: 0,
        }
      }
    };
    const response = await CallToTheAPI(params);
    if (response.success) {
      let data = [];
      response.body.countryStateCityCommunityList.elements.map((item) => {
        let newItem = {};
        newItem.value = item.id;
        newItem.label = item.name;
        data.push(newItem);
        return null;
      });
      setCommunities(data);
      setLoadingCommunitySelect(false);
    }
  }

  async function SelectCountry(data) {
    form.setFieldsValue({ state: '' });
    form.setFieldsValue({ city: '' });
    form.setFieldsValue({ community: '' });
    setStates([]);
    setDisabledStateSelect(false);
    setLoadingStateSelect(true);
    GetStates(data.value);
  }

  async function SelectState(data) {
    form.setFieldsValue({ city: '' });
    form.setFieldsValue({ community: '' });
    setCities([]);
    setDisabledCitiesSelect(false);
    setLoadingCitySelect(true);
    GetCities(data.value);
  }

  async function SelectCity(data) {
    form.setFieldsValue({ community: '' });
    setCommunities([]);
    setDisabledCommunitiesSelect(false);
    setLoadingCommunitySelect(true);
    GetCommunities(data.value);
  }

  useEffect(() => {
    if (formValues && formValues.zip_code) {
      if (formValues.zip_code.replace(/[^\d]+/g, '').length === 8) {
        if (formValues.zip_code.replace(/[^\d]+/g, '') !== cepConsulted) {
          axios
            .get(`https://viacep.com.br/ws/${formValues.zip_code.replace(/[^\d]+/g, '')}/json/`)
            .then(function (response) {
              form.setFieldsValue({ address: response.data.logradouro });
              form.setFieldsValue({ neighborhood: response.data.bairro });
            })
            .catch(function (error) {
              //console.log(error);
            });
          setCepConsulted(formValues.zip_code.replace(/[^\d]+/g, ''));
        }
      }
    }
  }, [formValues, cepConsulted, form]);

  return (
    <>
      <IsLivingOnStreet />

      {address.is_street === false ?
        formValues.is_street === false || formValues.is_street === undefined ?
          (
            <>
              <DescQuestion />
              <DesignationQuestion />
              <CEPQuestion />
              <StreetQuestion />
              <NeighborhoodQuestion />
              <NumberQuestion />
              <ComplementQuestion />
              <CondominiumQuestion />
              <BlockQuestion />
              <RoomQuestion />
              <StartedLivingAtQuestion />
              <LeftAtQuestion />
            </>
          ) : <></> :
        formValues.is_street === false ?
          <>
            <DescQuestion />
            <DesignationQuestion />
            <CEPQuestion />
            <StreetQuestion />
            <NeighborhoodQuestion />
            <NumberQuestion />
            <ComplementQuestion />
            <CondominiumQuestion />
            <BlockQuestion />
            <RoomQuestion />
            <StartedLivingAtQuestion />
            <LeftAtQuestion />
          </>
          :
          <></>
      }

      <FormItem
        label="País"
        name={['country']}
        style={{ marginRight: 10 }}
        rules={[
          {
            required: true,
            message: 'Um país é obrigatório',
          },
        ]}
        item={
          <Select
            options={countries}
            loading={loadingCountries}
            onSelect={(value, data) => SelectCountry(data)}
          />
        }
      />

      <FormItem
        label="Estado"
        name={['state']}
        style={{ marginRight: 10 }}
        rules={[
          {
            required: true,
            message: 'Um estado é obrigatório',
          },
        ]}
        item={
          <Select
            options={states}
            loading={loadingStateSelect}
            disabled={disabledStateSelect}
            onSelect={(value, data) => SelectState(data)}
          />
        }
      />

      <FormItem
        label="Cidade"
        name={['city']}
        rules={[
          {
            required: true,
            message: 'Um cidade é obrigatório',
          },
        ]}
        item={
          <Select
            options={cities}
            loading={loadingCitySelect}
            disabled={disabledCitiesSelect}
            onSelect={(value, data) => SelectCity(data)}
          />
        }
      />

      <FormItem
        label="Comunidade"
        name={['community']}
        item={
          <Select
            options={communities}
            loading={loadingCommunitySelect}
            disabled={disabledCommunitiesSelect}
          //onSelect={(value, data) => SelectCity(data)}
          />
        }
      />
    </>
  );
}

const IsLivingOnStreet = () => {
  return (
    <FormItem
      label="Você mora na rua?"
      name={['is_street']}
      item={
        <Radio.Group>
          <Radio value={true}>Sim</Radio>
          <Radio value={false}>Não</Radio>
        </Radio.Group>
      }
    />
  )
};

const DescQuestion = () => {
  return (
    <FormItem
      label="Descrição"
      name={['description']}
      rules={[
        {
          required: true,
          message: 'Uma descrição é obrigatória',
        },
      ]}
      item={
        <Input placeholder='Ex.: Minha Casa' />
      }
    />
  )
};

const CEPQuestion = () => {
  return (
    <FormItem
      label="CEP"
      name={['zip_code']}
      item={
        <Input mask="11111-111" />
      }
    />
  )
};

const DesignationQuestion = () => {
  const options = [
    {
      label: 'Rua',
      value: 'Rua'
    },
    {
      label: 'Avenida',
      value: 'Avenida'
    },
    {
      label: 'Alameda',
      value: 'Alameda'
    }
  ]
  return (
    <FormItem
      label="A designação do seu endereço"
      name={['designation']}
      rules={[
        {
          required: true,
          message: 'Uma designação é obrigatória',
        },
      ]}
      item={
        <Select options={options} />
      }
    />
  )
};

const StreetQuestion = () => {
  return (
    <FormItem
      label="Nome da Rua, Avenida, Alameda e etc"
      name={['address']}
      item={
        <Input placeholder="Insira o nome da rua, avenida, alameda e etc" />
      }
    />
  )
};

const NeighborhoodQuestion = () => {
  return (
    <FormItem
      label="Bairro"
      name={['neighborhood']}
      item={
        <Input placeholder="Insira o nome do bairro" />
      }
    />
  )
};

const NumberQuestion = () => {
  return (
    <FormItem
      label="Número"
      name={['number']}
      item={
        <Input placeholder="Insira o número" />
      }
    />
  )
};

const ComplementQuestion = () => {
  return (
    <FormItem
      label="Complemento"
      name={['complement']}
      item={
        <Input placeholder="Ex.: casa amarela" />
      }
    />
  )
};

const CondominiumQuestion = () => {
  return (
    <FormItem
      label="Condomínio"
      name={['condominium']}
      item={
        <Input placeholder="Insira o nome do condomínio se for o caso" />
      }
    />
  )
};

const BlockQuestion = () => {
  return (
    <FormItem
      label="Bloco"
      name={['block']}
      item={
        <Input placeholder="Insira o nome do bloco se for o caso" />
      }
    />
  )
};

const RoomQuestion = () => {
  return (
    <FormItem
      label="Número do Ap"
      name={['room']}
      item={
        <Input placeholder="Insira o número da sala se for o caso" />
      }
    />
  )
};

const StartedLivingAtQuestion = () => {
  return (
    <FormItem
      label="Data aproximada de quando começou a morar no endereço"
      name={['started']}
      item={
        <Input placeholder="Insira a data aproximada de quando começou a morar no endereço" />
      }
    />
  )
};

const LeftAtQuestion = () => {
  return (
    <FormItem
      label="Data aproximada de quando deixou de morar no endereço"
      name={['ended']}
      item={
        <Input placeholder="Insira a data aproximada de quando deixou de morar no endereço" />
      }
    />
  )
};
