import React, { useEffect, useState } from 'react';
// Libraries
import { Tabs, Badge } from 'antd';
import {
  Route,
  Switch,
  useRouteMatch,
  Link,
  Redirect,
  useLocation,
  useParams,
} from 'react-router-dom';
// Standalone Components
import { Container } from './style';

const { TabPane } = Tabs;

export default function TabsDefault({ tabsItems, tabRedirect }) {
  const useRouteMatchValues = useRouteMatch();
  let { path } = useRouteMatch();
  const params = useParams();
  const location = useLocation();
  const [activeKey, setActiveKey] = useState();
  const [redirect, setRedirect] = useState(false);

  if (useRouteMatchValues.params) {
    for (const props in useRouteMatchValues.params) {
      path = path.replace(`:${props}`, params[props]);
    }
  }

  useEffect(() => {
    async function GetTabKey() {
      for (let i = 0; i < tabsItems.length; i++) {
        if (tabsItems[i].tabPath === '/') {
          if (location.pathname === path || location.pathname === `${path}/`) {
            setActiveKey(tabsItems[i].title);
          }
        } else {
          /*
          if (location.pathname === `${path}${tabsItems[i].tabPath}`) {
            setActiveKey(tabsItems[i].title);
          }
          */
          if (location.pathname.includes(tabsItems[i].tabPath)) {
            setActiveKey(tabsItems[i].title);
          }
        }
      }
    }
    if (!activeKey) {
      GetTabKey();
    }
  }, [activeKey, tabsItems, location, path]);
  async function ChangeTab(key) {
    setActiveKey(key);
    setRedirect(true);
  }
  function RenderRedirect() {
    if (redirect) {
      let redirectPath;
      for (let i = 0; i < tabsItems.length; i++) {
        if (activeKey === tabsItems[i].title)
          redirectPath = tabsItems[i].tabPath;
      }
      return <Redirect to={`${path}${redirectPath}`} />;
    }
  }
  return (
    <Container>
      {RenderRedirect()}
      <Tabs activeKey={activeKey} onChange={ChangeTab}>
        {tabsItems.map((item) => (
          <TabPane
            tab={
              <Link to={`${path}${item.tabPath}`}>
                {item.title}
                <Badge style={{ marginLeft: 5 }} count={item.badgeCount} />
              </Link>
            }
            key={item.title}
          />
        ))}
      </Tabs>
      <Switch>
        {tabRedirect ? (
          <Route exact path={`${path}`}>
            <Redirect to={`${path}${tabRedirect.to}`} />
          </Route>
        ) : (
          <></>
        )}
        {tabsItems.map((item) => (
          <Route
            key={item.tabPath}
            exact={!!item.exact}
            path={`${path}${item.tabPath}`}
            component={item.component}
          />
        ))}
      </Switch>
    </Container>
  );
}
