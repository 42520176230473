import styled from 'styled-components';

export const Container = styled.div`
  margin: 0px auto;
  display: block;
  cursor: pointer;
  width: 100px;
  height: 100px;
`;

export const PhotoContainer = styled.div`
  display: block;
  cursor: pointer;
  width: 100px;
  height: 100px;
`;

export const AvatarContent = styled.div`
  transition: all 0.2s;
  background-color: gray;
  cursor: pointer;
  width: 100px;
  height: 100px;
  border-radius: 50px;
  background-image: url('${(props) => props.backgroundImage}');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

export const IconContainer = styled.div`
  transition: opacity 0.2s ease-in-out 0s;
  background-color: rgba(32,33,36,0.9);
  height: 50px;
  position: absolute;
  top: 74px;
  width: 100px;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  opacity: ${(props) => props.opacity};
`;