import React from 'react';
// Components
import FormItem from '~/components/form/FormItem';
import Input from '~/components/input';
import Select from '~/components/select';
// Environments
import { CNHCategories } from '~/environments/Selects';
//Utils
import { ValidateDate } from '~/utils/Validations';

export default function CNHForm({ formValues, form }) {
  return (
    <div>
      <Number />
      <Category />
      <Valid />
    </div>
  );
}

const Number = () => {
  return (
    <FormItem
      label={"Número da CNH"}
      name={['cnh_number']}
      rules={[
        {
          required: true,
          message: 'Um número é obrigatório',
        },
      ]}
      item={
        <Input mask="11111111111" />
      }
    />
  )
}

const Category = () => {
  return(
    <FormItem
        label="Categoria"
        name={['cnh_category']}
        item={
          <Select
            options={CNHCategories}
          />
        }
      />
  )
}

const Valid = () => {
  return (
    <FormItem
      label="Validade"
      name={['cnh_valid_through']}
      rules={[
        () => ({
          validator(rule, value) {
            if (ValidateDate(value) === false) {
              return Promise.reject('Insira uma data válida');
            }
            return Promise.resolve();
          },
        }),
      ]}
      item={
        <Input mask="11/11/1111" />
      }
    />
  )
};