import React, { useEffect, useState, useContext } from 'react';
// Libraries
import { IdcardOutlined } from '@ant-design/icons';
// Contexts
import { LanguageContext } from '~/contexts/LanguageContext';
// Own Components
import PrintContent from './components/PrintContent';
// Components
import Button from '~/components/button';
import Drawer from '~/components/drawer';
// Standalone Components
import { Container } from './style';
// Utils
import { UserAvatar, UserName } from '~/utils/UserInformation';
import SocialMeAPI from '~/utils/SocialMeAPI';
import { rgMask, cpfMask, cnsMask, pisMask } from '~/utils/Masks';
import { DateInBrazilianFormat } from '~/utils/DateAndTime';

export default function SocialMeIdentity() {
  const { translate } = useContext(LanguageContext);
  const [loadingQRCode, setLoadingQRCode] = useState(true);
  const [qrCodeValue, setQRCodeValue] = useState('undefined');
  const [name] = useState(UserName());
  const [fontSizeName, setFontSizeName] = useState('12pt');
  const [person, setPerson] = useState([]);

  useEffect(() => {
    async function GetQRCode() {
      const params = {
        operation: ['QRCode', 'GetPersonQRCode'],
      };
      const response = await SocialMeAPI(params);
      if (response.success) {
        setQRCodeValue(response.body.QRCode._id);
      }
      return setLoadingQRCode(false);
    }
    GetQRCode();
  }, []);

  useEffect(() => {
    async function GetPerson() {
      const params = {
        operation: ['Profile', 'Get'],
      };
      const response = await SocialMeAPI(params);
      if (response.success) {
        //console.log('person -> ', response.body.person);
        setPerson(response.body.person);
      }
    }
    GetPerson();
  }, []);

  useEffect(() => {
    async function PrepareName() {
      if (name.length < 23) {
        return setFontSizeName('12pt');
      }
      if (name.length >= 24 && name.length <= 30) {
        return setFontSizeName('10pt');
      }
      if (name.length >= 31 && name.length <= 36) {
        return setFontSizeName('8pt');
      }
      if (name.length >= 37) {
        return setFontSizeName('6pt');
      }
    }
    PrepareName();
  }, [person, name]);

  function PrepareRG() {
    if (person) {
      let rg = person.rg_number;
      let rgBy = person.rg_dispatched_by;
      let rgUF = person.rg_dispatched_state;
      if (!rg) {
        return '---------------------------------';
      }
      if (rgBy.length > 10) {
        var str = rgBy.match(/\b(\w)/g).join('');
        var initials = str.replace(/[a-z]/g, '');
        rgBy = initials;
      }
      return `${rgMask(rg)} ${rgBy ? ` / ${rgBy}` : ''} ${
        rgUF ? ` - ${rgUF}` : ''
      }`;
    }
    return;
  }

  function PrepareCPF() {
    if (person) {
      let cpf = person.cpf_number;
      if (cpf) {
        return `${cpfMask(cpf)}`;
      }
      return '-----------------';
    }
    return;
  }

  function PrepareBirth() {
    if (person) {
      let birth = person.birth_certificate_date;
      if (birth) {
        return `${DateInBrazilianFormat(birth)}`;
      }
      return '-------------';
    }
    return;
  }

  function PrepareCNS() {
    if (person) {
      let cns = person.cns_number;
      if (cns) {
        return `${cnsMask(cns)}`;
      }
      return '---------------------';
    }
    return;
  }

  function PreparePIS() {
    if (person) {
      let pis = person.ctps_pis_pasep;
      if (pis) {
        return `${pisMask(pis)}`;
      }
      return '-----------------';
    }
    return;
  }

  return (
    <Container>
      <Drawer
        Trigger={
          <Button
            type="dashed"
            icon={<IdcardOutlined />}
            disabled={loadingQRCode}
          >
            {translate.accountManagement.printMeIdentity.text}
          </Button>
        }
        title="Me identifica"
      >
        <PrintContent
          avatar={UserAvatar()}
          qrCode={qrCodeValue}
          name={name}
          fontSizeName={fontSizeName}
          rg={PrepareRG()}
          cpf={PrepareCPF()}
          birth={PrepareBirth()}
          cns={PrepareCNS()}
          pis={PreparePIS()}
        />
      </Drawer>
    </Container>
  );
}
