import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  height: 60vh;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 6px;
    background-color: ${(props) => props.scrollBarColor};
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: ${(props) => props.scrollBarThumbColor};
  }
`;

export const Box = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px 10px 10px 0;
`;

export const GroupTextBox = styled.div`
  width: 80%;
  display: flex;
  margin-left: 10px;
  flex-direction: column;
  align-items: center;
`;

export const TextGroup = styled.div`
  width: 100%;
  padding: 3px 0;
  display: flex;
  justify-content: space-between;
`;

export const Circle = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  font-size: 12px;
  font-weight: 500;
  background-color: ${(props) => props.backgroundColor};
  color: white;
  text-align: center;
`;
