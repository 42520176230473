import React from 'react';
// Libraries
import { List, Avatar, Divider } from 'antd';
// Components
import Button from '~/components/button';
import Text from '~/components/typography/text';
// Services
import { GetDefaultAvatar } from '~/utils/SocialMeAssets';

export default function Select({ peoples, setStep, setPeople }) {
  async function SelectPerson(person) {
    await setPeople(person);
    await setStep('fill');
  }
  return (
    <div>
      {peoples.length > 0 ? (
        <>
          <Divider>Resultado da busca</Divider>
          <List
            itemLayout="horizontal"
            dataSource={peoples}
            renderItem={(item) => (
              <List.Item
                style={{ display: 'flex', alignItems: 'center' }}
                actions={[
                  <Button
                    text="Adicionar relacionamento"
                    type="primary"
                    onClick={() => SelectPerson(item)}
                  />,
                ]}
              >
                <List.Item.Meta
                  style={{ alignItems: 'center' }}
                  avatar={
                    <Avatar
                      src={item.avatar ? item.avatar : GetDefaultAvatar('other')}
                    />
                  }
                  title={item.social_name || item.name}
                />
              </List.Item>
            )}
          />
        </>
      ) : (
          <div
            style={{
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              flexDirection: 'column',
              display: 'flex',
              marginTop: 30,
            }}
          >
            <Text>Sua busca não retornou nenhum resultado</Text>
            <Button
              type="primary"
              onClick={() => setStep('search')}
              style={{ marginTop: 10 }}
            >
              Buscar novamente
          </Button>
          <Divider />
          <Button
              type="dashed"
              onClick={() => setStep('preRegister')}
            >
              Criar Pré-cadastro
          </Button>
          </div>
        )}
    </div>
  );
}
