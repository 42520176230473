import React from 'react';
//Own Components
import AddDocument from './components/AddDocument';
// Standalone Components
import { Container, ItemContainer } from './style';
// Components
import Space from '~/components/space';

export default function AddDocumentContainer({ missingDocuments, GetProfileAgain }) {
    console.log(missingDocuments)
    return (
        <Container>
            <Space>
                {missingDocuments.map(item => (
                    <ItemContainer key={item} >
                        <AddDocument documentToAdd={item} GetProfileAgain={GetProfileAgain} />
                    </ItemContainer>
                ))}
            </Space>
        </Container>
    )
}