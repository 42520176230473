import React, { memo } from 'react';
// Standalone Components
import { MainTimelineItem } from './style';

function AntTimelineItem({
  children,
  style,
  onClick,
  color,
  dot,
  label,
  position
}) {
  return (
    <MainTimelineItem
      style={style}
      onClick={onClick}
      color={color}
      dot={dot}
      label={label}
      position={position}
    >
      {children}
    </MainTimelineItem>
  );
}

export default memo(AntTimelineItem);
