import React from 'react';
// Libraries
import { Row, Col, Typography } from 'antd';
// Standalone components
import { Container } from './style';
// Components
import StoreButton from './components/StoreButton';

const { Title } = Typography;

export default function ContactHeader({ GetAddressesAgain, title }) {
  return (
    <Container>
      <Row>
        <Col
          style={{ display: 'flex', justifyContent: 'flex-start' }}
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
        >
          <Title level={4}>{title}</Title>
        </Col>
        <Col
          style={{ display: 'flex', justifyContent: 'flex-end' }}
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
        >
          <StoreButton GetAddressesAgain={GetAddressesAgain} />
        </Col>
      </Row>
    </Container>
  );
}
