import React from 'react';
// Components
import FormItem from '~/components/form/FormItem';
import Input from '~/components/input';
//Utils
import { ValidateDate } from '~/utils/Validations';

export default function CPFForm({ formValues, form }) {
  return (
    <div>
      <Number />
      <DispatchedAt />
      <DispatchedBy />
      <DispatchedIn />
    </div>
  );
}

const Number = () => {
  return (
    <FormItem
      label={"Número do RG"}
      name={['rg_number']}
      rules={[
        {
          required: true,
          message: 'Um número é obrigatório',
        },
        () => ({
          validator(rule, value) {
            if (value.includes('_')) {
              return Promise.reject('Insira um RG válido');
            }
            return Promise.resolve();
          },
        }),
      ]}
      item={
        <Input mask="111111-1" />
      }
    />
  )
}

const DispatchedAt = () => {
  return (
    <FormItem
      label="Data de expedição"
      name={['rg_dispatched']}
      rules={[
        () => ({
          validator(rule, value) {
            if (ValidateDate(value) === false) {
              return Promise.reject('Insira uma data válida');
            }
            return Promise.resolve();
          },
        }),
      ]}
      item={
        <Input mask="11/11/1111" />
      }
    />
  )
};

const DispatchedBy = () => {
  return (
    <FormItem
      label={"Órgão expedidor"}
      name={['rg_dispatched_by']}
      item={
        <Input placeholder="Digite a órgão expedidor" />
      }
    />
  )
}

const DispatchedIn = () => {
  return (
    <FormItem
      label={"UF"}
      name={['rg_dispatched_state']}
      item={
        <Input mask="AA" />
      }
    />
  )
}