import React, { memo } from 'react';

import Search from '~/components/search';
import Text from '~/components/typography/text';
import Avatar from '~/components/avatar';

import { UserAvatar, UserName } from '~/utils/UserInformation';

import { Container, PersonInfoBox } from './style';

function Header() {
  return (
    <Container>
      <PersonInfoBox>
        <Avatar style={{ marginRight: 5 }} src={UserAvatar()} size={48} />
        <Text>{UserName()}</Text>
      </PersonInfoBox>
      <Search />
    </Container>
  );
}

export default memo(Header);
