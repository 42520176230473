import React, { useContext, useState } from 'react';
// Contexts
import { AxiosContext } from '~/contexts/AxiosContext';
// Libraries
import { List, Avatar, message, Empty } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
// Components
import Button from '~/components/button';
import Text from '~/components/typography/text';
// Utils
import { GetDefaultAvatar } from '~/utils/SocialMeAssets';

export default function RequestsMade({
  requestsMade,
  GetRelantionshipsAgain,
}) {
  const { CallToTheAPI } = useContext(AxiosContext);

  const [loadingButton, setLoadingButton] = useState(false);

  async function CancelRequest(relationshipId) {
    setLoadingButton(true);
    const params = {
      operation: ['Profile', 'Relantionship', 'Delete'],
      urlParams: {
        relationshipId,
      },
    };
    const response = await CallToTheAPI(params);
    if (response.success) {
      message.success('Solicitação apagada com sucesso');
      GetRelantionshipsAgain();
    } else {
      message.error('Alguma coisa aconteceu');
    }
    return setLoadingButton(false);
  }

  function GetKinship(item) {
    let sex;
    if(item.linked_person_social_sex){
      sex = item.linked_person_social_sex;
    }else if(item.linked_person_sex){
      sex = item.linked_person_sex;
    }else{
      sex = undefined;
    }
    switch (sex) {
      case 'M':
        return item.kinship_male_name;
      case 'F':
        return item.kinship_female_name;
      default:
        return item.kinship_neutral_name;
    }
  }

  return (
    <>
      {requestsMade.length === 0 ? (
        <div>
          <Empty description={false} />
          <Text style={{ textAlign: "center" }}>Sem Solicitações</Text>
        </div>
      ) : (
        <>
          <Text strong>Aguardando Confirmação</Text>
          <List
            itemLayout="horizontal"
            dataSource={requestsMade}
            renderItem={(item) => (
              <List.Item
                actions={[
                  <Button
                    loading={loadingButton}
                    onClick={() => CancelRequest(item.id)}
                    text="Cancelar solicitação"
                    icon={<CloseOutlined />}
                  />,
                ]}
              >
                <List.Item.Meta
                  avatar={
                    <Avatar
                      src={item.linked_person_avatar || GetDefaultAvatar('other')}
                    />
                  }
                  title={item.linked_person_name}
                  description={GetKinship(item)}
                />
              </List.Item>
            )}
          />
        </>
      )}
    </>
  );
}
