import React, { memo, useContext } from 'react';

import { ThemeContext } from '~/contexts/ThemeContext';

import { Row, Col } from 'antd';

import MessageArea from './components/MessageArea';
import SideBar from './components/SideBar';

function Messager() {
  const { theme } = useContext(ThemeContext);

  return (
    <Row>
      <Col xl={8} lg={8} md={8} sm={24} xs={24}>
        <SideBar theme={theme} />
      </Col>
      <Col xl={16} lg={16} md={16} sm={0} xs={0}>
        <MessageArea theme={theme} />
      </Col>
    </Row>
  );
}

export default memo(Messager);
