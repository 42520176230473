import React, { useContext, memo } from 'react';
//Contexts
import { ResponsiveContext } from '~/contexts/ResponsiveContext';
// Libraries
import { Radio } from 'antd';

function AntRadioGroup({ defaultValue, onChange, disabled, name, options, value, optionType, buttonStyle }) {
  const { isMobile } = useContext(ResponsiveContext);
  return (
    <Radio.Group
      defaultValue={defaultValue}
      onChange={onChange}
      disabled={disabled}
      name={name}
      value={value}
      optionType={optionType}
      buttonStyle={buttonStyle}
      size={isMobile ? 'large' : 'default'}
    >
      {options.map(item => (
        <Radio key={item.value} style={{
          display: 'block',
          height: '30px',
          lineHeight: '30px',
        }} value={item.value}>
          {item.label}
        </Radio>
      ))}
    </Radio.Group>
  );
}

export default memo(AntRadioGroup);
