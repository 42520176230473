import React from 'react';

import Title from '~/components/typography/title';
import Card from '~/components/card';

import Notifications from './components/Notifications';
import GoToNotifications from './components/GoToNotifications';

import { Header } from './style';

export default function Notification({ theme }) {
  const cardStyle = {
    height: '70vh',
  };

  return (
    <Card style={cardStyle}>
      <Header>
        <Title level={4}>Notificações</Title>
      </Header>

      <Notifications theme={theme} />
      <GoToNotifications />
    </Card>
  );
}
