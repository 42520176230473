import React from 'react';
//Components
import Text from '~/components/typography/text';
//Style
import {
  Container,
  FirstRow,
  SecondRow,
  ImageTitleContainer,
  ActionContainer,
} from './style';
//Utils
import { GetDocumentIcon } from '~/utils/SocialMeAssets';
//Own Components
import EditButton from '../../components/EditButton';

export default function ViewCNPJDesktop({ name, person, GetProfileAgain }) {
  return (
    <Container>
      <FirstRow>
        <ImageTitleContainer>
          <img src={GetDocumentIcon(name)} alt="..." style={{ height: 45 }} />
          <Text strong>CNPJ (Cadastro Nacional da Pessoa Jurídica)</Text>
        </ImageTitleContainer>
        <ActionContainer>
          <EditButton
            title="Editando CNPJ (Cadastro Nacional da Pessoa Jurídica)"
            person={person}
            GetProfileAgain={GetProfileAgain}
          />
        </ActionContainer>
      </FirstRow>
      <SecondRow>
        <Text>Número do CNPJ: {person.cnpj_number}</Text>
      </SecondRow>
    </Container>
  );
}
