import API from '~/environments/APIs';

export default {
    GetPartnerBalance: {
        baseURL: API.socialMe,
        url: '/dw/account/partner/balance/detailed',
        method: 'post',
        timeout: '50000',
    },
};
