import styled from 'styled-components';
import { Timeline } from 'antd';

export const MainTimelineItem = styled(Timeline.Item)`
  width: ${(style) => style.width};
  height: ${(style) => style.height};
  margin: ${(style) => style.margin};
  margin-top: ${(style) => style.marginTop};
  margin-right: ${(style) => style.marginRight};
  margin-bottom: ${(style) => style.marginBottom};
  margin-left: ${(style) => style.marginLeft};
  background-color: ${(style) => style.backgroundcolor};
  color: ${(style) => style.color};
  border: ${(style) => style.border};
`;
