import styled from 'styled-components';

export const Container = styled.div`
  margin: 30px 0;

  &::-webkit-scrollbar {
    width: 6px;
    background-color: ${(props) => props.scrollBarColor};
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: ${(props) => props.scrollBarThumbColor};
  }
`;

export const Box = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;
`;

export const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  justify-content: space-between;
`;
