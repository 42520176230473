import React from 'react';
// Libraries
import { Route, Switch, useRouteMatch } from 'react-router-dom';
// Components
import Tabs from '~/components/tabs';
import PendingList from './components/PendingList';
import RequestsMade from './components/RequestsMade';
import RelationshipList from './components/RelationshipList';

export default function Content({
  myRelationships,
  pendingList,
  requestsMade,
  GetRelantionshipsAgain,
}) {
  const { path } = useRouteMatch();
  const tabsItems = [
    {
      title: 'Meus relacionamentos',
      tabPath: '/',
      badgeCount: 0,
    },
    {
      title: 'Solicitações recebidas',
      tabPath: '/solicitacoes-recebidas',
      badgeCount: pendingList.length,
    },
    {
      title: 'Solicitações pendentes',
      tabPath: '/solicitacoes-realizadas',
      badgeCount: requestsMade.length,
    },
  ];
  return (
    <div>
      <Tabs tabsItems={tabsItems} />
      <Switch>
        <Route
          exact
          path={path}
          component={() => (
            <RelationshipList
              myRelationships={myRelationships}
              GetRelantionshipsAgain={GetRelantionshipsAgain}
            />
          )}
        />
        <Route
          path={`${path}/solicitacoes-recebidas`}
          component={() => (
            <PendingList
              pendingList={pendingList}
              GetRelantionshipsAgain={GetRelantionshipsAgain}
            />
          )}
        />
        <Route
          path={`${path}/solicitacoes-realizadas`}
          component={() => (
            <RequestsMade
              requestsMade={requestsMade}
              GetRelantionshipsAgain={GetRelantionshipsAgain}
            />
          )}
        />
      </Switch>
    </div>
  );
}
