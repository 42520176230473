import React, { useContext } from 'react';
//Libraries
import { BsGear } from 'react-icons/bs';
import { FiCalendar, FiMapPin, FiFolder, FiPhone, FiUser } from 'react-icons/fi';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Layout } from 'antd';
// Contexts
import { ThemeContext } from '~/contexts/ThemeContext';
// import { LanguageContext } from '~/contexts/LanguageContext';
// Components
import Sidebar from '~/components/sidebar';
import MainNavbar from '~/components/navbar/Main';
// Pages
import BasicInformation from './pages/BasicInformation';
import Documents from './pages/Documents';
import Contact from './pages/Contact';
import Timeline from './pages/Timeline';
import Config from './pages/Config';
import Addresses from './pages/Addresses';

const { Content } = Layout;

export default function Profile() {
  const { theme } = useContext(ThemeContext);
  // const { translate } = useContext(LanguageContext);

  const sidebarItems = [
    {
      index: 0,
      title: 'First',
    },
    {
      index: 1,
      title: 'Informações Básicas',
      url: '/informacoes-basicas',
      icon: FiUser,
    },
    {
      index: 2,
      title: 'Documentos',
      url: '/documentos',
      icon: FiFolder,
    },
    {
      index: 3,
      title: 'Contatos',
      url: '/contatos',
      icon: FiPhone,
    },
    {
      index: 4,
      title: 'Locais',
      url: '/locais',
      icon: FiMapPin,
    },
    {
      index: 5,
      title: 'Linha do Tempo',
      url: '/linha-do-tempo',
      icon: FiCalendar,
    },
    {
      index: 6,
      title: 'Last',
    },
    {
      index: 7,
      title: 'Configurações',
      url: '/configuracoes',
      icon: BsGear,
    },
    {
      index: 8,
      title: 'Last',
    },
  ];

  return (
    <div>
      <Layout
        style={{
          backgroundColor: theme.color.backgroundPrimary,
          height: '100vh',
        }}
      >
        <Sidebar items={sidebarItems} />
        <Layout
          style={{
            padding: 10,
            backgroundColor: theme.color.backgroundSecondary,
          }}
        >
          <MainNavbar />
          <Content
            style={{
              overflow: 'auto',
            }}
          >
            <Switch> 
              <Route exact path={`/`}>
                <Redirect to="/informacoes-basicas" />
              </Route>
              <Route
                path={`/informacoes-basicas`}
                component={BasicInformation}
              />
              <Route path={`/locais`} component={Addresses} />
              <Route path={`/documentos`} component={Documents} />
              <Route path={`/contatos`} component={Contact} />
              <Route path={`/linha-do-tempo`} component={Timeline} />
              <Route path={`/configuracoes`} component={Config} />
              <Route exact path={`*`}>
                <Redirect to="/informacoes-basicas" />
              </Route>
            </Switch>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
}
