import React, { memo } from 'react';
// Standalone Components
import { MainTimeline } from './style';

function AntTimeline({ 
  children,
  style,
  onClick,
  mode,
  pending,
  pendingDot,
  reverse
}) {
  return (
    <MainTimeline
      style={style}
      onClick={onClick}
      mode={mode}
      pending={pending}
      pendingDot={pendingDot}
      reverse={reverse}
    >
      {children}
    </MainTimeline>
  );
}

export default memo(AntTimeline);
