import React, { memo } from 'react';

import { message } from 'antd';

import { HiOutlineEmojiHappy, HiOutlineMicrophone } from 'react-icons/hi';
import { AiOutlinePaperClip } from 'react-icons/ai';

import Input from '~/components/input';

import { Container, Div } from './style';

function Footer({ text, setText }) {
  const iconStyle = {
    fontSize: 30,
  };

  function SendMessage(text) {
    message.success(`Mensagem enviada: ${text}`, 2);
    setText('');
  }

  return (
    <Container>
      <HiOutlineEmojiHappy style={iconStyle} />
      <AiOutlinePaperClip style={{ ...iconStyle, margin: '0 10px' }} />
      <Div>
        <Input
          placeholder="Digite uma mensagem"
          value={text}
          onKeyUp={(event) => {
            if (event.key === 'Enter') {
              SendMessage(event.target.value);
            }
          }}
          onChange={(event) => setText(event.target.value)}
        />
      </Div>
      <HiOutlineMicrophone style={{ ...iconStyle, marginLeft: 10 }} />
    </Container>
  );
}

export default memo(Footer);
