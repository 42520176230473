import React from 'react';
//Libraries
import { Space } from 'antd';
//Components
import FormItem from '~/components/form/FormItem';
import Input from '~/components/input';
import TextArea from '~/components/textArea';
import Select from '~/components/select';

export default function AddForm() {
  const ExtNationalOptions = [
    {
      value: '1',
      label: 'Ext National 1',
    },
    {
      value: '2',
      label: 'Ext National 2',
    },
  ];

  return (
    <div>
      <FormItem
        label="Nome"
        name={['name']}
        rules={[
          {
            required: true,
            message: 'Um nome é obrigatório',
          },
        ]}
        item={<Input />}
      />
      <FormItem
        label="Descrição Curta"
        name={['short_description']}
        item={<TextArea autoSize={{ minRows: 2, maxRows: 2 }} />}
      />
      <FormItem
        label="Descrição Completa"
        name={['description']}
        item={<TextArea autoSize={{ minRows: 3, maxRows: 6 }} />}
      />

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div>
          <FormItem
            label="EXT Nacional"
            name={['ext_national_id']}
            item={<Select options={ExtNationalOptions} />}
          />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Space>
            <FormItem label="Plus Code" name={['plus_code']} item={<Input />} />
            <FormItem label="OSM Code" name={['osm_code']} item={<Input />} />
          </Space>
        </div>
      </div>

      <FormItem label="Avatar" name={['avatar']} item={<Input />} />
    </div>
  );
}
