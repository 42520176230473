import styled from 'styled-components';

import { BiSearchAlt2 } from 'react-icons/bi';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const PersonInfoBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

export const SearchIcon = styled(BiSearchAlt2)`
  font-size: 30px;
  cursor: pointer;
`;
