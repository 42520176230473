import React from 'react';
//Libraries
import { Space } from 'antd';
//Views
import ViewBirthCertificate from './components/ViewBirthCertificate';
import ViewCNH from './components/ViewCNH';
import ViewCNS from './components/ViewCNS';
import ViewCPF from './components/ViewCPF';
import ViewCTPS from './components/ViewCTPS';
import ViewPassport from './components/ViewPassport';
import ViewRG from './components/ViewRG';
import ViewVoterCertificate from './components/ViewVoterCertificate';

export default function OwnedDocumentsContainer({ ownedDocuments, person, GetProfileAgain }) {

    function RenderView(name) {
        switch (name) {
            case 'BirthCertificate':
                return <ViewBirthCertificate name={name} person={person} GetProfileAgain={GetProfileAgain} />
            case 'CNH':
                return <ViewCNH name={name} person={person} GetProfileAgain={GetProfileAgain} />
            case 'CNS':
                return <ViewCNS name={name} person={person} GetProfileAgain={GetProfileAgain} />
            case 'CPF':
                return <ViewCPF name={name} person={person} GetProfileAgain={GetProfileAgain} />
            case 'CTPS':
                return <ViewCTPS name={name} person={person} GetProfileAgain={GetProfileAgain} />
            case 'Passport':
                return <ViewPassport name={name} person={person} GetProfileAgain={GetProfileAgain} />
            case 'RG':
                return <ViewRG name={name} person={person} GetProfileAgain={GetProfileAgain} />
            case 'VoterCertificate':
                return <ViewVoterCertificate name={name} person={person} GetProfileAgain={GetProfileAgain} />
            default:
                return 'null'
        }
    }

    return (
        <div>
            <Space style={{ width: '100%' }} direction={'vertical'}>
                {ownedDocuments.map(item =>
                    <div key={item}>
                        {RenderView(item)}
                    </div>
                )}
            </Space>
        </div>
    )
}