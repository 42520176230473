import React, { useEffect, useState, useContext } from 'react';
// Libraries
import { Avatar, Divider, Form, Typography } from 'antd';
// Components
import FormItem from '~/components/form/FormItem';
import Select from '~/components/select';
// Services
import { GetDefaultAvatar } from '~/utils/SocialMeAssets';
// Contexts
import { AxiosContext } from '~/contexts/AxiosContext';
import { AuthContext } from '~/contexts/AuthContext';

const { Text } = Typography;

export default function Fill({ people, form }) {
  // Contexts
  const { CallToTheAPI } = useContext(AxiosContext);
  const { user } = useContext(AuthContext);
  // Variables
  const [kinships, setKinships] = useState([]);

  useEffect(() => {
    async function GetKinships() {
      const params = {
        operation: ['System', 'Kinships', 'Get'],
      };
      const response = await CallToTheAPI(params);
      if (response.success) {
        const array = [];
        for (let i = 0; i < response.body.kinshipList.elements.length; i++) {
          const newItem = {
            value: response.body.kinshipList.elements[i].id,
            label: response.body.kinshipList.elements[i].name,
          };
          array.push(newItem);
        }
        return setKinships(array);
      }
    }
    GetKinships();
  }, [CallToTheAPI, user]);

  return (
    <div>
      <Divider>Tipo de relacionamento</Divider>
      <Form
        labelCol={{ xs: { span: 24 }, sm: { span: 24 } }}
        wrapperCol={{ xs: { span: 24 }, sm: { span: 24 } }}
        form={form}
        size="small"
        layout="vertical"
        scrollToFirstError
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <Avatar
            size={64}
            src={people.avatar ? people.avatar : GetDefaultAvatar('other')}
          />
          <Text strong>{people.name}</Text>

          <FormItem
            name={['kinship']}
            item={
              <Select
                options={kinships}
                loading={kinships ? false : true}
                style={{ width: '200px' }}
                noDefaultValue
                placeholder="Selecione"
              />
            }
          />
        </div>
      </Form>
    </div>
  );
}
