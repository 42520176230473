import React, { useState, useContext } from 'react';
// Contexts
import { AxiosContext } from '~/contexts/AxiosContext';
import { ResponsiveContext } from '~/contexts/ResponsiveContext';
// Libraries
import { Drawer, message, Form } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
// Components
import Button from '~/components/button';
import Loading from '~/components/loading';
// Utils
import { AmericanDateParser } from '~/utils/Parsers';
import { RemoveWhatIsNotNumber } from '~/utils/Transformations';
// Steps
import SearchPerson from './steps/SearchPerson';
import Select from './steps/Select';
import Fill from './steps/Fill';
import PreRegistration from './steps/PreRegistration';

export default function ContactStoreButton({ GetRelantionshipsAgain }) {
  const { CallToTheAPI } = useContext(AxiosContext);
  const { isMobile } = useContext(ResponsiveContext);

  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [load, setLoad] = useState(false);
  const [step, setStep] = useState('search');
  const [searchForm] = Form.useForm();
  const [linkForm] = Form.useForm();
  const [preRegisterForm] = Form.useForm();
  const [peoples, setPeoples] = useState();
  const [people, setPeople] = useState();

  const ShowDrawer = () => {
    setVisibleDrawer(true);
  };
  const CloseDrawer = () => {
    setVisibleDrawer(false);
  };
  async function Search(data) {
    setLoad(true);
    const params = {
      operation: ['System', 'Person', 'Search'],
      data,
    };
    const response = await CallToTheAPI(params);
    if (response.success) {
      setPeoples(response.body.peopleList.elements);
      setStep('select');
    }
    return setLoad(false);
  }
  const onFinishSearch = (data) => {
    if (data.filter.birth_certificate_date) {
      data.filter.birth_certificate_date = AmericanDateParser(
        data.filter.birth_certificate_date
      );
    }
    if (data.filter.cpf_number) {
      data.filter.cpf_number = RemoveWhatIsNotNumber(data.filter.cpf_number);
    }
    if (data.filter.cns_number) {
      data.filter.cns_number = RemoveWhatIsNotNumber(data.filter.cns_number);
    }
    if (data.filter.ctps_pis_pasep) {
      data.filter.ctps_pis_pasep = RemoveWhatIsNotNumber(
        data.filter.ctps_pis_pasep
      );
    }
    const dataTreated = {
      match: 2,
      filter: data.filter,
    };
    Search(dataTreated);
  };
  async function onFinishFill(data) {
    setLoad(true);
    const dataTreated = {
      person: people.id,
      kinship: data.kinship,
    };
    const params = {
      operation: ['Profile', 'Relantionship', 'Add'],
      data: dataTreated,
    };
    const response = await CallToTheAPI(params);
    if (response.success) {
      message.success('Solicitação enviada!');
      CloseDrawer();
      setStep('search');
      searchForm.resetFields();
      linkForm.resetFields();
      setLoad(false);
      return GetRelantionshipsAgain();
    }
    message.error('Alguma coisa deu errada!');
    return setLoad(false);
  }
  async function AddPreRegistration(data) {
    setLoad(true);
    const params = {
      operation: ['Auth', 'Register', 'PreRegistrationPhysical'],
      data,
    };
    const response = await CallToTheAPI(params);
    if (response.success) {
      setPeople(response.body.newPerson);
      setStep('fill');
    }
    return setLoad(false);
  }
  async function onFinishPreRegistration(data) {
    data.kind = 'PF';
    data.birth_certificate_date = AmericanDateParser(
      data.birth_certificate_date
    );
    data.cpf_number = data.cpf_number.replace(/[^\d]+/g, '');
    AddPreRegistration(data);
  }
  const RenderButton = () => {
    switch (step) {
      case 'search':
        return (
          <Button
            loading={load}
            disabled={load}
            type="primary"
            onClick={() => {
              searchForm.validateFields().then((values) => {
                onFinishSearch(values);
              });
            }}
          >
            Buscar
          </Button>
        );
      case 'select':
        return (
          <Button onClick={() => setStep('search')}>Pesquisar novamente</Button>
        );
      case 'fill':
        return (
          <>
            <Button
              style={{ marginRight: 8 }}
              onClick={() => setStep('select')}
            >
              Voltar
            </Button>
            <Button
              loading={load}
              disabled={load}
              type="primary"
              onClick={() => {
                linkForm.validateFields().then((values) => {
                  onFinishFill(values);
                });
              }}
            >
              Adicionar
            </Button>
          </>
        );
      case 'preRegister':
        return (
          <Button
            loading={load}
            disabled={load}
            type="primary"
            onClick={() => {
              preRegisterForm.validateFields().then((values) => {
                onFinishPreRegistration(values);
              });
            }}
          >
            Criar pré-registro
          </Button>
        );
      default:
        return null;
    }
  };
  const RenderStep = () => {
    switch (step) {
      case 'search':
        return <SearchPerson form={searchForm} />;
      case 'select':
        return (
          <Select setStep={setStep} peoples={peoples} setPeople={setPeople} />
        );
      case 'fill':
        return <Fill people={people} form={linkForm} />;
      case 'preRegister':
        return <PreRegistration form={preRegisterForm} />;
      default:
        return null;
    }
  };
  return (
    <>
      <Button
        text="Novo relacionamento"
        onClick={() => ShowDrawer()}
        type="primary"
        icon={<PlusOutlined />}
      />

      <Drawer
        title="Novo Relacionamento"
        width={isMobile ? '100%' : 600}
        onClose={CloseDrawer}
        visible={visibleDrawer}
        bodyStyle={{ padding: 20 }}
        footer={
          <div
            style={{
              textAlign: 'right',
            }}
          >
            <Button
              text="Cancelar"
              onClick={CloseDrawer}
              style={{ marginRight: 8 }}
            />

            {RenderButton()}
          </div>
        }
      >
        {load ? <Loading /> : RenderStep()}
      </Drawer>
    </>
  );
}
