import React, { useEffect, useState, useContext } from 'react';
// Libraries
//import { message } from 'antd';
import QRCode from 'qrcode.react';
// Contexts
import { LanguageContext } from '~/contexts/LanguageContext';
// Standalone Componentes
import { Container, ContainerError, TextError, ContainerQRCode } from './style';
// Components
//import Button from '~/components/button';
import Loading from '~/components/loading';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';
import { GetBrandIcon } from '~/utils/SocialMeAssets';

export default function UserCard({ person, GetProfileAgain }) {
  const { translate } = useContext(LanguageContext);
  const [loadingQRCode, setLoadingQRCode] = useState(true);
  const [qrCodeValue, setQRCodeValue] = useState('undefined');
  const [qrCodeError, setQRCodeError] = useState(false);

  useEffect(() => {
    async function GetQRCode() {
      const params = {
        operation: ['QRCode', 'GetPersonQRCode'],
      };
      const response = await SocialMeAPI(params);
      if (response.success) {
        setQRCodeValue(response.body.QRCode._id);
      } else {
        setQRCodeError(true);
      }
      return setLoadingQRCode(false);
    }
    GetQRCode();
  }, []);

  /*
  async function ChangeQRCode() {
    const params = {
      operation: ['QRCode', 'GetPersonQRCode'],
      data: {
        forceNew: false,
      },
    };
    const response = await SocialMeAPI(params);
    if (response.success) {
      message.success(translate.messages.QRCodeSuccessfullyChanged.text);
      setQRCodeValue(response.body.QRCode._id);
    } else {
      message.error(translate.messages.somethingHappened.text);
      setQRCodeError(true);
    }
    return;
  }
  */

  if (loadingQRCode) {
    return (
      <Loading height={'200px'} />
    );
  }

  return (
    <Container>
      {qrCodeError ? (
        <ContainerError>
          <TextError>{translate.messages.somethingHappened.text}</TextError>
        </ContainerError>
      ) : (
          <ContainerQRCode>
            <QRCode
              value={qrCodeValue}
              //value={'https://natalfeliz.doacoes.me'}
              size={200}
              bgColor="#ffffff"
              fgColor="#303030"
              level="L"
              includeMargin={false}
              renderAs="svg"
              imageSettings={{
                src: GetBrandIcon('socialMe_black64x64'),
                x: null,
                y: null,
                height: 30,
                width: 30,
                excavate: false,
              }}
            />
            {/* 
            <Button style={{ marginTop: 15 }} onClick={ChangeQRCode}>
              {translate.accountManagement.updateQRCode.text}
            </Button>
            */}
          </ContainerQRCode>
        )}
    </Container>
  );
}
