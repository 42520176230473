export const Languages = [
  { value: 'ptBR', label: 'Português' },
  { value: 'es', label: 'Español' },
];

export const Sex = [
  { value: 'M', label: 'Masculino' },
  { value: 'F', label: 'Feminino' },
  { value: 'N', label: 'Neutro' },
];

export const CivilState = [
  { value: 'S', label: 'SOLTEIRO(A)' },
  { value: 'C', label: 'CASADO(A)' },
  { value: 'V', label: 'VIÚVO(A)' },
  { value: 'SJ', label: 'SEPARADO(A) JUDICIALMENTE' },
  { value: 'D', label: 'DIVORCIADO(A)' },
];

export const Frequency = [
  { value: 'DIÁRIO', label: 'DIÁRIO' },
  { value: 'SEMANAL', label: 'SEMANAL' },
  { value: 'QUINZENAL', label: 'QUINZENAL' },
  { value: 'MENSAL', label: 'MENSAL' },
  { value: 'BIMESTRAL', label: 'BIMESTRAL' },
  { value: 'TRIMESTRAL', label: 'TRIMESTRAL' },
  { value: 'SEMESTRAL', label: 'SEMESTRAL' },
  { value: 'ANUAL', label: 'ANUAL' },
];

export const Genre = [
  { label: 'CIS', value: 'CIS' },
  { label: 'MULHER CIS', value: 'MULHER CIS' },
  { label: 'CIS FEMININO', value: 'CIS FEMININO' },
  { label: 'CIS MASCULINO', value: 'CIS MASCULINO' },
  { label: 'CISGÊNERO', value: 'CISGÊNERO' },
  { label: 'MULHER CISGÊNERO', value: 'MULHER CISGÊNERO' },
  { label: 'HOMEM CISGÊNERO', value: 'HOMEM CISGÊNERO' },
  { label: 'CISGÊNERO FEMININO', value: 'CISGÊNERO FEMININO' },
  { label: 'CISGÊNERO MASCULINO', value: 'CISGÊNERO MASCULINO' },
  { label: 'TRANS FEMININO', value: 'TRANS FEMININO' },
  { label: 'TRANS MASCULINO', value: 'TRANS MASCULINO' },
  { label: 'HOMEM TRANS', value: 'HOMEM TRANS' },
  { label: 'MULHER TRANS', value: 'MULHER TRANS' },
  { label: 'PESSOA TRANS', value: 'PESSOA TRANS' },
  { label: 'TRANSEXUAL', value: 'TRANSEXUAL' },
  { label: 'MULHER TRANSEXUAL', value: 'MULHER TRANSEXUAL' },
  { label: 'TRANSEXUAL FEMININO', value: 'TRANSEXUAL FEMININO' },
  { label: 'TRANSEXUAL MASCULINO', value: 'TRANSEXUAL MASCULINO' },
  { label: 'TRANSGÊNERO FEMININO', value: 'TRANSGÊNERO FEMININO' },
  { label: 'PESSOA TRANSGÊNERO', value: 'PESSOA TRANSGÊNERO' },
  { label: 'TRANS* FEMININO', value: 'TRANS* FEMININO' },
  { label: 'TRANS* MASCULINO', value: 'TRANS* MASCULINO' },
  { label: 'HOMEM TRANS*', value: 'HOMEM TRANS*' },
  { label: 'MULHER TRANS*', value: 'MULHER TRANS*' },
  { label: 'PESSOA TRANS*', value: 'PESSOA TRANS*' },
  { label: 'HOMEM PARA MULHER', value: 'HOMEM PARA MULHER' },
  { label: 'MULHER PARA HOMEM', value: 'MULHER PARA HOMEM' },
  { label: 'HPM', value: 'HPM' },
  { label: 'Other', value: 'OTHER' },
  { label: 'NEUTRO', value: 'NEUTRO' },
  { label: 'NÃO BINÁRIO', value: 'NÃO BINÁRIO' },
  { label: 'INCONFORMISMO DE GÊNERO', value: 'INCONFORMISMO DE GÊNERO' },
  { label: 'VARIAÇÃO DE GÊNERO', value: 'VARIAÇÃO DE GÊNERO' },
  { label: 'GENDERQUEER', value: 'GENDERQUEER' },
  { label: 'PANGÊNERO', value: 'PANGÊNERO' },
  { label: 'QUESTIONAMENTO DE GÊNERO', value: 'QUESTIONAMENTO DE GÊNERO' },
  { label: 'BISGÊNERO', value: 'BISGÊNERO' },
  { label: 'INTERSEXO', value: 'INTERSEXO' },
  { label: 'ANDRÓGINO', value: 'ANDRÓGINO' },
  { label: 'GÊNERO FLUIDO', value: 'GÊNERO FLUIDO' },
  { label: 'DOIS ESPÍRITOS', value: 'DOIS ESPÍRITOS' },
];

export const Gender = [
  { label: 'CIS', value: 'CIS' },
  { label: 'MULHER CIS', value: 'MULHER CIS' },
  { label: 'CIS FEMININO', value: 'CIS FEMININO' },
  { label: 'CIS MASCULINO', value: 'CIS MASCULINO' },
  { label: 'CISGÊNERO', value: 'CISGÊNERO' },
  { label: 'MULHER CISGÊNERO', value: 'MULHER CISGÊNERO' },
  { label: 'HOMEM CISGÊNERO', value: 'HOMEM CISGÊNERO' },
  { label: 'CISGÊNERO FEMININO', value: 'CISGÊNERO FEMININO' },
  { label: 'CISGÊNERO MASCULINO', value: 'CISGÊNERO MASCULINO' },
  { label: 'TRANS FEMININO', value: 'TRANS FEMININO' },
  { label: 'TRANS MASCULINO', value: 'TRANS MASCULINO' },
  { label: 'HOMEM TRANS', value: 'HOMEM TRANS' },
  { label: 'MULHER TRANS', value: 'MULHER TRANS' },
  { label: 'PESSOA TRANS', value: 'PESSOA TRANS' },
  { label: 'TRANSEXUAL', value: 'TRANSEXUAL' },
  { label: 'MULHER TRANSEXUAL', value: 'MULHER TRANSEXUAL' },
  { label: 'TRANSEXUAL FEMININO', value: 'TRANSEXUAL FEMININO' },
  { label: 'TRANSEXUAL MASCULINO', value: 'TRANSEXUAL MASCULINO' },
  { label: 'TRANSGÊNERO FEMININO', value: 'TRANSGÊNERO FEMININO' },
  { label: 'PESSOA TRANSGÊNERO', value: 'PESSOA TRANSGÊNERO' },
  { label: 'TRANS* FEMININO', value: 'TRANS* FEMININO' },
  { label: 'TRANS* MASCULINO', value: 'TRANS* MASCULINO' },
  { label: 'HOMEM TRANS*', value: 'HOMEM TRANS*' },
  { label: 'MULHER TRANS*', value: 'MULHER TRANS*' },
  { label: 'PESSOA TRANS*', value: 'PESSOA TRANS*' },
  { label: 'HOMEM PARA MULHER', value: 'HOMEM PARA MULHER' },
  { label: 'MULHER PARA HOMEM', value: 'MULHER PARA HOMEM' },
  { label: 'HPM', value: 'HPM' },
  { label: 'Other', value: 'OTHER' },
  { label: 'NEUTRO', value: 'NEUTRO' },
  { label: 'NÃO BINÁRIO', value: 'NÃO BINÁRIO' },
  { label: 'INCONFORMISMO DE GÊNERO', value: 'INCONFORMISMO DE GÊNERO' },
  { label: 'VARIAÇÃO DE GÊNERO', value: 'VARIAÇÃO DE GÊNERO' },
  { label: 'GENDERQUEER', value: 'GENDERQUEER' },
  { label: 'PANGÊNERO', value: 'PANGÊNERO' },
  { label: 'QUESTIONAMENTO DE GÊNERO', value: 'QUESTIONAMENTO DE GÊNERO' },
  { label: 'BISGÊNERO', value: 'BISGÊNERO' },
  { label: 'INTERSEXO', value: 'INTERSEXO' },
  { label: 'ANDRÓGINO', value: 'ANDRÓGINO' },
  { label: 'GÊNERO FLUIDO', value: 'GÊNERO FLUIDO' },
  { label: 'DOIS ESPÍRITOS', value: 'DOIS ESPÍRITOS' },
];

export const Schooling = [
  { label: 'ANALFABETO', value: 'ANALFABETO' },
  {
    label: 'ENSINO FUNDAMENTAL INCOMPLETO',
    value: 'ENSINO FUNDAMENTAL INCOMPLETO',
  },
  {
    label: 'ENSINO FUNDAMENTAL COMPLETO',
    value: 'ENSINO FUNDAMENTAL COMPLETO',
  },
  { label: 'ENSINO MÉDIO INCOMPLETO', value: 'ENSINO MÉDIO INCOMPLETO' },
  { label: 'ENSINO MÉDIO COMPLETO', value: 'ENSINO MÉDIO COMPLETO' },
  { label: 'ENSINO SUPERIOR INCOMPLETO', value: 'ENSINO SUPERIOR INCOMPLETO' },
  { label: 'ENSINO SUPERIOR COMPLETO', value: 'ENSINO SUPERIOR COMPLETO' },
  { label: 'PÓS-GRADUAÇÃO', value: 'PÓS-GRADUAÇÃO' },
  { label: 'MESTRADO', value: 'MESTRADO' },
  { label: 'DOUTORADO', value: 'DOUTORADO' },
  { label: 'PÓS-DOUTORADO', value: 'PÓS-DOUTORADO' },
];

export const Relationships = [
  { value: 'Pai', label: 'Pai' },
  { value: 'Mãe', label: 'Mãe' },
  { value: 'Irmão', label: 'Irmão' },
  { value: 'Irmã', label: 'Irmã' },
  { value: 'Filho', label: 'Filho' },
  { value: 'Filha', label: 'Filha' },
  { value: 'Avô', label: 'Avô' },
  { value: 'Avó', label: 'Avó' },
  { value: 'Tio', label: 'Tio' },
  { value: 'Tia', label: 'Tia' },
  { value: 'Primo', label: 'Primo' },
  { value: 'Prima', label: 'Prima' },
];

export const Countries = [
  { value: 'País1', label: 'País1' },
  { value: 'País2', label: 'País2' },
  { value: 'País3', label: 'País3' },
  { value: 'País4', label: 'País4' },
  { value: 'País5', label: 'País5' },
  { value: 'País6', label: 'País6' },
];

export const States = [
  { value: 'Estado1', label: 'Estado1' },
  { value: 'Estado2', label: 'Estado2' },
  { value: 'Estado3', label: 'Estado3' },
  { value: 'Estado4', label: 'Estado4' },
  { value: 'Estado5', label: 'Estado5' },
  { value: 'Estado6', label: 'Estado6' },
];

export const Cities = [
  { value: 'Cidade1', label: 'Cidade1' },
  { value: 'Cidade2', label: 'Cidade2' },
  { value: 'Cidade3', label: 'Cidade3' },
  { value: 'Cidade4', label: 'Cidade4' },
  { value: 'Cidade5', label: 'Cidade5' },
  { value: 'Cidade6', label: 'Cidade6' },
];

export const Documents = [
  { value: 'CNH', label: 'CNH' },
  { value: 'CNS', label: 'CNS' },
  { value: 'Passport', label: 'Passaporte' },
  { value: 'RG', label: 'RG' },
  { value: 'voterCertificate', label: 'Título de Eleitor' },
];

export const CNHCategories = [
  { value: 'A', label: 'Categoria A' },
  { value: 'B', label: 'Categoria B' },
  { value: 'C', label: 'Categoria C' },
  { value: 'D', label: 'Categoria D' },
  { value: 'E', label: 'Categoria E' },
];

export const Cep = [
  {
    value: 'Brasil',
    label: 'Brasil',
    children: [
      {
        value: 'Acre',
        label: 'Acre',
        children: [
          {
            value: 'Acre1',
            label: 'Acre1',
          },
        ],
      },
      {
        value: 'Alagoas',
        label: 'Alagoas',
        children: [
          {
            value: 'Alagoas1',
            label: 'Alagoas1',
          },
        ],
      },
      {
        value: 'Amapá',
        label: 'Amapá',
        children: [
          {
            value: 'Amapá1',
            label: 'Amapá1',
          },
        ],
      },
      {
        value: 'Amazonas',
        label: 'Amazonas',
        children: [
          {
            value: 'Amazonas1',
            label: 'Amazonas1',
          },
        ],
      },
      {
        value: 'Bahia',
        label: 'Bahia',
        children: [
          {
            value: 'Bahia1',
            label: 'Bahia1',
          },
        ],
      },
      {
        value: 'Ceará',
        label: 'Ceará',
        children: [
          {
            value: 'Boa Vista',
            label: 'Boa Vista',
          },
        ],
      },
      {
        value: 'Distrito Federal',
        label: 'Distrito Federal',
        children: [
          {
            value: 'Boa Vista',
            label: 'Boa Vista',
          },
        ],
      },
      {
        value: 'Espírito Santo',
        label: 'Espírito Santo',
        children: [
          {
            value: 'Boa Vista',
            label: 'Boa Vista',
          },
        ],
      },
      {
        value: 'Goiás',
        label: 'Goiás',
        children: [
          {
            value: 'Boa Vista',
            label: 'Boa Vista',
          },
        ],
      },
      {
        value: 'Maranhão',
        label: 'Maranhão',
        children: [
          {
            value: 'Boa Vista',
            label: 'Boa Vista',
          },
        ],
      },
      {
        value: 'Mato Grosso',
        label: 'Mato Grosso',
        children: [
          {
            value: 'Boa Vista',
            label: 'Boa Vista',
          },
        ],
      },
      {
        value: 'Mato Grosso do Sul',
        label: 'Mato Grosso do Sul',
        children: [
          {
            value: 'Boa Vista',
            label: 'Boa Vista',
          },
        ],
      },
      {
        value: 'Minas Gerais',
        label: 'Minas Gerais',
        children: [
          {
            value: 'Boa Vista',
            label: 'Boa Vista',
          },
        ],
      },
      {
        value: 'Pará',
        label: 'Pará',
        children: [
          {
            value: 'Boa Vista',
            label: 'Boa Vista',
          },
        ],
      },
      {
        value: 'Paraíba',
        label: 'Paraíba',
        children: [
          {
            value: 'Boa Vista',
            label: 'Boa Vista',
          },
        ],
      },
      {
        value: 'Paraná',
        label: 'Paraná',
        children: [
          {
            value: 'Boa Vista',
            label: 'Boa Vista',
          },
        ],
      },
      {
        value: 'Pernambuco',
        label: 'Pernambuco',
        children: [
          {
            value: 'Boa Vista',
            label: 'Boa Vista',
          },
        ],
      },
      {
        value: 'Piauí',
        label: 'Piauí',
        children: [
          {
            value: 'Boa Vista',
            label: 'Boa Vista',
          },
        ],
      },
      {
        value: 'Rio de Janeiro',
        label: 'Rio de Janeiro',
        children: [
          {
            value: 'Boa Vista',
            label: 'Boa Vista',
          },
        ],
      },
      {
        value: 'Rio Grande do Norte',
        label: 'Rio Grande do Norte',
        children: [
          {
            value: 'Boa Vista',
            label: 'Boa Vista',
          },
        ],
      },
      {
        value: 'Rio Grande do Sul',
        label: 'Rio Grande do Sul',
        children: [
          {
            value: 'Boa Vista',
            label: 'Boa Vista',
          },
        ],
      },
      {
        value: 'Rondônia',
        label: 'Rondônia',
        children: [
          {
            value: 'Boa Vista',
            label: 'Boa Vista',
          },
        ],
      },
      {
        value: 'Roraima',
        label: 'Roraima',
        children: [
          {
            value: 'Boa Vista',
            label: 'Boa Vista',
          },
        ],
      },
      {
        value: 'Santa Catarina',
        label: 'Santa Catarina',
        children: [
          {
            value: 'Boa Vista',
            label: 'Boa Vista',
          },
        ],
      },
      {
        value: 'São Paulo',
        label: 'São Paulo',
        children: [
          {
            value: 'Boa Vista',
            label: 'Boa Vista',
          },
        ],
      },
      {
        value: 'Sergipe',
        label: 'Sergipe',
        children: [
          {
            value: 'Boa Vista',
            label: 'Boa Vista',
          },
        ],
      },
      {
        value: 'Tocantins',
        label: 'Tocantins',
        children: [
          {
            value: 'Boa Vista',
            label: 'Boa Vista',
          },
        ],
      },
    ],
  },
];
