import React, { useContext } from 'react';
//Contexts
import { ResponsiveContext } from '~/contexts/ResponsiveContext';
//View
import Desktop from './view/Desktop';
import Mobile from './view/Mobile';

export default function ViewVoterCertificate({
  name,
  person,
  GetProfileAgain,
}) {
  const { isMobile } = useContext(ResponsiveContext);
  return isMobile ? (
    <Mobile name={name} person={person} GetProfileAgain={GetProfileAgain} />
  ) : (
    <Desktop name={name} person={person} GetProfileAgain={GetProfileAgain} />
  );
}
