import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    overflow-x: auto;
    padding: 10px 10px;
`

export const ItemContainer = styled.div`
`