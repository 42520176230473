import React from 'react';
// Libraries
import { Space } from 'antd';
import Animate, { FadeIn } from 'animate-css-styled-components';
// Standalone Components
import { Container, Content, LeftItems, RightItems } from './style';
// Own Components
import CollapsedButton from './components/CollapsedButton';
import ModulesMenu from './components/ModulesMenu';
import UserButton from './components/UserButton';
import NotificationButton from './components/NotificationButton';
//import Config from './components/Config';

export default function MainNavbar({ withoutSidebar }) {
  return (
    <Container>
      <Content>
        <LeftItems>
          <Animate Animation={[FadeIn]} duration={['0.8s']} delay={['0.2s']}>
            {!withoutSidebar && <CollapsedButton />}
          </Animate>
        </LeftItems>
        <Animate Animation={[FadeIn]} duration={['0.8s']} delay={['0.2s']}>
          <RightItems>
            <Space direction="horizontal">
              <NotificationButton />
              <ModulesMenu />
              <UserButton />
            </Space>
          </RightItems>
        </Animate>
      </Content>
    </Container>
  );
}
