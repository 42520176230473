import React, { useState, useEffect, useContext } from 'react';
// Libraries
import { Divider } from 'antd';
// Contexts
import { AxiosContext } from '~/contexts/AxiosContext';
//Own Components
import Header from './components/Header';
import Content from './components/Content';
//Components
import Card from '~/components/card';
import Loading from '~/components/loading';

export default function Documents() {
  const { CallToTheAPI } = useContext(AxiosContext);

  const [loadingContent, setLoadingContent] = useState(true);
  const [person, setPerson] = useState();

  useEffect(() => {
    async function GetProfile() {
      const params = {
        operation: ['Profile', 'Get'],
      };
      const response = await CallToTheAPI(params);
      if (response.success) {
        setPerson(response.body.person);
        return setLoadingContent(false);
      }
      return setLoadingContent(false);
    }
    GetProfile();
  }, [CallToTheAPI]);

  async function GetProfileAgain() {
    const params = {
      operation: ['Profile', 'Get'],
    };
    const response = await CallToTheAPI(params);
    if (response.success) {
      setPerson(response.body.person);
    }
  }

  return (
    <div>
      <Card>
        <Header title="Documentos" />
        <Divider />
        {loadingContent ?
          <Loading text="Buscando seus documentos" />
          :
          <Content person={person} GetProfileAgain={GetProfileAgain} />
        }
      </Card>
    </div>
  );
}
