import React, { useContext } from 'react';
// Libraries
import { Divider, Row, Col } from 'antd';
// Contexts
import { LanguageContext } from '~/contexts/LanguageContext';
// Components
import FormItem from '~/components/form/FormItem';
import Input from '~/components/input';
import Select from '~/components/select';
// Environments
import { Sex, CivilState, Schooling } from '~/environments/Selects';

export default function BasicInformation() {
  const { translate } = useContext(LanguageContext);
  return (
    <Row>
      <Divider style={{ margin: '0px 0px 10px' }}>
        {translate.accountManagement.basic.text}
      </Divider>
      <Col xs={24} sm={24} md={16} lg={16} xl={16}>
        <FormItem
          label={translate.fields.fullNameLabel.text}
          name={['name']}
          rules={[
            {
              required: true,
              message: translate.fields.nameIsRequired.text,
            },
          ]}
          item={<Input placeholder={translate.fields.enterYourFullName.text} />}
          style={{ marginRight: 10 }}
        />
      </Col>
      <Col xs={24} sm={24} md={8} lg={8} xl={8}>
        <FormItem
          label={translate.fields.mainContactLabel.text}
          name={['main_contact']}
          helpText={translate.fields.mainContactHelp.text}
          item={
            <Input placeholder={translate.fields.enterAnEmailOrPhone.text} />
          }
          style={{ marginRight: 10 }}
        />
      </Col>
      <Col xs={24} sm={24} md={5} lg={5} xl={5}>
        <FormItem
          label={translate.fields.sexLabel.text}
          name={['sex']}
          item={
            <Select placeholder={translate.fields.select.text} options={Sex} />
          }
          style={{ marginRight: 10 }}
        />
      </Col>
      <Col xs={24} sm={24} md={8} lg={8} xl={8}>
        <FormItem
          label={translate.fields.maritalStatusLabel.text}
          name={['civil_state']}
          item={
            <Select
              placeholder={translate.fields.select.text}
              options={CivilState}
            />
          }
          style={{ marginRight: 10 }}
        />
      </Col>
      <Col xs={24} sm={24} md={11} lg={11} xl={11}>
        <FormItem
          label={translate.fields.educationLabel.text}
          name={['schooling']}
          item={
            <Select
              placeholder={translate.fields.select.text}
              options={Schooling}
            />
          }
          style={{ marginRight: 10 }}
        />
      </Col>
    </Row>
  );
}
