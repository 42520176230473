import React, { useEffect, useState, useContext } from 'react';

import { Row, Col } from 'antd';

import { ThemeContext } from '~/contexts/ThemeContext';
import { ResponsiveContext } from '~/contexts/ResponsiveContext';
import { AuthContext } from '~/contexts/AuthContext';

import { UserFirstName } from '~/utils/UserInformation';
import SocialMeAPI from '~/utils/SocialMeAPI';

import Search from '~/components/search';
import Loading from '~/components/loading';

import PersonQRCode from './components/PersonQRCode';
import MessagerShortcut from './components/MessagerShortcut';
import Notification from './components/Notification';

import { Body } from './style';

export default function Begin() {
  const [loadingContent, setLoadingContent] = useState(true);
  const [person, setPerson] = useState();

  const { GetUserAgain } = useContext(AuthContext);
  const { theme } = useContext(ThemeContext);
  const { isMobile } = useContext(ResponsiveContext);

  useEffect(() => {
    async function GetData() {
      const params = {
        operation: ['Profile', 'Get'],
      };
      const response = await SocialMeAPI(params);
      if (response.success) {
        setPerson(response.body.person);
        return setLoadingContent(false);
      }
      return setLoadingContent(false);
    }
    GetData();
  }, []);

  async function GetProfileAgain() {
    const params = {
      operation: ['Profile', 'Get'],
    };
    const response = await SocialMeAPI(params);
    if (response.success) {
      GetUserAgain();
      return setPerson(response.body.person);
    }
  }

  return (
    <Body backgroundColor={theme.color.backgroundSecondary}>
      <Search placeholder={`${UserFirstName()}, o que você está procurando?`} />
      {loadingContent ? (
        <Loading text="Carregando dados" />
      ) : (
        <Row style={{ marginTop: 10 }}>
          <Col xl={6} lg={6} md={18} sm={24} xs={24}>
            <PersonQRCode GetProfileAgain={GetProfileAgain} person={person} />
          </Col>
          <Col xl={10} lg={10} md={18} sm={24} xs={24}>
            <MessagerShortcut theme={theme} isMobile={isMobile} />
          </Col>
          <Col xl={8} lg={8} md={18} sm={0} xs={0}>
            <Notification theme={theme} />
          </Col>
        </Row>
      )}
    </Body>
  );
}
