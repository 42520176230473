import React, { useContext } from 'react';
// Libraries
import { BiBadgeCheck } from "react-icons/bi";
import { RiCake2Line } from "react-icons/ri";
import { VscRocket } from "react-icons/vsc";
// Contexts
import { LanguageContext } from '~/contexts/LanguageContext';
import { ThemeContext } from '~/contexts/ThemeContext';
import { ResponsiveContext } from '~/contexts/ResponsiveContext';
// Components
import Timeline, { TimelineItem } from '~/components/timeline';
import Text from '~/components/typography/text';
// Standalone Components
import { Container } from './style';
// Utils
import { DateInBrazilianFormat } from '~/utils/DateAndTime';
import { CapitalizeString } from '~/utils/Transformations';

export default function TimelineContent({ timeline }) {
  const { translate } = useContext(LanguageContext);
  const { theme } = useContext(ThemeContext);
  const { isMobile } = useContext(ResponsiveContext);

  const AgentColors = {
    PERSON_BIRTHDATE: theme.color.red,
    PERSON_REGISTRATION: theme.color.purple,
    ACTIVITY_FREQUENCY: theme.color.blue
  }

  const ContentItem = ({ date, text }) => {
    return (
      <Container
        style={{
          flexDirection: isMobile ? 'column' : 'row'
        }}
      >
        <Text strong>{date}</Text>
        {!isMobile &&
          <Text strong style={{ margin: '0px 5px' }}>
            -
          </Text>
        }
        <Text>{text}</Text>
      </Container>
    )
  }

  function PrepareItem(item) {
    const agent = item.agent;
    switch (agent) {
      case "PERSON_BIRTHDATE":
        return (
          <TimelineItem
            dot={
              <RiCake2Line style={{ color: AgentColors[agent] }} />
            }>
            <ContentItem
              date={DateInBrazilianFormat(item.created_at)}
              text={translate.accountManagement.personBirthDateTimeline.text}
            />
          </TimelineItem>
        )
      case "PERSON_REGISTRATION":
        return (
          <TimelineItem
            dot={
              <VscRocket style={{ color: AgentColors[agent] }} />
            }>
            <ContentItem
              date={DateInBrazilianFormat(item.created_at)}
              text={translate.accountManagement.personRegistrationTimeline.text}
            />
          </TimelineItem>
        )
      case "ACTIVITY_FREQUENCY":
        return (
          <TimelineItem
            dot={
              <BiBadgeCheck style={{ color: AgentColors[agent] }} />
            }>
            <ContentItem
              date={DateInBrazilianFormat(item.created_at)}
              text={`${translate.accountManagement.activityFrequencyTimeline.text}: ${CapitalizeString(item.agent_name)}`}
            />
          </TimelineItem>
        )
      default:
        return;
    }
  }
  return (
    <Timeline reverse>
      {timeline.map(item => (
        <div key={item.created_at}>
          {PrepareItem(item)}
        </div>
      ))}
    </Timeline>
  );
}
