import React, { useContext, useState, useEffect } from 'react';
// Libraries
import { Layout } from 'antd';
import { useLocation } from 'react-router-dom';
// Contexts
import { ThemeContext } from '~/contexts/ThemeContext';
import { SidebarContext } from '~/contexts/SidebarContext';
import { ResponsiveContext } from '~/contexts/ResponsiveContext';
// Components
//import ModulesSidebar from './components/ModulesSidebar';
// Own components
import Item from './components/Item';
import Header from './components/Header';
// Standalone components
import { Content, Menu } from './style';

const { Sider } = Layout;

export default function AntSidebar({ items }) {
  const { theme } = useContext(ThemeContext);
  const { collapsed } = useContext(SidebarContext);
  const { isMobile } = useContext(ResponsiveContext);

  const location = useLocation();
  const { pathname } = location;
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    items.map((item) => {
      if (item.url) {
        if (pathname === item.url || pathname.includes(item.url)) {
          setActiveIndex(item.index);
        }
      }
      return null;
    });
  }, [items, pathname]);
  return (
    <Sider
      width={isMobile ? '100%' : 215}
      collapsed={isMobile ? false : collapsed}
      collapsedWidth={50}
      trigger={null}
      collapsible
      style={{
        backgroundColor: theme.color.secondary,
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'row',
        height: '100vh',
      }}
    >
      <Content width={collapsed ? '50px' : '215px'} backgroundColor={theme.color.primary}>
        <Header collapsed={collapsed} />
        <Menu backgroundColor={theme.color.primary}>
          {items.map((item) => (
            <Item
              key={item.index}
              title={item.title}
              icon={item.icon}
              url={item.url}
              index={item.index}
              activeIndex={activeIndex}
              collapsed={collapsed}
            />
          ))}
        </Menu>
      </Content>
    </Sider>
  );
}
