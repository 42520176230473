import React from 'react';
//Libraries
import { Space } from 'antd';
//Components
import Text from '~/components/typography/text';
//Style
import {
  Container,
  FirstRow,
  SecondRow,
  ImageTitleContainer,
  ActionContainer,
} from './style';
//Utils
import { GetDocumentIcon } from '~/utils/SocialMeAssets';
import { DateInBrazilianFormat } from '~/utils/DateAndTime';
//Own Components
import EditButton from '../../components/EditButton';

export default function ViewRG({ name, person, GetProfileAgain }) {
  return (
    <Container>
      <FirstRow>
        <ImageTitleContainer>
          <img src={GetDocumentIcon(name)} alt="..." style={{ height: 45 }} />
          <Text strong>RG (Registro Geral)</Text>
        </ImageTitleContainer>
        <ActionContainer>
          <EditButton
            title="Editando RG (Registro Geral)"
            person={person}
            GetProfileAgain={GetProfileAgain}
          />
        </ActionContainer>
      </FirstRow>
      <SecondRow>
        <Space>
          <Text>Número: {person.rg_number}</Text>
          {person.rg_dispatched_at ? (
            <Text>
              Data de expedição:{' '}
              {DateInBrazilianFormat(person.rg_dispatched_at)}
            </Text>
          ) : (
            <></>
          )}
          {person.rg_dispatched_by ? (
            <Text>Órgão expedidor: {person.rg_dispatched_by}</Text>
          ) : (
            <></>
          )}
          {person.rg_dispatched_state ? (
            <Text>UF: {person.rg_dispatched_state}</Text>
          ) : (
            <></>
          )}
        </Space>
      </SecondRow>
    </Container>
  );
}
