import React from 'react';
import { Space } from 'antd';
//Components
import EditButton from './components/EditButton';
import DeleteButton from './components/DeleteButton';

export default function Actions({ contact, GetContactsAgain }) {
  return (
    <span style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
      <Space>
        <EditButton contact={contact} GetContactsAgain={GetContactsAgain} />
        <DeleteButton contact={contact} GetContactsAgain={GetContactsAgain} />
      </Space>
    </span>
  );
}
