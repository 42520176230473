import React, { useState, useEffect } from 'react';
//Own Components
import AddDocumentContainer from './components/AddDocumentContainer';
import OwnedDocumentsContainer from './components/OwnedDocumentsContainer';
//Components
import Loading from '~/components/loading';

export default function Content({ person, GetProfileAgain }) {
  const [ownedDocuments, setOwnedDocuments] = useState([]);
  const [missingDocuments, setMissingDocuments] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (person) {
      const ownedArray = [];
      const missingArray = [];
      async function HasOrNot(fieldName, documentName) {
        if (person[fieldName]) {
          ownedArray.push(documentName);
        } else {
          missingArray.push(documentName);
        }
      }
      async function SeparationOfDocuments() {
        await HasOrNot('cnpj_number', 'CNPJ');
        setOwnedDocuments(ownedArray);
        setMissingDocuments(missingArray);
        setLoading(false);
      }
      SeparationOfDocuments();
    }
  }, [person]);

  return (
    <div>
      {loading ? (
        <Loading text="Preparando documentos..." />
      ) : (
        <>
          <AddDocumentContainer
            missingDocuments={missingDocuments}
            GetProfileAgain={GetProfileAgain}
          />
          <OwnedDocumentsContainer
            ownedDocuments={ownedDocuments}
            person={person}
            GetProfileAgain={GetProfileAgain}
          />
        </>
      )}
    </div>
  );
}
