import React, { useContext } from 'react';
// Libraries
import {
  DownOutlined,
  LogoutOutlined,
  UserOutlined,
  GiftOutlined,
  HeartOutlined,
  WalletOutlined,
  ShopOutlined,
  //BankOutlined,
} from '@ant-design/icons';
import { Dropdown } from 'antd';
// Contexts
import { AuthContext } from '~/contexts/AuthContext';
import { ThemeContext } from '~/contexts/ThemeContext';
import { LanguageContext } from '~/contexts/LanguageContext';
import { ResponsiveContext } from '~/contexts/ResponsiveContext';
// Components
import Avatar from '~/components/avatar';
import Text from '~/components/typography/text';
import Divider from '~/components/divider';
import Space from '~/components/space';
// Environments
import System from '~/environments/System';
// Standalone Components
import { MenuContainer, MenuItem, Link, Container } from './style';
// Utils
import { UserAvatar, UserShortName, UserKind } from '~/utils/UserInformation';
import { GetModuleNameByHostname } from '~/utils/Hosts';

export default function HasUser() {
  const { Logout } = useContext(AuthContext);
  const { theme } = useContext(ThemeContext);
  const { translate } = useContext(LanguageContext);
  const { isMobile } = useContext(ResponsiveContext);
  const menu = (
    <MenuContainer
      borderColor={theme.color.borderColor}
      backgroundColor={theme.color.backgroundSecondaryLight}
      marginTop={isMobile ? '10px' : '0px'}
      fontSize={isMobile ? '12pt' : '10pt'}
    >
      <MenuItem>
        <Link href={`https://${System.hostname.accountManagement}`}>
          <UserOutlined style={{ marginRight: 5 }} />
          {translate.brands.accountManagement.text}
        </Link>
      </MenuItem>
      <MenuItem>
        <Link href={`https://${System.hostname.beneficiary}`}>
          <GiftOutlined style={{ marginRight: 5 }} />
          {translate.brands.beneficiary.text}
        </Link>
      </MenuItem>
      <MenuItem>
        <Link href={`https://${System.hostname.donor}`}>
          <HeartOutlined style={{ marginRight: 5 }} />
          {translate.brands.donor.text}
        </Link>
      </MenuItem>
      {UserKind() === 'PJ' && (
        <>
          <MenuItem>
            <Link href={`https://${System.hostname.partner}`}>
              <ShopOutlined style={{ marginRight: 5 }} />
              {translate.brands.partner.text}
            </Link>
          </MenuItem>
          {/*           <MenuItem>
            <Link href={`https://${System.hostname.manager}`}>
              <BankOutlined style={{ marginRight: 5 }} />
              {translate.brands.manager.text}
            </Link>
          </MenuItem>
          */}
        </>
      )}
      <MenuItem>
        <Link href={`https://${System.hostname.digitalWallet}`}>
          <WalletOutlined style={{ marginRight: 5 }} />
          {translate.brands.digitalWallet.text}
        </Link>
      </MenuItem>
      <Divider style={{ margin: '5px 0px' }} />
      <MenuItem
        onClick={() =>
          Logout(
            GetModuleNameByHostname() === 'localhost'
              ? 'http://localhost:3000'
              : `https://${System.hostname[GetModuleNameByHostname()]}`
          )
        }
      >
        <LogoutOutlined /> {translate.basic.logout.text}
      </MenuItem>
    </MenuContainer>
  );

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <Container>
        <Space>
          <Avatar src={UserAvatar()} />
          {!isMobile && (
            <>
              <Text>{UserShortName()}</Text>
              <DownOutlined style={{ fontSize: '7pt' }} />
            </>
          )}
        </Space>
      </Container>
    </Dropdown>
  );
}
