import React, { useContext, useState, useEffect } from 'react';
// Contexts
import { AxiosContext } from '~/contexts/AxiosContext';
import { AuthContext } from '~/contexts/AuthContext';
// Own Components
import Header from './components/Header';
import Content from './components/Content';
// Components
import Card from '~/components/card';
import Loading from '~/components/loading';

export default function Relationships() {
  const { CallToTheAPI } = useContext(AxiosContext);
  const { user } = useContext(AuthContext);

  const [loadingContent, setLoadingContent] = useState(true);
  const [myRelationships, setMyRelationships] = useState([]);
  const [pendingList, setPendingList] = useState([]);
  const [requestsMade, setRequestsMade] = useState([]);

  useEffect(() => {
    async function GetRelantionships() {
      const params = {
        operation: ['Profile', 'Relantionship', 'GetAll'],
      };
      const response = await CallToTheAPI(params);
      if (response.success) {
        const arrayActive = [];
        const arrayPending = [];
        const arrayRequestsMade = [];
        response.body.RelationshipList.elements.map(item => {
          if(item.status === "INACTIVE"){
            return null;
          }
          if(item.status === 'ACTIVE'){
            return arrayActive.push(item);
          }
          if(item.person_id === user.id){
            return arrayRequestsMade.push(item);
          }
            return arrayPending.push(item);
          
        })
        setMyRelationships(arrayActive);
        setPendingList(arrayPending);
        setRequestsMade(arrayRequestsMade);
        setLoadingContent(false)
      }
    }
    if(user){
      GetRelantionships();
    }
  }, [CallToTheAPI, user]);

  async function GetRelantionshipsAgain() {
    const params = {
      operation: ['Profile', 'Relantionship', 'GetAll'],
    };
    const response = await CallToTheAPI(params);
    if (response.success) {
      const arrayActive = [];
      const arrayPending = [];
      const arrayRequestsMade = [];
      response.body.RelationshipList.elements.map(item => {
        if(item.status === 'INACTIVE'){
          return null;
        }
        if(item.status === 'ACTIVE'){
          return arrayActive.push(item);
        }
        if(item.person_id === user.id){
          return arrayRequestsMade.push(item);
        }
          return arrayPending.push(item);
        
      })
      setMyRelationships(arrayActive);
      setPendingList(arrayPending);
      setRequestsMade(arrayRequestsMade);
      setLoadingContent(false)
    }
  }

  return (
    <div>
      <Card>
        <Header
          title="Relacionamentos"
          GetRelantionshipsAgain={GetRelantionshipsAgain}
        />
      
      {loadingContent ? (
        <Loading />
      ) : (
        <Content
          myRelationships={myRelationships}
          pendingList={pendingList}
          requestsMade={requestsMade}
          GetRelantionshipsAgain={GetRelantionshipsAgain}
        />
      )}
      </Card>
    </div>
  );
}
