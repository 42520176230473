import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  height: 70vh;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 6px;
    background-color: ${(props) => props.scrollBarColor};
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: ${(props) => props.scrollBarThumbColor};
  }
`;

export const Box = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 10px 10px 0;
`;
