import React from 'react';

import { Button } from 'antd';
// Components
import Actions from './components/Actions';
//
import Table from '~/components/table';

export default function ContactsTable({ contacts, GetContactsAgain }) {
  const tableColumns = [
    {
      title: 'Descrição',
      dataIndex: 'description',
      key: 'description',
      render: (description) => <Button type="link">{description}</Button>,
    },
    {
      title: 'Contato',
      dataIndex: 'value',
      key: 'value',
      render: (value) => <Button type="link">{value}</Button>,
    },
    {
      title: 'Ações',
      key: 'action',
      render: (data) => (
        <Actions GetContactsAgain={GetContactsAgain} contact={data} />
      ),
    },
  ];

  return <Table tableColumns={tableColumns} dataReceived={contacts} />;
}
