import React, { useEffect, useState, useContext } from 'react';
// Contexts
import { AxiosContext } from '~/contexts/AxiosContext';
// Libraries
import { Radio } from 'antd';
// Components
import FormItem from '~/components/form/FormItem';
import Select from '~/components/select';
import Input from '~/components/input';
//Utils
import { ValidateDate } from '~/utils/Validations';

export default function BirthCertificateForm({ formValues, form }) {
  const { CallToTheAPI } = useContext(AxiosContext);

  const [countries, setCountries] = useState([]);
  const [loadingCountries, setLoadingCountries] = useState(true);
  const [disabledStateSelect, setDisabledStateSelect] = useState(true);
  const [states, setStates] = useState([]);
  const [loadingStateSelect, setLoadingStateSelect] = useState(false);
  const [disabledCitiesSelect, setDisabledCitiesSelect] = useState(true);
  const [cities, setCities] = useState([]);
  const [loadingCitySelect, setLoadingCitySelect] = useState(false);
  const [disabledCommunitiesSelect, setDisabledCommunitiesSelect] = useState(true);
  const [communities, setCommunities] = useState([]);
  const [loadingCommunitySelect, setLoadingCommunitySelect] = useState(false);

  useEffect(() => {
    async function GetCountries() {
      const params = {
        operation: ['Location', 'Get'],
        data: {
          filter: {
            type: 'COU',
          },
          pagination: {
            sort: {
              by: 'name',
              order: 'asc',
            },
          }
        },
      };
      const response = await CallToTheAPI(params);
      if (response.success) {
        let data = [];
        response.body.countryStateCityCommunityList.elements.map((item) => {
          let newItem = {};
          if (item.name === 'BRASIL') {
            newItem.value = item.id;
            newItem.label = item.name;
            data.push(newItem);
          }
          return null;
        });
        /*
        response.body.countryStateCityCommunityList.elements.map((item) => {
          let newItem = {};
          newItem.value = item.id;
          newItem.label = item.name;
          data.push(newItem);
          return null;
        });
        */
        setCountries(data);
        setLoadingCountries(false);
      }
    }
    GetCountries();
  }, [CallToTheAPI]);

  useEffect(() => {
    async function GetStates(countryId) {
      const params = {
        operation: ['Location', 'Get'],
        data: {
          filter: {
            type: 'ST',
            country: countryId,
          },
          pagination: {
            sort: {
              by: 'name',
              order: 'asc',
            },
            limit: 0,
          }
        }
      };
      const response = await CallToTheAPI(params);
      if (response.success) {
        let data = [];
        response.body.countryStateCityCommunityList.elements.map((item) => {
          let newItem = {};
          newItem.value = item.id;
          newItem.label = item.name;
          data.push(newItem);
          return null;
        });
        setStates(data);
        setLoadingStateSelect(false);
        setDisabledStateSelect(false);
      }
    }
    if (form.getFieldsValue().birth_certificate_state) {
      GetStates(form.getFieldsValue().birth_certificate_country);
    }
  }, [CallToTheAPI, form])

  useEffect(() => {
    async function GetCities(stateId) {
      const params = {
        operation: ['Location', 'Get'],
        data: {
          filter: {
            type: 'CI',
            state: stateId,
          },
          pagination: {
            sort: {
              by: 'name',
              order: 'asc',
            },
            limit: 0,
          }
        }
      };
      const response = await CallToTheAPI(params);
      if (response.success) {
        let data = [];
        response.body.countryStateCityCommunityList.elements.map((item) => {
          let newItem = {};
          newItem.value = item.id;
          newItem.label = item.name;
          data.push(newItem);
          return null;
        });
        setCities(data);
        setLoadingCitySelect(false);
        setDisabledCitiesSelect(false);
      }
    }
    if (form.getFieldsValue().birth_certificate_city) {
      GetCities(form.getFieldsValue().birth_certificate_state);
    }
  }, [CallToTheAPI, form])

  useEffect(() => {
    async function GetCommunities(cityId) {
      const params = {
        operation: ['Location', 'Get'],
        data: {
          filter: {
            type: 'COM',
            city: cityId,
          },
          pagination: {
            sort: {
              by: 'name',
              order: 'asc',
            },
            limit: 0,
          }
        }
      };
      const response = await CallToTheAPI(params);
      if (response.success) {
        let data = [];
        response.body.countryStateCityCommunityList.elements.map((item) => {
          let newItem = {};
          newItem.value = item.id;
          newItem.label = item.name;
          data.push(newItem);
          return null;
        });
        setCommunities(data);
        setLoadingCommunitySelect(false);
      }
    }
    if (form.getFieldsValue().birth_certificate_community) {
      GetCommunities(form.getFieldsValue().birth_certificate_city);
    }
  }, [CallToTheAPI, form])

  async function GetStates(countryId) {
    const params = {
      operation: ['Location', 'Get'],
      data: {
        filter: {
          type: 'ST',
          country: countryId,
        },
        pagination: {
          sort: {
            by: 'name',
            order: 'asc',
          },
          limit: 0,
        }
      }
    };
    const response = await CallToTheAPI(params);
    if (response.success) {
      let data = [];
      response.body.countryStateCityCommunityList.elements.map((item) => {
        let newItem = {};
        newItem.value = item.id;
        newItem.label = item.name;
        data.push(newItem);
        return null;
      });
      setStates(data);
      setLoadingStateSelect(false);
    }
  }

  async function GetCities(stateId) {
    const params = {
      operation: ['Location', 'Get'],
      data: {
        filter: {
          type: 'CI',
          state: stateId,
        },
        pagination: {
          sort: {
            by: 'name',
            order: 'asc',
          },
          limit: 0,
        }
      }
    };
    const response = await CallToTheAPI(params);
    if (response.success) {
      let data = [];
      response.body.countryStateCityCommunityList.elements.map((item) => {
        let newItem = {};
        newItem.value = item.id;
        newItem.label = item.name;
        data.push(newItem);
        return null;
      });
      setCities(data);
      setLoadingCitySelect(false);
    }
  }

  async function GetCommunities(cityId) {
    const params = {
      operation: ['Location', 'Get'],
      data: {
        filter: {
          type: 'COM',
          city: cityId,
        },
        pagination: {
          sort: {
            by: 'name',
            order: 'asc',
          },
          limit: 0,
        }
      }
    };
    const response = await CallToTheAPI(params);
    if (response.success) {
      let data = [];
      response.body.countryStateCityCommunityList.elements.map((item) => {
        let newItem = {};
        newItem.value = item.id;
        newItem.label = item.name;
        data.push(newItem);
        return null;
      });
      setCommunities(data);
      setLoadingCommunitySelect(false);
    }
  }

  async function SelectCountry(data) {
    form.setFieldsValue({ state: '' });
    form.setFieldsValue({ city: '' });
    form.setFieldsValue({ community: '' });
    setStates([]);
    setDisabledStateSelect(false);
    setLoadingStateSelect(true);
    GetStates(data.value);
  }

  async function SelectState(data) {
    form.setFieldsValue({ city: '' });
    form.setFieldsValue({ community: '' });
    setCities([]);
    setDisabledCitiesSelect(false);
    setLoadingCitySelect(true);
    GetCities(data.value);
  }

  async function SelectCity(data) {
    form.setFieldsValue({ community: '' });
    setCommunities([]);
    setDisabledCommunitiesSelect(false);
    setLoadingCommunitySelect(true);
    GetCommunities(data.value);
  }

  return (
    <>
      <Date />
      <AliveNumber />
      <Place />
      <Twins />

      <FormItem
        label="País"
        name={['birth_certificate_country']}
        style={{ marginRight: 10 }}
        rules={[
          {
            required: true,
            message: 'Um país é obrigatório',
          },
        ]}
        item={
          <Select
            options={countries}
            loading={loadingCountries}
            onSelect={(value, data) => SelectCountry(data)}
          />
        }
      />

      <FormItem
        label="Estado"
        name={['birth_certificate_state']}
        style={{ marginRight: 10 }}
        rules={[
          {
            required: true,
            message: 'Um estado é obrigatório',
          },
        ]}
        item={
          <Select
            options={states}
            loading={loadingStateSelect}
            disabled={disabledStateSelect}
            onSelect={(value, data) => SelectState(data)}
          />
        }
      />

      <FormItem
        label="Cidade"
        name={['birth_certificate_city']}
        rules={[
          {
            required: true,
            message: 'Um cidade é obrigatório',
          },
        ]}
        item={
          <Select
            options={cities}
            loading={loadingCitySelect}
            disabled={disabledCitiesSelect}
            onSelect={(value, data) => SelectCity(data)}
          />
        }
      />

      <FormItem
        label="Comunidade"
        name={['birth_certificate_community']}
        item={
          <Select
            options={communities}
            loading={loadingCommunitySelect}
            disabled={disabledCommunitiesSelect}
          //onSelect={(value, data) => SelectCity(data)}
          />
        }
      />
    </>
  );
}

const Date = () => {
  return (
    <FormItem
      label="Data de nascimento"
      name={['birth_date']}
      rules={[
        {
          required: true,
          message: 'Uma data é obrigatória',
        },
        () => ({
          validator(rule, value) {
            if (ValidateDate(value) === false) {
              return Promise.reject('Insira uma data válida');
            }
            return Promise.resolve();
          },
        }),
      ]}
      item={
        <Input mask="11/11/1111" />
      }
    />
  )
};

const AliveNumber = () => {
  return (
    <FormItem
      label="Matrícula"
      name={['birth_certificate_alive_number']}
      item={
        <Input />
      }
    />
  )
};

const Place = () => {
  return (
    <FormItem
      label="Lugar"
      name={['birth_certificate_place']}
      item={
        <Input placeholder="Ex.: Maternidade Nossa Senhora" />
      }
    />
  )
};

const Twins = () => {
  return (
    <FormItem
      label="Possui irmão gêmeo"
      name={['birth_certificate_twins']}
      item={
        <Radio.Group>
          <Radio value={true}>Sim</Radio>
          <Radio value={false}>Não</Radio>
        </Radio.Group>
      }
    />
  )
};