import React, { useEffect, useState, useContext } from "react";

import { Empty } from "antd";

import { ResponsiveContext } from "~/contexts/ResponsiveContext";
import { ThemeContext } from "~/contexts/ThemeContext";

import ResponsiveTable from "./responsive";

import Table from "./style";

export default function TableDefault({
  dataReceived,
  tableColumns,
  setLoading,
  expandable,
  emptyDescription,
}) {
  const [data, setData] = useState([]);

  const { theme } = useContext(ThemeContext);
  const { isMobile } = useContext(ResponsiveContext);

  useEffect(() => {
    async function PrepareData() {
      const treatment = [];

      await dataReceived.map((item) => {
        if (item.id) {
          item.key = item.id;
        } else {
          item.key = item._id;
        }

        return treatment.push(item);
      });

      setData(treatment);
    }

    if (dataReceived) {
      PrepareData();
    }
  }, [dataReceived, setLoading]);

  return data.length > 0 ? (
    !isMobile ? (
      <Table
        headerColor={theme.color.white}
        pagination={false}
        columns={tableColumns}
        dataSource={data}
        expandable={expandable}
      />
    ) : (
      <ResponsiveTable
        theme={theme}
        columns={tableColumns}
        data={data}
        expandable={expandable}
      />
    )
  ) : (
    <Empty description={emptyDescription || "Sem nenhuma informação amostra"} />
  );
}
